/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Container, Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useContext, useEffect, useReducer, useState } from "react";
import { useIsDesktop } from "../../../../Hooks/useIsDesktop";
// import ReactDatePicker from "react-datepicker";
import SourcePieChart from "./Graph Components/PieChart";
import DailyData from "./Graph Components/DailyData";
import RenewablePrevMonth from "./Text Components/RenewablePrevMonth";
import { useAxiosPrivate } from "../../../../Hooks/useAxiosPrivate";
// import { BarLoader } from "react-spinners";
import LoadingComp from "./LoadingComp";
import BatteryUsage from "./Graph Components/Battery";
import ReportSelectorComp from "./ReportSelectorComp";
import {
  fetchReportData,
  reportDataReducer,
  reportDateReducer,
  reportLoadReducer,
} from "./ReportReducers";
import { AiFillQuestionCircle } from "react-icons/ai";
import ReportHelpModal from "./ReportHelpModal";
import StatusContext from "../../../../Context/StatusProvider";
import BlankCard from "../../../Misc/BlankCard";
import LandscapePlease from "../../../Misc/LandscapePlease";
import { exportPdf } from "../../Admin/Tools/pdf-tools";
import domtoimage from "dom-to-image";

const ReportPage = () => {
  // hooks declared
  const isDesktop = useIsDesktop();
  const axiosPrivate = useAxiosPrivate();
  //- - -

  //params taken from URL
  const params = useParams();
  const siteCxp = params.siteCxp;
  const currentSerial = params.serialNumber;
  //- - -

  //initial states for reducers.
  const allData = {
    battery: [],
    sources: [],
    daily: [],
    renewable: [],
  };

  const loadError = {
    isLoading: true,
    isError: false,
    showReports: false,
    batteryLoading: true,
    dailyLoading: true,
    sourcesLoading: true,
    renewablesLoading: true,
  };

  const dateState = {
    startDate: null,
    endDate: null,
  };

  //- - -

  const {
    showReportAck,
    setShowReportAck,
    reportShownSession,
    setReportShownSession,
  } = useContext(StatusContext);

  // state management using reducers

  // const [showModal, setShowModal] = useState(false);

  const handleShow = () => {
    setShowReportAck(true);
  };

  const handleHide = () => {
    setShowReportAck(false);
  };

  const [data, setData] = useReducer(reportDataReducer, allData);

  const [loadingState, setLoadingState] = useReducer(
    reportLoadReducer,
    loadError
  );

  const [dates, setDateState] = useReducer(reportDateReducer, dateState);

  const [options, setOptions] = useState([]);

  const [selected, setSelected] = useState([]);

  const formattedSerials = () => {
    let str = "";

    selected.forEach((sn) => {
      const serial = sn.value;
      const regex = /\d\d\d\d\/\d\d\d\d/i;

      if (!regex.test(serial.toString())) {
        return "";
      }

      if (regex.test(serial.toString())) {
        const splitSerial = serial.split("/");
        const formattedSerial =
          `${splitSerial[0]}${splitSerial[1]},`.toString();

        str += formattedSerial;
      }
    });

    const lastChar = str.slice(-1);

    if (lastChar === ",") {
      str = str.substring(0, str.length - 1);
    }

    return str;
  };

  //- - -

  const queryString = () => {
    const query = `?start=${dates.startDate.toLocaleDateString(
      "en-GB"
    )}&end=${dates.endDate.toLocaleDateString()}&serial=${formattedSerials()}`;

    return query;
  };

  const fetchAllData = async () => {
    const dataKeys = Array.from(Object.keys(allData));

    setLoadingState({
      type: "setLoading",
      payload: true,
    });
    try {
      dataKeys.forEach(async (key) => {
        await fetchReportData(
          key,
          siteCxp,
          queryString(),
          axiosPrivate,
          setData
        );
        setTimeout(() => {
          if (key === "battery") {
            setLoadingState({
              type: "setBatteryLoading",
              payload: false,
            });
          }
          if (key === "sources") {
            setLoadingState({
              type: "setSourcesLoading",
              payload: false,
            });
          }
          if (key === "daily") {
            setLoadingState({
              type: "setDailyLoading",
              payload: false,
            });
          }
          if (key === "renewables") {
            setLoadingState({
              type: "setRenewablesLoading",
              payload: false,
            });
          }
        }, 750);
      });
    } catch (err) {
      dataKeys.forEach((key) => {
        setLoadingState({
          type: `set${key.charAt(0).toUpperCase()}Loading`,
          payload: false,
        });
      });
      setLoadingState({
        type: "setLoading",
        payload: false,
      });
      setLoadingState({
        type: "setError",
        payload: true,
      });
    }
  };

  const generateReport = async () => {
    setData({
      type: "resetData",
      payload: true,
    });
    setLoadingState({
      type: "setError",
      payload: false,
    });
    setLoadingState({
      type: "setLoading",
      payload: true,
    });
    setLoadingState({
      type: "setShowReports",
      payload: false,
    });

    await fetchAllData();

    setTimeout(() => {
      setLoadingState({
        type: "setLoading",
        payload: false,
      });
      setLoadingState({
        type: "setShowReports",
        payload: true,
      });
    }, 2000);
  };

  const exportReportPDF = async () => {
    const node = document.getElementById("report-page");

    domtoimage.toPng(node).then(function (dataUrl) {
      exportPdf(dataUrl, siteCxp);
    });
  };

  //stops keyboard popping up when datepicker is clicked on mobile.
  useEffect(() => {
    const datePickers = document.getElementsByClassName(
      "react-datepicker__input-container"
    );
    Array.from(datePickers).forEach((el) =>
      el.childNodes[0].setAttribute("readOnly", true)
    );
  }, []);

  //trigger effect if serial number or site changes (such as page change)

  useEffect(() => {
    setDateState({
      type: "setStart",
      payload: null,
    });
    setDateState({
      type: "setEnd",
      payload: null,
    });
    setLoadingState({
      type: "setShowReports",
      payload: false,
    });
  }, [siteCxp, currentSerial]);

  return (
    <>
      {!isDesktop && window.innerWidth < window.innerHeight && (
        <>
          <LandscapePlease />
        </>
      )}
      {isDesktop === true && (
        <>
          <Container
            fluid
            className="p-0 pb-3"
            key={siteCxp + currentSerial}
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Row
              className=""
              style={{
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <ReportSelectorComp
                start={dates?.startDate}
                setDates={setDateState}
                end={dates?.endDate}
                isLoading={loadingState.isLoading || false}
                setIsLoading={setLoadingState}
                options={options}
                setOptions={setOptions}
                selected={selected}
                setSelected={setSelected}
              />
              <Row
                className="my-3"
                style={{
                  display: "flex",
                  width: "25%",
                  justifyContent: "space-around",
                  alignItems: "center",
                }}
              >
                <div style={{ width: "fit-content" }}>
                  <Row>
                    <Button
                      style={{ width: "fit-content" }}
                      onClick={() => generateReport()}
                      disabled={dates.startDate && dates.endDate ? false : true}
                    >
                      Generate Report
                    </Button>
                  </Row>
                </div>
                <div style={{ width: "fit-content" }}>
                  <Row>
                    <Button
                      onClick={() => handleShow()}
                      className="report-help-btn"
                      style={{ width: "fit-content" }}
                      title="Help"
                    >
                      <AiFillQuestionCircle style={{ fontSize: "1.2rem" }} />
                    </Button>
                  </Row>
                </div>
              </Row>
            </Row>

            {loadingState.isLoading && (
              <>
                <Row
                  style={{
                    display: "flex",
                    height: "35rem",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingComp />
                </Row>
              </>
            )}
          </Container>
          {!loadingState.isLoading && loadingState.showReports && (
            <>
              <Container
                fluid
                id="report-page"
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  overflow: "auto",
                }}
              >
                <Row
                  className="mb-3 p-0 m-0"
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  {data.battery.length === 0 && (
                    <Container
                      className="p-0 m-0"
                      fluid
                      style={{ height: "10rem" }}
                    >
                      <BlankCard
                        loading={loadingState.batteryLoading}
                        title={{ title: "Battery", subtitle: "" }}
                      >
                        <LoadingComp />
                      </BlankCard>
                    </Container>
                  )}
                  {data.battery.map((item, idx) => (
                    <>
                      <BlankCard
                        loading={loadingState.batteryLoading}
                        title={{
                          title: `Battery ${idx + 1}`,
                          subtitle: `${item.serial}`,
                        }}
                      >
                        <BatteryUsage
                          data={data.battery[idx].data}
                          serial={item.serial}
                        />
                      </BlankCard>
                    </>
                  ))}
                </Row>
                <Row
                  className="mb-3"
                  style={{
                    height: "20rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  <Col
                    md={6}
                    style={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    {
                      <>
                        <BlankCard
                          loading={loadingState.sourcesLoading}
                          title={{
                            title: "Imported Energy Sources",
                            subtitle: "Prev 28 Days",
                          }}
                        >
                          <SourcePieChart data={data.sources} />
                        </BlankCard>
                      </>
                    }
                  </Col>

                  {data.renewable.length !== 0 && (
                    <>
                      <Col
                        md={6}
                        style={{
                          height: "100%",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                        }}
                      >
                        <BlankCard
                          loading={loadingState.renewables}
                          title={{
                            title: "Renewable Energy",
                            subtitle: "Last Full Month",
                          }}
                        >
                          <RenewablePrevMonth data={data.renewable} />
                        </BlankCard>
                      </Col>
                    </>
                  )}
                </Row>
                <Row
                  className="mb-3 p-0 m-0"
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    padding: "1rem",
                  }}
                >
                  {data.daily &&
                    data.daily.map((sn) => (
                      <>
                        <BlankCard
                          loading={loadingState.dailyLoading}
                          title={{
                            title: "Daily Energy Import Sources",
                            subtitle: `${sn.serial}`,
                          }}
                        >
                          <DailyData data={sn.data} />
                        </BlankCard>
                      </>
                    ))}
                </Row>
              </Container>
            </>
          )}
          {!loadingState.isLoading && loadingState.showReports && (
            <Row
              className="mb-4"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                onClick={() => exportReportPDF()}
                title="Download this report as a PDF file."
                className="pdf-export-btn"
                style={{ width: "fit-content", fontSize: "1.2rem" }}
              >
                Export to PDF
              </Button>
            </Row>
          )}
        </>
      )}

      {!isDesktop && (
        <>
          <Container
            fluid
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Row
              className="mb-3"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <ReportSelectorComp
                start={dates?.startDate}
                setDates={setDateState}
                end={dates?.endDate}
                isLoading={loadingState.isLoading}
                setIsLoading={setLoadingState}
                options={options}
                setOptions={setOptions}
                selected={selected}
                setSelected={setSelected}
              />
            </Row>

            <Row
              className=""
              style={{
                display: "flex",
                width: "80%",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <div style={{ width: "fit-content" }}>
                <Row>
                  <Button
                    style={{ width: "fit-content" }}
                    onClick={() => generateReport()}
                    disabled={dates.startDate && dates.endDate ? false : true}
                  >
                    Generate Report
                  </Button>
                </Row>
              </div>
              <div style={{ width: "fit-content" }}>
                <Row>
                  <Button
                    onClick={() => handleShow()}
                    className="report-help-btn"
                    style={{ width: "fit-content" }}
                    title="Help"
                  >
                    <AiFillQuestionCircle style={{ fontSize: "1.2rem" }} />
                  </Button>
                </Row>
              </div>
            </Row>

            {loadingState.isLoading && (
              <>
                <Row
                  style={{
                    display: "flex",
                    height: "15rem",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingComp />
                </Row>
              </>
            )}
          </Container>
          {loadingState.showReports && (
            <Container
              className="mb-3"
              fluid
              id="report-page"
              style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Row className="my-3" style={{ width: "100%" }}>
                {data.battery.length === 0 && (
                  <Container
                    fluid
                    className="m-0 p-0"
                    style={{ height: "10rem" }}
                  >
                    <BlankCard
                      loading={loadingState.batteryLoading}
                      title={{ title: "Battery", subtitle: "" }}
                    >
                      <LoadingComp />
                    </BlankCard>
                  </Container>
                )}
                {data.battery.map((item, idx) => (
                  <>
                    <BlankCard
                      loading={loadingState.batteryLoading}
                      title={{
                        title: `Battery ${idx + 1}`,
                        subtitle: `${item.serial}`,
                      }}
                    >
                      <BatteryUsage
                        data={data.battery[idx].data}
                        serial={item.serial}
                      />
                    </BlankCard>
                  </>
                ))}
              </Row>

              <Row
                className="mb-3 p-0 m-0"
                style={{
                  width: "100%",
                  height: "20rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                <>
                  <BlankCard
                    loading={loadingState.sourcesLoading}
                    title={{
                      title: "Imported Energy Sources",
                      subtitle: "Prev 28 Days",
                    }}
                  >
                    <SourcePieChart data={data.sources} />
                  </BlankCard>
                </>
              </Row>
              {data.renewable && (
                <>
                  <Row
                    className="mb-3 p-0 m-0"
                    style={{
                      width: "100%",
                      height: "15rem",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                    }}
                  >
                    <BlankCard
                      loading={loadingState.renewableLoading}
                      title={{
                        title: "Renewable Energy",
                        subtitle: "Last Full Month",
                      }}
                    >
                      <RenewablePrevMonth data={data.renewable} />
                    </BlankCard>
                  </Row>
                </>
              )}

              <Row
                className="mb-3 p-0 m-0"
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                {data.daily.length === 0 && (
                  <Container
                    fluid
                    className="m-0 p-0"
                    style={{ height: "10rem" }}
                  >
                    <BlankCard
                      loading={loadingState.dailyLoading}
                      title={{
                        title: "Daily Energy Import Sources",
                        subtitle: "",
                      }}
                    >
                      <LoadingComp />
                    </BlankCard>
                  </Container>
                )}
                {data.daily.map((sn) => (
                  <>
                    <BlankCard
                      loading={loadingState.dailyLoading}
                      title={{
                        title: "Daily Energy Import Sources",
                        subtitle: `${sn.serial}`,
                      }}
                    >
                      <DailyData data={sn.data} />
                    </BlankCard>
                  </>
                ))}
              </Row>
            </Container>
          )}
        </>
      )}

      <ReportHelpModal
        handleClose={handleHide}
        // showOnLoad={showReportAck}
      />
    </>
  );
};

export default ReportPage;
