import { createContext, useState } from "react";
import { useAxiosPrivate } from "../Hooks/useAxiosPrivate";

const UserContext = createContext({});

export const UserDataProvider = ({ children }) => {
  const axiosPrivate = useAxiosPrivate();
  const [userData, setUserData] = useState({});
  const [userFaultProfile, setUserFaultProfile] = useState({});
  const [theme, setTheme] = useState("default");
  const [loggedIn, setLoggedIn] = useState(false);

  const saveThemeChange = async (theme) => {
    try {
      await axiosPrivate.put("/users/theme", { theme: theme });
      setTheme(theme);
    } catch (err) {
      console.log(err);
    }
  };

  const handleThemeChange = (theme) => {
    saveThemeChange(theme);
  };

  return (
    <UserContext.Provider
      value={{
        userData,
        setUserData,
        theme,
        setTheme,
        handleThemeChange,
        loggedIn,
        setLoggedIn,
        userFaultProfile,
        setUserFaultProfile,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
