import { createContext, useState } from "react";

const AuthContext = createContext({});

//this is the context provider that will wrap our app and provide the auth context to all components

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({});
  const [logInNotify, setLogInNotify] = useState(false);
  const [logOutNotify, setLogOutNotify] = useState(false);
  const [passwordChange, setPasswordChange] = useState(false);
  const [accountChange, setAccountChange] = useState(false);

  return (
    <AuthContext.Provider
      value={{
        auth,
        setAuth,
        logInNotify,
        setLogInNotify,
        logOutNotify,
        setLogOutNotify,
        passwordChange,
        setPasswordChange,
        accountChange,
        setAccountChange,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
