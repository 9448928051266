import { Row, Container, Button } from "react-bootstrap";
import { IoMdArrowRoundBack } from "react-icons/io";
import useBackButton from "../../Hooks/useBackButton";
import useForwardButton from "../../Hooks/useForwardButton";
import { useNavigate } from "react-router-dom";

const BackButtonRow = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };
  const goForward = () => {
    navigate(1);
  };

  useBackButton(goBack);
  useForwardButton(goForward);

  return (
    <>
      <Row
        className="p-0 m-0 site-info-top m-2"
        style={{
          width: "100%",
          height: "2.5rem",
        }}
      >
        <Button
          style={{ width: "fit-content", fontSize: "0.8rem" }}
          onClick={() => navigate(-1)}
          className="my-auto mx-1 back-btn-top"
        >
          <IoMdArrowRoundBack style={{ fontSize: "1.3rem" }} /> Return
        </Button>
      </Row>
    </>
  );
};

export default BackButtonRow;
