/* eslint-disable react-hooks/exhaustive-deps */
import {
  Col,
  Container,
  Row,
  Button,
  Nav,
  Tab,
  Spinner,
  ButtonGroup,
} from "react-bootstrap";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useContext, useEffect, useCallback } from "react";
import Overview from "./Overview.jsx";
import { Accordion } from "react-bootstrap";
import { useAxiosPrivate } from "../../../Hooks/useAxiosPrivate.js";
import LoadingScreen from "../../Misc/LoadingScreen.jsx";
import { FiRefreshCcw } from "react-icons/fi";
import { parseISO } from "date-fns";
import useBackButton from "../../../Hooks/useBackButton.js";
import useForwardButton from "../../../Hooks/useForwardButton.js";
import EVPage from "./EV/EVPage.jsx";
import BatteryPage from "./Battery/BatteryPage.jsx";
import AcInvPage from "./ACAndInverter/ACInvPage.jsx";
import MobileBattery from "./Battery/Mobile Views/Battery/index.js";
import { useIsDesktop } from "../../../Hooks/useIsDesktop.js";
import Solar from "./Solar.jsx";
import GlobalDataContext from "../../../Context/DataProvider.js";
import UserContext from "../../../Context/UserProvider.js";
import StatusContext from "../../../Context/StatusProvider.js";
import Dropdown from "react-bootstrap/Dropdown";
import InverterPage from "./ACAndInverter/InverterPage.jsx";
import ReportPage from "./Reports/ReportPage.jsx";

const NewStatusPage = () => {
  const navigate = useNavigate();
  const params = useParams();
  let site_name = params.siteCxp;
  const serialNum = params.serialNumber;

  const goBack = () => {
    navigate(-1);
  };
  const goForward = () => {
    navigate(1);
  };

  //custom hooks for use of browser back and forward buttons, allow navigation using these without refresh and losing application state.
  useBackButton(goBack);
  useForwardButton(goForward);

  //axios private instance for use of axios interceptor for authentication
  const axiosPrivate = useAxiosPrivate();
  // const dispatch = useDispatch();
  const { latestData, setLatestData, siteInfo } = useContext(GlobalDataContext);

  const [dataLoading, setDataLoading] = useState(false);
  const [switching, setSwitching] = useState(false);
  const [activePage, setActivePage] = useState("");

  const [productType, setProductType] = useState("");

  const {
    showReportAck,
    setShowReportAck,
    reportShownSession,
  } = useContext(StatusContext);

  const { userData } = useContext(UserContext);

  const { isLoading, setIsLoading } = useContext(StatusContext);

  const userProjects = userData?.projects;

  const isDesktop = useIsDesktop();

  const [siteLocation, setSiteLocation] = useState("");
  const [activeKey, setActiveKey] = useState("first");

  const [serialArr, setSerialArr] = useState([]);

  const lastReported = () => {
    if (latestData !== null || latestData !== undefined) {
      const date = parseISO(latestData?.timestamp).toLocaleDateString();
      const time = parseISO(latestData?.timestamp).toLocaleTimeString();
      const stringToReturn = `${date} at ${time}`;
      return stringToReturn;
    } else {
      const stringToReturn = "No data available";
      return stringToReturn;
    }
  };

  //crude way of checking if data is displayed on page - if not, do not display report page
  const isDataDisplayed = () => {
    const container = document.getElementById("not-configured");
    if (container) {
      return false;
    } else {
      return true;
    }
  };

  async function fetchLatestData() {
    try {
      setDataLoading(true);
      setSwitching(true);

      const serialNumbers = await axiosPrivate.get(
        `/projects/${site_name}/serials`
      );
      const siteLocation = await axiosPrivate.get(`/projects/${site_name}`);

      const response = await axiosPrivate.get(
        `/data/latest?site=${site_name}&serial=${serialNum.toString()}`
      );

      if (response.status === 200 || response.status === 304) {
        setTimeout(() => {
          // setIsLoading(false);
          setDataLoading(false);
          setSwitching(false);

          if (serialNumbers.status === 200 || serialNumbers.status === 304) {
            setSerialArr(serialNumbers.data);
          }

          if (siteLocation.status === 200 || siteLocation.status === 304) {
            setIsLoading(false);
            setSiteLocation(siteLocation.data.location);
          }
        }, 1000);

        setLatestData(response.data);
      }
    } catch (err) {
      console.error(err);
    }
  }

  const fetchSystemType = useCallback(async () => {
    const response = await axiosPrivate.get(`/projects/${site_name}/product`);
    if (response.status === 200 || response.status === 304) {
      setProductType(response.data);
    }
  }, [site_name]);

  useEffect(() => {
    if (activePage === "Reports") {
      let reportAck = localStorage.getItem("reportAck");

      if (reportAck !== null) {
        reportAck = JSON.parse(reportAck);
        setShowReportAck(reportAck);
      } else {
        if (reportShownSession === true) {
          setShowReportAck(false);
        } else {
          setShowReportAck(true);
        }
      }
    }
  }, [activePage]);

  useEffect(() => {
    fetchSystemType();
    async function dataFetch() {
      await fetchLatestData();
    }
    dataFetch();

    // console.log(currentSite);
  }, [site_name, serialNum]);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => setIsLoading(true);
  }, []);

  const handleRefresh = () => {
    fetchLatestData();
  };

  const handleSiteChange = (e) => {
    const site = e;
    const newSiteInfo = siteInfo.filter(
      (info) =>
        info.contract_ref.toLowerCase() === site.toString().toLowerCase()
    )[0];
    if (newSiteInfo) {
      const newSiteSerials = newSiteInfo.serial_number;
      const splitSerial = newSiteSerials.split("/");
      const formattedSerial = `${splitSerial[0]}${splitSerial[1]}`.toString();
      setActiveKey("first");
      setActivePage("System Overview");
      navigate(`/dashboard/sites/${site}/${formattedSerial}`);
    }
  };

  const handleSerialChange = (e) => {
    const serial = e;
    const splitSerial = serial.split("/");
    const formattedSerial = `${splitSerial[0]}${splitSerial[1]}`.toString();
    setActiveKey("first");
    navigate(`/dashboard/sites/${site_name}/${formattedSerial}`);
  };

  return (
    <>
      {isLoading && <LoadingScreen />}
      {!isLoading && (
        <>
          {" "}
          <Container
            className="m-0 p-0"
            fluid
            style={{ height: "100%", width: "100%" }}
          >
            <Tab.Container
              activeKey={activeKey}
              className="site-tab-con"
              style={{ height: "100%", width: "100%" }}
            >
              {" "}
              <Accordion flush className="mobile-siteview-nav">
                <Accordion.Item eventKey="0">
                  <Accordion.Header className="text-center">
                    <Col className="my-auto">
                      <h5 className="p-0 m-0">
                        {siteLocation} | {site_name.toUpperCase()}
                      </h5>
                    </Col>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Col>
                      <Nav variant="pills" className="flex-column">
                        <Nav.Item>
                          <Nav.Link
                            className="blank-navlink"
                            eventKey="first"
                            onClick={() => setActiveKey("first")}
                          >
                            <Row className="m-0">
                              <Button className="sidebar-btn">
                                System Overview
                              </Button>
                            </Row>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            className="blank-navlink"
                            eventKey="second"
                            onClick={() => setActiveKey("second")}
                          >
                            <Row className="m-0">
                              <Button className="sidebar-btn">
                                Battery Status
                              </Button>
                            </Row>
                          </Nav.Link>
                        </Nav.Item>
                        {latestData?.ev_charge_quantity !== undefined && (
                          <Nav.Item>
                            <Nav.Link
                              className="blank-navlink"
                              eventKey="third"
                              onClick={() => setActiveKey("third")}
                            >
                              <Row className="m-0">
                                <Button className="sidebar-btn">
                                  EV Charger Status
                                </Button>
                              </Row>
                            </Nav.Link>
                          </Nav.Item>
                        )}

                        <Nav.Item>
                          <Nav.Link
                            className="blank-navlink"
                            eventKey="fourth"
                            onClick={() => setActiveKey("fourth")}
                          >
                            <Row className="m-0">
                              <Button className="sidebar-btn">
                                Inverter Status
                              </Button>
                            </Row>
                          </Nav.Link>
                        </Nav.Item>

                        <Nav.Item>
                          <Nav.Link
                            className="blank-navlink"
                            eventKey="fifth"
                            onClick={() => setActiveKey("fifth")}
                          >
                            <Row className="m-0">
                              <Button className="sidebar-btn">
                                AC Meter Status
                              </Button>
                            </Row>
                          </Nav.Link>
                        </Nav.Item>

                        {latestData?.ac_solar_quantity ? (
                          <Nav.Item>
                            <Nav.Link
                              className="blank-navlink"
                              eventKey="sixth"
                              onClick={() => setActiveKey("sixth")}
                            >
                              <Row className="m-0">
                                <Button className="sidebar-btn">
                                  {" "}
                                  {latestData?.ac_solar_quantity !== 0
                                    ? "DC"
                                    : "AC"}{" "}
                                  Solar Status
                                </Button>
                              </Row>
                            </Nav.Link>
                          </Nav.Item>
                        ) : latestData.dc_solar_quantity ? (
                          <Nav.Item>
                            <Nav.Link
                              className="blank-navlink"
                              eventKey="sixth"
                              onClick={() => setActiveKey("sixth")}
                            >
                              <Row className="m-0">
                                <Button className="sidebar-btn">
                                  {" "}
                                  {latestData.ac_solar_quantity !== 0
                                    ? "DC"
                                    : "AC"}{" "}
                                  Solar Status
                                </Button>
                              </Row>
                            </Nav.Link>
                          </Nav.Item>
                        ) : (
                          ""
                        )}
                        <Nav.Item>
                          <Nav.Link
                            className="blank-navlink"
                            eventKey="seventh"
                            onClick={() => setActiveKey("seventh")}
                          >
                            <Row className="m-0">
                              <Button className="sidebar-btn">Reports</Button>
                            </Row>
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Col>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <Container
                fluid
                className="site-view-container p-0"
                style={{
                  width: "100%",
                  height: "100%",
                  overflowY: "scroll",
                  overflowX: "hidden",
                }}
              >
                <Container
                  id="overlay"
                  fluid
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "black",
                    visibility: switching ? "visible" : "none",
                    opacity: switching ? 0.5 : 0,
                    pointerEvents: switching ? "" : "none",
                    position: "absolute",
                    zIndex: 3,
                    transition: "all 0.4s ease",
                  }}
                >
                  <Row
                    className="p-0 m-0"
                    style={{ height: "100%", width: "100%" }}
                  >
                    <Container
                      style={{ width: "fit-content", height: "fit-content" }}
                      className="mx-auto my-auto"
                    >
                      <Spinner
                        className="mx-auto my-auto"
                        animation="grow"
                        variant="light"
                      />
                    </Container>
                  </Row>
                </Container>
                <Row
                  style={{ height: "100%" }}
                  className="siteview-sidebar-container p-0 m-0"
                >
                  <Col xs={3} md={2} className="siteview-sidebar">
                    <Container fluid className="p-0">
                      <Row
                        className="text-center mt-1"
                        style={{ height: "fit-content" }}
                      >
                        <Col>
                          <>
                            <Dropdown
                              as={ButtonGroup}
                              className="my-1"
                              title="Contract Reference"
                            >
                              <Button variant="light">
                                <h4 className="m-0">
                                  {site_name.toUpperCase()}
                                </h4>
                              </Button>
                              <Dropdown.Toggle
                                split
                                variant="light"
                                id="site-select-dropdown"
                              />
                              <Dropdown.Menu
                                style={{
                                  maxHeight: "15rem",
                                  overflowY: "scroll",
                                }}
                              >
                                {userProjects.map((project, idx) => (
                                  <Dropdown.Item
                                    key={"site-dropdown-" + idx}
                                    onClick={() => handleSiteChange(project)}
                                  >
                                    {project}
                                  </Dropdown.Item>
                                ))}
                              </Dropdown.Menu>
                            </Dropdown>
                          </>
                          {serialArr && (
                            <>
                              <p className="m-0">Serial Number:</p>
                              <Dropdown
                                as={ButtonGroup}
                                className="my-1"
                                title="System Serial Number"
                              >
                                <Button variant="light" size="sm">
                                  <h4 className="m-0">
                                    {serialNum === "undefined"
                                      ? "0000/0000"
                                      : serialNum.slice(0, 4) +
                                        "/" +
                                        serialNum.slice(4, 8)}
                                  </h4>
                                </Button>
                                <Dropdown.Toggle
                                  size="sm"
                                  split
                                  variant="light"
                                  id="site-serial-dropdown"
                                />
                                <Dropdown.Menu
                                  defaultValue={
                                    serialNum.slice(0, 4) +
                                    "/" +
                                    serialNum.slice(4)
                                  }
                                  style={{
                                    maxHeight: "15rem",
                                    overflowY: "scroll",
                                  }}
                                >
                                  {serialArr?.map((serial, idx) => (
                                    <Dropdown.Item
                                      key={"serial-dropdown-" + idx}
                                      onClick={() => handleSerialChange(serial)}
                                    >
                                      {serial}
                                    </Dropdown.Item>
                                  ))}
                                </Dropdown.Menu>
                              </Dropdown>
                            </>
                          )}
                          <h4>{siteLocation}</h4>
                          <h6>{productType}</h6>
                          <hr />
                        </Col>
                      </Row>

                      <Row className="">
                        <Col className="p-0">
                          <Nav variant="pills" className="flex-column">
                            <Nav.Item>
                              <Nav.Link
                                onClick={(b) => {
                                  setActivePage(b.target.text);
                                  setActiveKey("first");
                                }}
                                eventKey="first"
                              >
                                System Overview
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link
                                onClick={(b) => {
                                  setActivePage(b.target.text);
                                  setActiveKey("second");
                                }}
                                eventKey="second"
                              >
                                Battery Status
                              </Nav.Link>
                            </Nav.Item>

                            {latestData.ev_charge_quantity !== undefined && (
                              <Nav.Item>
                                <Nav.Link
                                  onClick={(b) => {
                                    setActivePage(b.target.text);
                                    setActiveKey("third");
                                  }}
                                  eventKey="third"
                                >
                                  EV Charger Status
                                </Nav.Link>
                              </Nav.Item>
                            )}
                            <Nav.Item>
                              <Nav.Link
                                onClick={(b) => {
                                  setActivePage(b.target.text);
                                  setActiveKey("fourth");
                                }}
                                eventKey="fourth"
                              >
                                Inverter Status
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link
                                onClick={(b) => {
                                  setActivePage(b.target.text);
                                  setActiveKey("fifth");
                                }}
                              >
                                AC Meter Status
                              </Nav.Link>
                            </Nav.Item>
                            {latestData.ac_solar_quantity !== undefined ? (
                              <Nav.Item>
                                <Nav.Link
                                  onClick={(b) => {
                                    setActivePage(b.target.text);
                                    setActiveKey("sixth");
                                  }}
                                  eventKey="sixth"
                                >
                                  {" "}
                                  {latestData?.ac_solar_quantity >= 1
                                    ? "AC"
                                    : "DC"}{" "}
                                  Solar Status
                                </Nav.Link>
                              </Nav.Item>
                            ) : latestData?.dc_solar_quantity !== undefined ? (
                              <Nav.Item>
                                <Nav.Link
                                  onClick={(b) => {
                                    setActivePage(b.target.text);
                                    setActiveKey("sixth");
                                  }}
                                  eventKey="sixth"
                                >
                                  {latestData?.ac_solar_quantity >= 1
                                    ? "AC"
                                    : "DC"}{" "}
                                  Solar Status
                                </Nav.Link>
                              </Nav.Item>
                            ) : (
                              ""
                            )}
                            <Nav.Item>
                              <Nav.Link
                                onClick={(b) => {
                                  setActivePage(b.target.text);
                                  setActiveKey("seventh");
                                }}
                                eventKey="seventh"
                              >
                                Reports
                              </Nav.Link>
                            </Nav.Item>
                          </Nav>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                  <Col
                    id="site-view-right"
                    className="m-0 p-0"
                    style={{ width: "100%" }}
                  >
                    <Tab.Content className="">
                      <Row className="m-0 mt-2" style={{ display: "flex" }}>
                        <Button
                          style={{ width: "fit-content", fontSize: "0.8rem" }}
                          onClick={() => navigate("/dashboard")}
                          className="my-auto ms-2 me-auto"
                        >
                          <IoMdArrowRoundBack style={{ fontSize: "1.3rem" }} />{" "}
                          Return
                        </Button>

                        {!isDesktop && (
                          <Dropdown style={{ width: "fit-content" }}>
                            <Dropdown.Toggle
                              variant="light"
                              id="mobile-site-nav-dropdown"
                            >
                              {serialNum.toString().slice(0, 4) +
                                "/" +
                                serialNum.toString().slice(4, 8)}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {serialArr?.map((serial, idx) => (
                                <Dropdown.Item
                                  key={"serial-dropdown-" + idx}
                                  onClick={() => handleSerialChange(serial)}
                                >
                                  {serial}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        )}

                        {/* {activePage === "Reports" && (
                          <p className="ms-auto me-2" style={{ width: "fit-content" }}>
                            <FaCircleQuestion />
                          </p>
                        )}

                        {activePage === "Reports" && (
                          <Button
                            id="generate-report-btn"
                            onClick={() => createPDFToExport()}
                            title="Export to PDF"
                            className="ms-auto me-2 my-auto"
                          >
                            <MdSaveAlt /> Export
                          </Button>
                        )} */}
                      </Row>

                      {activePage !== "Reports" && (
                        <>
                          {" "}
                          <Row className="m-0 p-0">
                            <h6 className="text-center my-2">
                              Data last reported:{" "}
                              {lastReported() === "Invalid Date at Invalid Date"
                                ? "N/A"
                                : lastReported()}
                            </h6>
                          </Row>
                          <Row
                            className="p-0 mx-auto"
                            style={{ width: "fit-content" }}
                          >
                            <FiRefreshCcw
                              onClick={() => handleRefresh()}
                              className={
                                dataLoading
                                  ? "refresh-spinning"
                                  : "refresh-spinning refresh-spinning-pause"
                              }
                              id="refresh_data_btn"
                              style={{ fontSize: "1.5rem" }}
                              title="Fetch Latest Data"
                            />
                          </Row>
                        </>
                      )}

                      {/* overview page */}
                      <Tab.Pane eventKey="first">
                        <Container
                          className="p-0 m-0"
                          fluid
                          style={{
                            display: "flex",
                            height: "fit-content",
                            width: "100%",
                          }}
                        >
                          <Overview />
                        </Container>
                      </Tab.Pane>
                      {/* battery status page */}
                      <Tab.Pane eventKey="second">
                        <Container
                          fluid
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            height: "fit-content",
                          }}
                        >
                          {isDesktop && isDataDisplayed() ? (
                            <BatteryPage />
                          ) : (
                            ""
                          )}
                          {!isDesktop && isDataDisplayed() ? (
                            <MobileBattery />
                          ) : (
                            ""
                          )}
                        </Container>
                      </Tab.Pane>
                      {/* EV charger status page */}
                      <Tab.Pane eventKey="third">
                        <Container fluid className="">
                          {isDataDisplayed() && <EVPage site={site_name} />}
                        </Container>
                      </Tab.Pane>
                      <Tab.Pane eventKey="fourth">
                        <Container
                          fluid
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {isDataDisplayed() ? <InverterPage /> : ""}
                        </Container>
                      </Tab.Pane>

                      <Tab.Pane eventKey="fifth">
                        <Container fluid>
                          {isDataDisplayed() ? <AcInvPage /> : ""}
                        </Container>
                      </Tab.Pane>

                      <Tab.Pane eventKey="sixth">
                        <Container>{isDataDisplayed() && <Solar />}</Container>
                      </Tab.Pane>
                      <Tab.Pane eventKey="seventh">
                        <Container
                          fluid
                          style={{
                            height: "fit-content",
                            display: "flex",
                            flexDirection: "column",
                            overflowY: "hidden",
                          }}
                        >
                          {isDataDisplayed() ? (
                            <ReportPage
                              showReportAck={showReportAck}
                              setReportAck={setShowReportAck}
                              currentPage={activePage}
                            />
                          ) : (
                            ""
                          )}
                        </Container>
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Container>
            </Tab.Container>
          </Container>
        </>
      )}
    </>
  );
};

export default NewStatusPage;
