import { Button, Card, Container, Form, Row } from "react-bootstrap";
import { useState } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { Link } from "react-router-dom";
import axios from "axios";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");

  const toSend = {
    email: email,
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const submit = async () => {
      try {
        const response = axios.post(
          `${process.env.REACT_APP_BE_URL}/users/requestReset`,
          toSend
        );
      } catch (err) {
        console.error(err);
      }
    };

    submit();
  };

  const [showNext, setShowNext] = useState(false);
  return (
    <>
      <Container
        className="forgot-password-con"
        fluid
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "100%",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        <Card className="mx-auto">
          <Card.Header as="h5">Password Reset</Card.Header>
          <Card.Body className="m-3">
            {showNext && email ? (
              <Container
                fluid
                style={{
                  width: "80%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                <p className="m-0">
                  If the email address entered matches a registered account, an
                  email has been sent with password reset instructions.
                </p>
                <p className="mt-2 m-0">
                  This email may be in your spam folder.
                </p>
                <p className="m-0">
                  <Link to="/">Return to Login</Link>
                </p>
              </Container>
            ) : (
              <Container fluid style={{ width: "80%" }}>
                <p className="m-0">
                  Enter the email address associated with your account.
                </p>
                <Form style={{ width: "100%" }} className="mx-auto">
                  <Form.Group className="my-3">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Email Address"
                      className="mb-3"
                    >
                      <Form.Control
                        type="email"
                        placeholder="name@example.com"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please enter a valid email address.
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Form.Group>

                  <Row className="m-0">
                    <Button
                      type="submit"
                      disabled={email ? false : true}
                      onClick={(e) => {
                        setShowNext(true);
                        handleSubmit(e);
                      }}
                    >
                      Next
                    </Button>
                  </Row>
                </Form>

                <Row className="mt-2 text-center">
                  <p className="m-0">
                    <Link to="/">Return to Login</Link>
                  </p>
                </Row>
              </Container>
            )}
          </Card.Body>
        </Card>
      </Container>
    </>
  );
};

export default ForgotPassword;
