import { Button, Container, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

//error page which should be replaced with something a bit less hoers
const ErrorPage = () => {
  return (
    <>
      <Container fluid className="error-page-con">
        <h4>An error has occurred.</h4>

        <a href="/">
          <Button>Return to Login</Button>
        </a>
      </Container>
    </>
  );
};

export default ErrorPage;
