// generates random password
export const generatePassword = () => {
  const length = 8;
  const charset =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let returnVal = "";
  for (let i = 0, n = charset.length; i < length; i++) {
    returnVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return returnVal;
};
