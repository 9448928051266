import { useEffect } from "react";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "404 - Page Not Found";
  }, []);

  // 404 page, come back to make look better
  return (
    <>
      <Container
        fluid
        className="text-center"
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "center",
        }}
      >
        <Container>
          <h1>Page not found</h1>
          <h2 id="return-link" onClick={() => navigate(-1)}>
            Return?
          </h2>
        </Container>
      </Container>
    </>
  );
};

export default NotFound;
