/* eslint-disable no-eval */
/* eslint-disable react-hooks/exhaustive-deps */
import { Container, Row, Col } from "react-bootstrap";
import BatteryComp from "./BatteryComp";
import { useFetchData } from "../../../../Hooks/useFetchData";

const BatteryPage = () => {
  const {
    dcVoltage,
    dcCurrent,
    dcPower,
    soc,
    avgCellTemp,
    maxCellTemp,
    minCellTemp,
    maxChargePower,
    maxDischargePower,
    cellVoltageMin,
    cellVoltageAvg,
    cellVoltageMax,
    maxCapacity,
    onlineCapacity,
    cycles,
  } = useFetchData("battery");

  return (
    <Container fluid style={{ height: "100%" }} key={"battery-page"}>
      <Row style={{ height: "100%" }}>
        {/* index is always second argument */}
        {soc?.map((item, index) => (
          <Col key={"battery-" + index} style={{ height: "100%" }}>
            <BatteryComp
              key={`battery-comp` + index}
              soc={soc[index]}
              num={index + 1}
              current={dcCurrent[index]}
              voltage={dcVoltage[index]}
              power={dcPower[index]}
              minCellTemp={minCellTemp[index]}
              maxCellTemp={maxCellTemp[index]}
              avgCellTemp={avgCellTemp[index]}
              maxChargePower={maxChargePower[index]}
              maxDischargePower={maxDischargePower[index]}
              cellVoltageMin={cellVoltageMin[index]}
              cellVoltageAvg={cellVoltageAvg[index]}
              cellVoltageMax={cellVoltageMax[index]}
              maxCapacity={maxCapacity[index]}
              onlineCapacity={onlineCapacity[index]}
              cycles={cycles[index]}
            />
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default BatteryPage;
