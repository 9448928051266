import { useEffect, useState } from "react";
import { Alert, Button, Card, Container, Form, Row } from "react-bootstrap";
import { useSearchParams, Link } from "react-router-dom";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import axios from "axios";
import { toast } from "react-toastify";

const ResetPassword = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get("token");
  const id = searchParams.get("id");

  const [password, setPassword] = useState("");
  const [verify, setVerify] = useState("");
  const [error, setError] = useState(false);
  const [tokenVerified, setTokenVerified] = useState(false);
  const [tokenInvalid, setTokenInvalid] = useState(false);

  const passwordSuccess = () => {
    return toast.success("Password updated.", {
      toastId: 'password-change-success'
    });
  };

  useEffect(() => {
    async function confirmToken() {
      const response = await axios.post(
        `${process.env.REACT_APP_BE_URL}/users/verifyRequest?token=${token}&id=${id}`,
        {},
        {
          validateStatus: () => true,
        }
      );
      if (response.status === 200) {
        return setTokenVerified(true);
      }
      if (response.status === 401) {
        return setTokenInvalid(true);
      }
    }

    confirmToken();
  }, []);

  const toSend = {
    password: password,
  };

  const postNewPass = async (e) => {
    e.preventDefault();
    const response = await axios.post(
      `${process.env.REACT_APP_BE_URL}/users/resetPass?token=${token}`,
      toSend
    );

    if (response.status === 201) {
      passwordSuccess();
    }
  };

  //password strength check

  return (
    <>
      <>
        <Container
          className="forgot-password-con"
          fluid
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            width: "100%",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <Card className="mx-auto">
            <Card.Header as="h5">Password Reset</Card.Header>
            <Card.Body className="m-3">
              {tokenVerified === true && (
                <Container fluid style={{ width: "80%" }}>
                  <p className="m-0">
                    Please enter the new password for your account.
                  </p>
                  <Form style={{ width: "100%" }} className="mx-auto">
                    <Form.Group className="my-3">
                      <FloatingLabel
                        controlId="floatingInput"
                        label="New Password"
                        className="mb-3"
                      >
                        <Form.Control
                          type="password"
                          placeholder="New Password"
                          value={password}
                          onChange={(e) => {
                            setPassword(e.target.value.toString());
                            if (password === verify) {
                              setError(false);
                            }
                          }}
                        />
                      </FloatingLabel>
                    </Form.Group>
                    <Form.Group className="my-3">
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Confirm New Password"
                        className="mb-3"
                      >
                        <Form.Control
                          type="password"
                          placeholder="Confirm New Password"
                          value={verify}
                          onChange={(e) => {
                            setVerify(e.target.value.toString());
                          }}
                        />
                      </FloatingLabel>
                    </Form.Group>
                    <Row className="m-0">
                      {password !== verify && (
                        <Alert variant="warning">Passwords must match!</Alert>
                      )}
                    </Row>
                    <Row className="m-0">
                      <Button
                        onClick={(e) => {
                          postNewPass(e);
                        }}
                        disabled={password && verify ? false : true}
                      >
                        Submit
                      </Button>
                    </Row>
                  </Form>

                  <Row className="mt-2 text-center">
                    <p className="m-0">
                      <Link to="/">Return to Login</Link>
                    </p>
                  </Row>
                </Container>
              )}
              {tokenInvalid === true && (
                <Container
                  fluid
                  className="text-center"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    width: "100%",
                    justifyContent: "center",
                    alignContent: "center",
                  }}
                >
                  <h5>Your link has expired. Please submit another request.</h5>
                  <Link to="/login/identify">Click Here</Link>
                </Container>
              )}
            </Card.Body>
          </Card>
        </Container>
      </>
    </>
  );
};

export default ResetPassword;
