import { Container, Row } from "react-bootstrap";
import Arrow from "../../../../images/new/arrow.svg";
import EVCharge from "../../../../images/new/ev_charge.svg";
import { useState, useEffect, useContext } from "react";
import { useIsDesktop } from "../../../../Hooks/useIsDesktop";
import UserContext from "../../../../Context/UserProvider";

const Siteload = ({ power, evQty }) => {
  // const evOnline = useSelector((state) => state?.data?.ev_charger_online);
  const evOnline = 0;
  const { theme } = useContext(UserContext);
  const globalTheme = theme;

  const isDesktop = useIsDesktop();

  return (
    <>
      {isDesktop && (
        <Container className="overview-bottom-box">
          {evQty === 0 || evQty === undefined ? (
            <Row
              className="text-center"
              style={{
                border: "1px solid red",
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h2 className="m-0">Site Load: {power}kW</h2>
            </Row>
          ) : (
            <Row
              className="text-center"
              style={{
                // border: "1px solid red",
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h2 className="m-0">Site Load: {power}kW</h2>
              <img
                src={EVCharge}
                className="img-fluid mx-auto"
                alt="EV Charger"
                style={{ maxWidth: "5.5rem" }}
              />
              <h6>
                {evOnline}/{evQty} Online
              </h6>
            </Row>
          )}
        </Container>
      )}
      {!isDesktop && (
        <Container
          className="text-center mobile-siteload"
          style={{
            // backgroundColor: "rgba(255,255,255,0.4)",
            borderRadius: "8px",
            height: "8rem",
          }}
        >
          <Row
            className="text-center"
            style={{
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {evQty !== 0 && (
              <>
                <h4>
                  {evOnline}/{evQty} Chargers Online
                </h4>
                <h2>Site Load: {power || "--"}kW</h2>
              </>
            )}
          </Row>
        </Container>
      )}
    </>
  );
};

export default Siteload;
