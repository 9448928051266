import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
} from "react-bootstrap";
import { useContext, useState } from "react";
import { useAxiosPrivate } from "../../../Hooks/useAxiosPrivate";
import { useNavigate } from "react-router-dom";
import useAuth from "../../../Hooks/useAuth";
import { toast } from "react-toastify";
import StatusContext from "../../../Context/StatusProvider";
import ErrorBoundary from "../../Misc/ErrorBoundary";
import { useErrorBoundary } from "react-error-boundary";

// page that appears upon first login, prompting user to change their password from the preset one.
const FirstLogin = () => {
  const { showBoundary } = useErrorBoundary();
  // axios private uses an interceptor to intercept any requests where the access token has expired, and refreshes it using refresh token stored in a httpOnly cookie
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();

  const { setPasswordChange } = useAuth();

  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");

  // toast notification on password change
  const passwordChange = () => {
    return toast.info("Password successfully changed", {
      toastID: "passwdchange",
    });
  };

  const { isError, setIsError, errorMessage, setErrorMessage } =
    useContext(StatusContext);

  const errorState = isError;

  //   error and error message states
  // const errorState = useSelector((state) => state?.general?.isError);
  // const errorMessage = useSelector((state) => state?.general.errorMessage);

  //   object to send
  const toSend = {
    password: password,
  };

  //   function that handle form submit
  const onSubmit = async (e) => {
    e.preventDefault();
    if (password === passwordConfirm) {
      const response = await axiosPrivate.put("/users/me", toSend, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      });
      if (response) {
        passwordChange();
        navigate("/dashboard");
      }
    } else {
      setIsError(true);

      setErrorMessage("Passwords must match!");
      // dispatch(setErrorMessage("Passwords must match."));
    }
  };

  return (
    <>
      <Container fluid style={{ height: "100%" }} className="first-login-con">
        <Row
          style={{
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Col md={6}>
            {errorState && <Alert variant="danger">{errorMessage}</Alert>}
            <Card>
              <Card.Header as="h5">Set Your Password</Card.Header>
              <Card.Body>
                <p className="text-center">
                  As it is your first time logging in, you are required to set a
                  memorable password for your account.
                </p>
                <Container fluid>
                  <Form autoComplete="off" onSubmit={(e) => onSubmit(e)}>
                    <Form.Group className="mb-3">
                      <Form.Label>Enter your new password</Form.Label>
                      <Form.Control
                        onChange={(e) => setPassword(e.target.value)}
                        type="password"
                        placeholder="New password"
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Confirm new password</Form.Label>
                      <Form.Control
                        onChange={(e) => setPasswordConfirm(e.target.value)}
                        type="password"
                        placeholder="Password confirmation"
                      />
                    </Form.Group>
                    <Row>
                      <Button
                        className="mx-auto"
                        style={{ width: "fit-content" }}
                        type="submit"
                      >
                        Submit
                      </Button>
                    </Row>
                  </Form>
                </Container>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default FirstLogin;
