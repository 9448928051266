import { Container } from "react-bootstrap";
import MapView from "./MapViewComp.jsx";
import { useEffect } from "react";

const MainView = ({ siteDataLoaded }) => {
  //main right hand section of dashboard landing page

  return (
    <>
      <Container
        fluid
        className="p-0"
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: "transparent",
        }}
      >
        {/* <SoCView /> */}
        <Container fluid className="p-0">
          <MapView siteDataLoaded={siteDataLoaded} />
        </Container>
      </Container>
    </>
  );
};

export default MainView;
