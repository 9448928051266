import { Container } from "react-bootstrap";
import CreateSystemLayout from "./CreateSystemLayout";
import { useParams } from "react-router-dom";
import NewCreateLayout from "./NewCreateLayout";

const LayoutMain = () => {
  const { siteCxp, serialNumber } = useParams();
  return (
    <>
      <Container
        fluid
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <NewCreateLayout
          key={serialNumber.toString()}
          cxp={siteCxp.toString()}
          sn={serialNumber.toString()}
        />
      </Container>
    </>
  );
};

export default LayoutMain;
