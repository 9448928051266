import { Container, Row } from "react-bootstrap";
import { useFetchData } from "../../../../Hooks/useFetchData";
import ACMeterComp from "./ACMeterComp";

const AcInvPage = () => {
  const {
    acAvgPhaseVoltage,
    acAvgLineVoltage,
    acAvgCurrent,
    acFrequency,
    acTotalPowerFactor,
    acTotalActivePower,
  } = useFetchData("acmeter");

  const { importPowerHi, importPowerLo, exportPowerHi, exportPowerLo } =
    useFetchData("importexport");

  const shiftBitsAround = (lo = 0, hi = 0) => {
    let number = (hi << 16) + lo;

    let suffix = "kWh";

    if (number === 0) {
      return "--kWh";
    } else if (number > 10000) {
      suffix = "MWh";
      number = number / 10000;
      const stringToReturn = `${number.toFixed(2)}${suffix}`;
      return stringToReturn;
    } else {
      number = number / 10;
      const stringToReturn = `${number.toFixed(2)}${suffix}`;
      return stringToReturn;
    }
  };

  return (
    <Container
      key="ac-inv-page"
      fluid
      className="p-0 m-0"
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {acTotalActivePower &&
        acTotalActivePower.map((ac, idx) => (
          <Row
            key={"ac-total" + idx}
            className="m-0 mt-2 p-0 text-center"
            style={{
              // border: "1px solid red",
              width: "100%",
              height: "fit-content",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ACMeterComp
              key={"AC-meter-comp" + idx}
              avgPhaseVoltage={acAvgPhaseVoltage[idx]}
              avgLineVoltage={acAvgLineVoltage[idx]}
              avgCurrent={acAvgCurrent[idx]}
              totalPowerFactor={acTotalPowerFactor[idx]}
              totalActivePower={acTotalActivePower[idx]}
              frequency={acFrequency[idx]}
              importPower={shiftBitsAround(
                importPowerLo[idx],
                importPowerHi[idx]
              )}
              exportPower={shiftBitsAround(
                exportPowerLo[idx],
                exportPowerHi[idx]
              )}
              num={idx + 1}
            />
          </Row>
        ))}
    </Container>
  );
};

export default AcInvPage;
