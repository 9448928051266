import { Container } from "react-bootstrap";
import NotificationSelection from "./selection";

const NotificationPage = () => {
  //main component that displays any fault/warning information to the user
  //allows for settings to be changed in regards to fault notifications
  //emails first -> investigate SMS further down the line
  //push notifications? both desktop and mobile. (((only if the user opts in!!)))

  //on page load ->
  //

  return (
    <>
      <Container fluid style={{ padding: "1rem" }}>
        <Container
          fluid
          className="m-0 p-0 "
          style={{
            // backgroundColor: "rgba(255,255,255,0.5)",
            // width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            // justifyContent: "center",
          }}
        >
          <Container
            className="mb-3 m-0 p-0"
            fluid
            style={{ backgroundColor: "rgba(0, 0, 0, 0.4)", height: "15rem" }}
          >
            <NotificationSelection />
          </Container>
          <Container
            fluid
            style={{
              backgroundColor: "rgba(0, 0, 0 ,0.3)",
              height: "100%",
              marginTop: "auto",
              marginBottom: "auto",
            }}
          ></Container>
        </Container>
      </Container>
    </>
  );
};

export default NotificationPage;
