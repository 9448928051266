import { Container, Row, Button } from "react-bootstrap";
import BlankCard from "./BlankCard";
import { Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import BackButtonRow from "./BackButtonRow";

const HelpPage = () => {
  const navigate = useNavigate();

  return (
    <>
      <Container
        className="help-page-con p-0 m-0"
        fluid
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          // justifyContent: "center",
          alignItems: "center",
        }}
      >
        <BackButtonRow />
        <Container
          className="mt-4"
          style={{ height: "fit-content", maxWidth: "40rem" }}
        >
          <BlankCard title="Help and Support">
            <Container
              fluid
              style={{
                height: "fit-content",
                // padding: "1.5rem",
                // paddingBottom: 0,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Accordion flush>
                <Accordion.Item eventKey={0}>
                  <Accordion.Header>
                    I'm having trouble logging in.
                  </Accordion.Header>
                  <Accordion.Body>
                    <Container className="p-0">
                      <p className="m-0">
                        If you're having issues logging in, try resetting your
                        password <Link to="/login/identify">here</Link>. If
                        you're still unable to access your account, please
                        contact us at{" "}
                        <a href="mailto:info@multisourcepower.com">
                          info@multisourcepower.com
                        </a>
                        .
                      </p>
                    </Container>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey={1}>
                  <Accordion.Header>
                    I haven't received an email with my temporary password.
                  </Accordion.Header>

                  <Accordion.Body>
                    <Container className="p-0">
                      <p className="m-0">
                        You should receive two emails from{" "}
                        <a href="mailto:NoReply@multisourcepower.com">
                          NoReply@multisourcepower.com
                        </a>
                        . If you haven't received these emails, please check
                        your spam folder.
                      </p>
                      <p>
                        If you are still missing these emails, please contact{" "}
                        <a href="mailto:info@multisourcepower.com">
                          info@multisourcepower.com
                        </a>
                      </p>
                    </Container>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey={2}>
                  <Accordion.Header>
                    One of my systems has not reported data in a while - is it
                    offline?
                  </Accordion.Header>
                  <Accordion.Body>
                    <Container className="p-0">
                      <p className="m-0">
                        The majority of our systems report data to the server
                        via a 4G cellular connection. On occasion, the cellular
                        connection may be lost due to a variety of reasons
                        outside of our control. If this happens, the system will
                        continue to collect data and store it locally.
                      </p>
                      <p>
                        If you have any other concerns regarding your system,
                        don't hesitate to get in contact with us.
                      </p>
                    </Container>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey={3}></Accordion.Item>
              </Accordion>

              <Container>
                <p className="my-3 text-center">
                  Any other questions or problems? Contact us at{" "}
                  <a href="mailto:info@multisourcepower.com">
                    info@multisourcepower.com
                  </a>
                  .{" "}
                </p>
              </Container>
            </Container>
          </BlankCard>
        </Container>
      </Container>
    </>
  );
};

export default HelpPage;
