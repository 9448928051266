/* eslint-disable react-hooks/exhaustive-deps */
import { Container, Row, Col } from "react-bootstrap";
import { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import PV from "./Components/PV";
import Battery from "./Components/Battery";
import Grid from "./Components/Grid";
import Siteload from "./Components/Siteload";
import { BarLoader } from "react-spinners";
import { useIsDesktop } from "../../../Hooks/useIsDesktop";
import GlobalDataContext from "../../../Context/DataProvider";

//creates system overview layout based on
const NewCreateLayout = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [deviceName, setDeviceName] = useState([]);
  const [deviceQty, setDeviceQty] = useState([]);

  const params = useParams();

  const siteCxp = params.siteCxp;
  const serialNumber = params.serialNumber;

  const { siteInfo } = useContext(GlobalDataContext);

  let devicesList = [];

  let devicesQty = [];

  const formattedSerial = `${serialNumber.slice(0, 4)}/${serialNumber.slice(
    4
  )}`;

  const currentSiteInfo = siteInfo.filter(
    (el) => el.serial_number === formattedSerial
  )[0];

  const getDeviceData = async () => {
    const devs = [];
    const qty = [];

    // setCurrentSystemData(currentSiteInfo);

    Object.entries(currentSiteInfo?.devices).forEach(([key, value]) => {
      devs.push(key);
      qty.push(value);
    });

    setDeviceName(devs);
    setDeviceQty(qty);
  };

  useEffect(() => {
    getDeviceData();
    setTimeout(() => {
      setLoading(false);
    }, 200);
  }, [siteCxp, serialNumber]);

  // const devicesList = currentSystemData?.devices;
  // const devicesQty = currentSystemData?.qty;

  const solarExists = () => {
    if (
      deviceName.includes("ac_solar_quantity") ||
      deviceName.includes("dc_solar_quantity")
    ) {
      return isDesktop ? (
        <PV />
      ) : (
        <Col
          xs={5}
          className="p-0"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <PV />
        </Col>
      );
    } else {
      return null;
    }
  };
  const gridExists = () => {
    const idx = deviceName.indexOf("ac_meter_quantity");
    if (deviceQty?.[idx] >= 1) {
      return isDesktop ? (
        <Grid />
      ) : (
        <Col
          xs={5}
          className="p-0"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Grid />
        </Col>
      );
    } else {
      return null;
    }
  };
  const batteryExists = () => {
    const idx = deviceName.indexOf("battery_quantity");
    if (deviceQty?.[idx] >= 1) {
      return isDesktop ? (
        <Battery />
      ) : (
        <Col
          className="p-0"
          xs={5}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Battery />
        </Col>
      );
    } else {
      return null;
    }
  };
  const siteLoadExists = () => {
    const idx = deviceName?.indexOf("ac_meter_quantity");
    if (deviceQty?.[idx] > 1 && devicesList?.includes("ev_charge_quantity")) {
      return (
        <Row
          id="site-load"
          className="mx-auto p-0"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "20rem",
          }}
        >
          <Siteload />
        </Row>
      );
    } else {
      return null;
    }
  };

  const isDesktop = useIsDesktop();

  return (
    <>
      <Container
        key={serialNumber.toString()}
        fluid
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {loading && (
          <Container
            fluid
            style={{
              height: "20rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <BarLoader
              color="silver"
              width={"25%"}
              aria-label="Loading Screen"
            />
          </Container>
        )}

        {!loading && isDesktop && (
          <>
            <Container
              fluid
              className="my-2"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Row
                id="power-in"
                className="mx-auto py-3 my-auto m-0 mt-2 text-center"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "2px dashed rgba(0, 0, 255, 0.3)",
                  borderRadius: "8px",
                  width: "100%",
                }}
              >
                {gridExists()}
                {batteryExists()}
                {solarExists()}
              </Row>
              <Row id="site load">{siteLoadExists()}</Row>
            </Container>
          </>
        )}
        {!loading && !isDesktop && (
          <>
            <Container
              fluid
              className="my-2 mx-auto p-0"
              style={{ width: "100%" }}
            >
              <Row
                className="m-0"
                id="power-in"
                style={{
                  display: "flex",
                  width: "100%",
                  border: "2px dotted rgba(0,0,255,0.2)",
                  padding: "0.5rem",
                  borderRadius: "5px",
                  justifyContent: "space-evenly",
                  height: "100%",
                }}
              >
                {gridExists()}
                {batteryExists()}
                {solarExists()}
              </Row>
            </Container>
          </>
        )}
      </Container>
    </>
  );
};

export default NewCreateLayout;
