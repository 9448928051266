import { Button, Container, Row } from "react-bootstrap";
import PhoneIcon from "../../images/new/phone.svg";
import { useEffect } from "react";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

const LandscapePlease = () => {
  const body = document.getElementById("root");
  useEffect(() => {
    disableBodyScroll(body);

    return () => enableBodyScroll(body);
  }, []);

  const splashScreen = document.getElementById("landscape-splash");

  return (
    <>
      <Container
        fluid
        id="landscape-splash"
        style={{
          background:
            "linear-gradient(to bottom, rgba(0,0,0,0.8), rgba(0, 0, 0, 0))",
          height: "100%",
          display: "flex",
          zIndex: "9999",
          flexDirection: "column",
          position: "absolute",
          top: 0,
          left: 0,
          //   justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Container style={{ height: "fit-content", marginTop: "50%" }}>
          <img
            id="phone-icon"
            className="mb-4"
            src={PhoneIcon}
            alt="Outline of mobile phone"
            style={{ width: "15rem", filter: "invert(1)" }}
          />

          <h1 style={{ color: "white" }}>
            Rotate your device for the best experience.
          </h1>
        </Container>
        <Row className="my-4">
          <Button
            onClick={() => {
              splashScreen.style.opacity = "0";

              setTimeout(() => {
                splashScreen.style.display = "none";
              }, 500);

              enableBodyScroll(body);
            }}
          >
            Understood
          </Button>
        </Row>
      </Container>
    </>
  );
};

export default LandscapePlease;
