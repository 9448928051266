/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Container, Spinner } from "react-bootstrap";
import { useEffect, useState, useRef, useContext } from "react";
import Map, { GeolocateControl, Marker, Popup } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { Link } from "react-router-dom";
import UserContext from "../../../Context/UserProvider";
import GlobalDataContext from "../../../Context/DataProvider";

const MapView = ({ siteDataLoaded }) => {
  const { siteInfo } = useContext(GlobalDataContext);
  const { theme } = useContext(UserContext);

  const [showSatellite, setShowSatellite] = useState(false);

  const [mapLoading, setMapLoading] = useState(true);

  const [popupInfo, setPopupInfo] = useState(null);

  const mapRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      setMapLoading(false);
    }, 1500);

    return () => {
      setMapLoading(true);
    };
  }, []);

  const handleClick = (i) => {
    mapRef?.current?.flyTo({
      center: [i.long, i.lat],
      zoom: 10,
      duration: 4000,
    });
  };

  console.log(popupInfo)

  return (
    <>
      <Container
        className="p-0 m-0"
        fluid
        style={{ width: "100%", height: "95vh" }}
      >
        <Container
          fluid
          className="p-0 m-0 map-container"
          style={{
            height: "100%",
            width: "100%",
            overflow: "hidden",
          }}
        >
          {mapLoading === true && (
            <Container
              style={{
                display: "flex",
                height: "100%",
                flexDirection: "column",
              }}
            >
              <Spinner
                animation="border"
                className="my-auto mx-auto"
                variant="info"
              />
            </Container>
          )}
          {siteDataLoaded && (
            <>
              <Button
                className="m-2"
                variant={theme === "dark" ? "secondary" : "primary"}
                style={{ position: "absolute", zIndex: 999 }}
                onClick={() => setShowSatellite(!showSatellite)}
              >
                {showSatellite ? "Show Map" : "Show Satellite"}
              </Button>
              <Map
                style={{ position: "relative" }}
                ref={mapRef}
                mapboxAccessToken={process.env.REACT_APP_MAPBOX_KEY}
                initialViewState={{
                  latitude: 50.001709,
                  longitude: 9.596851,
                  zoom: 4,
                }}
                mapStyle={
                  showSatellite
                    ? "mapbox://styles/mapbox/satellite-v9"
                    : theme === "dark"
                    ? "mapbox://styles/mapbox/navigation-night-v1"
                    : "mapbox://styles/mapbox/navigation-day-v1"
                }
              >
                <GeolocateControl
                  positionOptions={{ enableHighAccuracy: true }}
                  trackUserLocation={true}
                />
                {siteInfo &&
                  siteInfo?.map(
                    (site, idx) =>
                      site?.location && (
                        <Marker
                          key={`marker-${idx}`}
                          longitude={site?.location?.long}
                          latitude={site?.location?.lat}
                          title={site?.projectName}
                          onClick={(e) => {
                            e.originalEvent.stopPropagation();
                            setPopupInfo(site);
                            handleClick(site.location);
                          }}
                        />
                      )
                  )}
                {popupInfo && (
                  <Popup
                    anchor="bottom"
                    longitude={Number(popupInfo.location.long)}
                    latitude={Number(popupInfo.location.lat)}
                    onClose={() => {
                      setPopupInfo(null);
                      mapRef?.current?.flyTo({
                        duration: 5000,
                        center: [9.596851, 50.001709],
                        zoom: 4,
                      });
                    }}
                  >
                    <div>
                      <Link
                        to={
                          "/dashboard/sites/" +
                          popupInfo.contract_ref +
                          "/" +
                          popupInfo.serial_number.split("/")[0].toString() +
                          popupInfo.serial_number.split("/")[1].toString()
                        }
                        style={{ textDecoration: "none" }}
                      >
                        <h5>{popupInfo.contract_ref.toUpperCase()}</h5>
                      </Link>
                    </div>
                  </Popup>
                )}
              </Map>
            </>
          )}
        </Container>
      </Container>
    </>
  );
};

export default MapView;
