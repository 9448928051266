/* eslint-disable react-hooks/exhaustive-deps */
import { Container, Row } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import { useEffect, useState } from "react";
import { useAxiosPrivate } from "../../../../Hooks/useAxiosPrivate";
// import Select from "react-select";
import { useParams } from "react-router-dom";
import { MultiSelect } from "../../../Misc/MultiSelectAll";
import { useIsDesktop } from "../../../../Hooks/useIsDesktop";
// import { setFormatSerials } from "./ReportReducers";
// import { useIsDesktop } from "../../../../Hooks/useIsDesktop";

const ReportSelectorComp = ({
  start,
  setDates,
  end,
  isLoading,
  setIsLoading,
  options,
  setOptions,
  selected,
  setSelected,
}) => {
  //hooks
  const params = useParams();
  const axiosPrivate = useAxiosPrivate();
  const isDesktop = useIsDesktop();
  //- - -

  //params from URL
  const siteCxp = params.siteCxp;
  const siteSerial = params.serialNumber;
  //- - -

  //states
  const [showSerial, setShowSerial] = useState(false);
  //- - -

  // function that fetches site serial numbers from API
  //these are used in multi select component.
  const fetchSerialNumbers = async () => {
    try {
      setIsLoading({
        type: "setLoading",
        payload: true,
      });
      const response = await axiosPrivate.get(`/projects/${siteCxp}/serials`);
      if (response.status === 200 || response.status === 304) {
        const serialArr = response.data;
        const data = [];
        serialArr.forEach((sn) => {
          data.push({ value: sn, label: sn });
        });
        setOptions(data);
        setIsLoading({
          type: "setLoading",
          payload: false,
        });
        setTimeout(() => {
          setShowSerial(true);
        }, 200);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // refetch serial numbers whenever site changes.
  useEffect(() => {
    setSelected([]);
    setOptions([]);
  }, [siteCxp, siteSerial]);

  useEffect(() => {
    fetchSerialNumbers();
  }, [siteCxp]);

  return (
    <>
      <Row
        className="mt-3"
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ width: "fit-content" }}>
          <ReactDatePicker
            placeholderText="Start Date"
            closeOnScroll={true}
            selected={start}
            selectsStart
            value={start?.toLocaleDateString("en-GB")}
            onChange={(date) =>
              setDates({
                type: "setStart",
                payload: date,
              })
            }
            endDate={end}
            maxDate={new Date()}
          />
        </div>
        <div style={{ width: "fit-content" }}>
          <ReactDatePicker
            disabled={start ? false : true}
            closeOnScroll={true}
            placeholderText="End Date"
            selected={end}
            selectsEnd
            value={end?.toLocaleDateString("en-GB")}
            onChange={(date) =>
              setDates({
                type: "setEnd",
                payload: date,
              })
            }
            minDate={start}
            maxDate={new Date()}
          />
        </div>
      </Row>
      <Row className="mt-3" style={{ width: "100%", justifyContent: "center" }}>
        <div
          style={{
            width: isDesktop ? "25%" : "65%",
            overflow: "visible",
          }}
        >
          {showSerial && (
            <MultiSelect
              value={selected}
              onChange={setSelected}
              options={options}
              isMulti
            />
          )}
        </div>
      </Row>
    </>
  );
};

export default ReportSelectorComp;
