import { Card, Container } from "react-bootstrap";
import { Link } from "react-router-dom";

const PasswordChangeSuccess = () => {
  return (
    <>
      <Container
        fluid
        className="success-con"
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        <Container
          fluid
          style={{
            height: "100%",
            width: "60%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Card>
            <Card.Header as="h5">Password Change</Card.Header>
            <Card.Body>
              <Container fluid className="text-center">
                <h5>Your password has been changed.</h5>
                <p className="m-0">
                  <Link to="/">Click Here </Link>to return to Login.
                </p>
              </Container>
            </Card.Body>
          </Card>
        </Container>
      </Container>
    </>
  );
};

export default PasswordChangeSuccess;
