import { Col, Card, Row } from "react-bootstrap";
import EV_Charge from "../../../../images/new/ev_charge.svg";
import EV_0 from "../../../../images/new/evCharge0.svg";
import EV_20 from "../../../../images/new/evCharge20.svg";
import EV_40 from "../../../../images/new/evCharge40.svg";
import EV_60 from "../../../../images/new/evCharge60.svg";
import EV_80 from "../../../../images/new/evCharge80.svg";
import EV_100 from "../../../../images/new/evCharge100.svg";
import { useContext, useState } from "react";
import EVChargerModal from "./EVChargerModal";
import UserContext from "../../../../Context/UserProvider";

const EVChargerCard = ({
  num,
  soc,
  enable_state,
  session_active,
  power_budget,
  duration,
  voltage,
  current,
  power,
  fault,
}) => {
  const [show, setShow] = useState(false);

  const { theme } = useContext(UserContext);
  const globalTheme = theme;
  // const globalTheme = useSelector((state) => state?.user?.theme);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const imageToDisplay = () => {
    if (session_active === false || fault !== 0) {
      return EV_Charge;
    } else if (soc >= 0 && soc <= 19) {
      return EV_0;
    } else if (soc >= 20 && soc <= 39) {
      return EV_20;
    } else if (soc >= 40 && soc <= 59) {
      return EV_40;
    } else if (soc >= 60 && soc <= 79) {
      return EV_60;
    } else if (soc >= 80 && soc <= 99) {
      return EV_80;
    } else if (soc === 100) {
      return EV_100;
    } else {
      return EV_Charge;
    }
  };

  return (
    <>
      <>
        <Col xs={6} md={2} className="mb-3 ev-card">
          <Card onClick={() => handleShow()} style={{ cursor: "pointer" }}>
            <Card.Body
              className={
                fault !== 0 ? "text-center pb-2 muted-card" : "text-center pb-2"
              }
              style={{ height: "18rem", borderRadius: "10px" }}
            >
              <Row style={{ height: "fit-content" }}>
                <h5
                  style={{ width: "fit-content" }}
                  className={
                    globalTheme === "default" ? "text-muted m-0" : "m-0"
                  }
                >
                  {num}
                </h5>
              </Row>
              <Row>
                <img
                  src={imageToDisplay()}
                  alt="EV Charger"
                  className="img-fluid mx-auto"
                  style={{
                    maxWidth: "8rem",
                    filter: globalTheme === "dark" ? "invert(1)" : "invert(0)",
                  }}
                />
              </Row>
              <Row>
                <Col>
                  <h5
                    style={{ color: "var(--text-color)" }}
                    className={globalTheme === "default" ? "text-muted" : ""}
                  >
                    {fault === 0 ? "Online" : "Offline"}
                  </h5>
                  <h5
                    style={{ color: "var(--text-color)" }}
                    className={globalTheme === "default" ? "text-muted" : ""}
                  >
                    {session_active ? "Charging" : "Not Active"}
                  </h5>
                  {soc !== 0 && <h5>Vehicle SoC: {soc}%</h5>}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </>

      <EVChargerModal
        num={num}
        soc={soc}
        enable_state={enable_state}
        session_active={session_active}
        power_budget={power_budget}
        duration={duration}
        voltage={voltage}
        current={current}
        power={power}
        fault={fault}
        show={show}
        handleClose={handleClose}
      />
    </>
  );
};

export default EVChargerCard;
