import Modal from "react-bootstrap/Modal";
import { Button, Container } from "react-bootstrap";

const WIPModal = ({ show, handleClose }) => {
  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Body>
          <Container>
            <p className="m-0">
              Please note: This is an early build. Development is ongoing.
            </p>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button className="mx-auto" variant="warning" onClick={handleClose}>
            Continue
          </Button>
          {/* <Button variant="primary" onClick={handleClose}>
                Save Changes
            </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default WIPModal;
