/* eslint-disable react-hooks/exhaustive-deps */
import { Routes, Route, HashRouter } from "react-router-dom";
import { Container } from "react-bootstrap";
import TopNav from "./Components/Misc/TopNav.jsx";
import Settings from "./Components/Pages/Admin/Settings.jsx";
import { useEffect } from "react";
import NotFound from "./Components/Misc/NotFound.jsx";
import ProtectedRoute from "./Components/Misc/ProtectedRoute.jsx";
import AdminPage from "./Components/Pages/Admin/AdminPage.jsx";
import AdminRoute from "./Components/Misc/AdminRoute.jsx";
import NewStatusPage from "./Components/Pages/Site View/NewStatusPage.jsx";
import axios from "./api/axios.js";
import FirstLogin from "./Components/Pages/Admin/FirstLogin.jsx";
import { ToastContainer } from "react-toastify";
import NewLandingPage from "./Components/Pages/Landing Page/NewLandingPage.jsx";
import ForgotPassword from "./Components/Pages/Login Page/ForgotPassword.jsx";
import ResetPassword from "./Components/Pages/Login Page/ResetPassword.jsx";
import PasswordChangeSuccess from "./Components/Pages/Login Page/PasswordChangeSuccess.jsx";
import EditAccount from "./Components/Pages/Admin/EditAccount.jsx";
import NewLogin from "./Components/Pages/Login Page/NewLogin.jsx";
import { useContext } from "react";
import UserContext from "./Context/UserProvider.js";
import HelpPage from "./Components/Misc/HelpPage.jsx";
import FaultPage from "./Components/Misc/FaultPage.jsx";
import ErrorPage from "./Components/Misc/ErrorPage.jsx";
import FaultNotification from "./Components/Misc/FaultNotification.jsx";
import TestReportPage from "./Components/Pages/Site View/Reports/TestReportPage.jsx";
import NotificationPage from "./Components/Pages/Notifications/NotificationPage.jsx";
import FaultSettings from "./Components/Pages/Faults/Settings.jsx";

function App() {
  const { theme, setTheme } = useContext(UserContext);
  const globalTheme = theme;

  useEffect(() => {
    document.body.className = theme;
    setTheme(theme);
  }, [theme]);

  // removes refreshToken cookie from browser if the window/tab is closed

  useEffect(() => {
    return () => {
      window.addEventListener("beforeunload", () => {
        return () => {
          axios.post(
            "/users/logout",
            {},
            {
              withCredentials: true,
            }
          );
        };
      });
    };
  }, []);

  return (
    <>
      {/* hashrouter replaces normal router component as normal routing is not supported on github pages for single page applications */}
      <HashRouter basename="/">
        <Container
          fluid
          // dynamic theme for whole application based on state
          className={`${theme} m-0 p-0`}
          style={{ height: "100vh" }}
        >
          {/* top navigation bar kept separate from all other pages as it is displayed on every page */}
          <TopNav theme={theme} themeSelect={setTheme} />
          <ToastContainer
            position="bottom-left"
            theme={globalTheme === "dark" ? "dark" : "light"}
          />
          <Container
            fluid
            className="p-0"
            style={{ height: "calc(100% - 3rem)", display: "flex" }}
          >
            <Routes>
              {/* <Route exact path="/" element={<LoginPage />} /> */}

              {/* login page */}
              <Route exact path="/" element={<NewLogin />} />

              <Route path="/test/faults" element={<NotificationPage />} />

              <Route path="/error" element={<ErrorPage />} />

              <Route path="/help" element={<HelpPage />} />

              {/* forgot password page */}
              <Route path="/login/identify" element={<ForgotPassword />} />

              {/* password change success page */}
              <Route
                path="/login/identify/success"
                element={<PasswordChangeSuccess />}
              />

              {/* reset password page */}
              <Route path="/login/reset" element={<ResetPassword />} />

              {/* protected route - dashboard landing page - only accessible when user is logged in */}
              <Route
                path="/dashboard"
                element={
                  <ProtectedRoute redirectTo={"/"}>
                    {/* <LandingPage /> */}
                    <NewLandingPage />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/dashboard/notifications/settings"
                element={
                  <ProtectedRoute redirectTo={"/"}>
                    <FaultSettings />
                  </ProtectedRoute>
                }
              />

              {/* primary site view page */}
              <Route
                path="/dashboard/sites/:siteCxp/:serialNumber"
                element={
                  <ProtectedRoute redirectTo={"/"}>
                    <NewStatusPage />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/dashboard/settings"
                element={
                  <ProtectedRoute redirectTo={"/"}>
                    <Settings />
                  </ProtectedRoute>
                }
              />

              {/* admin routes only allow those with an admin account to proceed */}
              <Route
                path="/dashboard/add-user"
                element={
                  <AdminRoute redirectTo="/dashboard">
                    <AdminPage />
                  </AdminRoute>
                }
              />

              <Route
                path="/dashboard/edit-user"
                element={
                  <AdminRoute redirectTo={"/dashboard"}>
                    <EditAccount />
                  </AdminRoute>
                }
              />

              <Route
                path="/dashboard/first-login"
                element={
                  <ProtectedRoute redirectTo={"/"}>
                    <FirstLogin />
                  </ProtectedRoute>
                }
              />

              {/* catches all undeclared paths and redirects to a 'not found' page */}
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Container>
        </Container>
      </HashRouter>
    </>
  );
}

export default App;
