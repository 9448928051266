/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-target-blank */
import { Container, Row, Form, Col, Button, Alert } from "react-bootstrap";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { useEffect, useState, useReducer, useContext } from "react";
import Logo from "../../../images/MSP_LOGO.png";
import { Link, useNavigate } from "react-router-dom";
import axios from "../../../api/axios.js";
import useAuth from "../../../Hooks/useAuth.js";
import "../../../CSS/animated.css";
import { userReducer } from "../../../Context/Reducers.js";
import { useIsDesktop } from "../../../Hooks/useIsDesktop";
import StatusContext from "../../../Context/StatusProvider";
import UserContext from "../../../Context/UserProvider";

const NewLogin = () => {
  const isDesktop = useIsDesktop();

  const [userState, dispatch] = useReducer(userReducer, {
    email: "",
    password: "",
  });

  // const reduxDispatch = useDispatch();
  const { setAuth } = useAuth();

  // const errorState = useSelector((state) => state?.general?.isError);
  const { isError, setIsError, errorMessage, setErrorMessage } =
    useContext(StatusContext);
  const { setLoggedIn } = useContext(UserContext);
  const errorState = isError;
  // const errorMessage = useSelector((state) => state?.general?.errorMessage);

  const navigate = useNavigate();

  const logInForm = {
    email: userState.email,
    password: userState.password,
  };
  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      setIsError(false);
      const response = await axios.post("/users/login", logInForm, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      });

      const accessToken = response?.data?.accessToken;
      const lastLoggedIn = response?.data?.lastLoggedIn;
      setAuth({ accessToken });
      setLoggedIn(true);
      dispatch({
        type: "setUserEmail",
        payload: "",
      });
      dispatch({
        type: "setUserPassword",
        payload: "",
      });

      if (lastLoggedIn === null) {
        navigate("/dashboard/first-login");
      } else {
        navigate("/dashboard");
      }
    } catch (err) {
      setIsError(true);
      if (err.response?.status === 429) {
        setErrorMessage("Too many requests made. Please try again later.");
      } else if (!err.response) {
        setErrorMessage("No response from the server");
      } else if (err.response?.status === 400) {
        setErrorMessage("Check your credentials and try again.");
      } else if (err.response?.status === 401) {
        setErrorMessage(
          "Your email or password is incorrect. Please check and try again."
        );
      } else {
        setErrorMessage("We were unable to log you in.");
      }
    }
  };

  useEffect(() => {
    document.title = "MSP - Login";
  }, []);

  useEffect(() => {
    setErrorMessage("");
    setIsError(false);
  }, []);

  return (
    <>
      <Container
        fluid
        className="new-login-container p-0"
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        {isDesktop && (
          <>
            <Container
              fluid
              style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                position: "relative",
              }}
            >
              <Row className="my-auto">
                <Col
                  xs={12}
                  md={5}
                  style={{
                    display: "flex",
                    borderRight: isDesktop
                      ? "1px solid rgba(0, 0, 230, 0.2)"
                      : "",
                  }}
                >
                  <Container
                    fluid
                    className=" mx-auto"
                    style={{ width: "fit-content" }}
                  >
                    <a
                      href="https://multisourcepower.com"
                      title="Multi Source Power"
                      target="_blank"
                    >
                      <img
                        style={{}}
                        className="img-fluid msp-logo animated-logo"
                        src={Logo}
                        alt="MSP Logo"
                      />
                    </a>
                  </Container>
                </Col>
                <Col xs={12} md={7} style={{}}>
                  <Container
                    fluid
                    className="text-center"
                    style={{ width: "100%", height: "100%" }}
                  >
                    <Container className="new-login-form">
                      <Form
                        style={{ width: "95%", height: "65%" }}
                        noValidate
                        className="text-center my-auto mx-auto"
                        onSubmit={(e) => onSubmit(e)}
                        onClick={() => setIsError(false)}
                      >
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Email Address"
                          className="mb-3"
                        >
                          <Form.Control
                            type="email"
                            placeholder="name@example.com"
                            value={userState.email}
                            onChange={(e) =>
                              dispatch({
                                type: "setUserEmail",
                                payload: e.target.value,
                              })
                            }
                          />
                        </FloatingLabel>
                        <FloatingLabel
                          controlId="floatingPassword"
                          label="Password"
                        >
                          <Form.Control
                            type="password"
                            placeholder="Password"
                            value={userState.password}
                            onChange={(e) =>
                              dispatch({
                                type: "setUserPassword",
                                payload: e.target.value,
                              })
                            }
                          />
                        </FloatingLabel>

                        <Row
                          style={{
                            // border: "1px solid red",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          className="mt-3"
                        >
                          <Col
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            className="text-center"
                          >
                            <p style={{ width: "fit-content" }}>
                              <Link className="text-white" to="/help">
                                Trouble Logging In?
                              </Link>
                            </p>
                          </Col>
                        </Row>
                        <Button
                          className="login-btn"
                          type="submit"
                          disabled={
                            userState.email && userState.password ? false : true
                          }
                        >
                          Sign In
                        </Button>
                      </Form>
                    </Container>
                  </Container>
                </Col>
              </Row>
              {errorState && (
                <Alert
                  style={{
                    width: "fit-content",
                    position: "absolute",
                    top: "20%",
                  }}
                  className="mt-3 mx-auto alert-animated text-center"
                  variant="danger"
                >
                  <p className="m-0">{errorMessage}</p>
                </Alert>
              )}
            </Container>
            <footer
              style={{
                position: "absolute",
                height: "5rem",
                width: "100%",
                top: "80%",
                left: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Alert variant="info" dismissible title="Cookie Disclaimer">
                <p className="m-0">
                  This website uses cookies to improve the user experience. By
                  proceeding, you are agreeing to the use of cookies.
                </p>
              </Alert>
            </footer>
          </>
        )}
        {!isDesktop && (
          <>
            <Row className="m-0 p-0" style={{ position: "relative" }}>
              <Container fluid style={{ display: "flex" }}>
                <img
                  id="mobile-logo"
                  src={Logo}
                  alt="MSP Logo"
                  className="img-fluid msp-logo mx-auto"
                />
              </Container>
              <Col>
                <Row
                  style={{ fontWeight: "bolder" }}
                  className="text-center text-white"
                ></Row>

                <Form
                  style={{ width: "95%" }}
                  noValidate
                  className="text-center my-auto mx-auto new-login-form-mobile"
                  onSubmit={(e) => onSubmit(e)}
                  onClick={() => setIsError(false)}
                >
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Email Address"
                    className="mb-3"
                  >
                    <Form.Control
                      type="email"
                      placeholder="name@example.com"
                      value={userState.email}
                      onChange={(e) =>
                        dispatch({
                          type: "setUserEmail",
                          payload: e.target.value,
                        })
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter a valid email address.
                    </Form.Control.Feedback>
                  </FloatingLabel>
                  <FloatingLabel controlId="floatingPassword" label="Password">
                    <Form.Control
                      type="password"
                      placeholder="Password"
                      value={userState.password}
                      onChange={(e) =>
                        dispatch({
                          type: "setUserPassword",
                          payload: e.target.value,
                        })
                      }
                    />
                  </FloatingLabel>
                  {errorState && (
                    <Alert
                      className="my-2 alert-animated text-center"
                      variant="danger"
                    >
                      <p className="m-0">{errorMessage}</p>
                    </Alert>
                  )}
                  <Row
                    className="mt-3"
                    style={{ width: "fit-content", margin: "0 auto" }}
                  ></Row>
                  <Row>
                    <p>
                      <Link style={{ color: "white" }} to="/help">
                        Trouble logging in?
                      </Link>
                    </p>
                  </Row>
                  <Button
                    className="login-btn mobile"
                    type="submit"
                    disabled={
                      userState.email && userState.password ? false : true
                    }
                  >
                    Sign In
                  </Button>
                </Form>
              </Col>
            </Row>
            <footer
              style={{
                position: "absolute",
                height: "5rem",
                width: "100%",
                top: "75%",
                left: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Alert
                style={{ width: "90%" }}
                variant="info"
                dismissible
                title="Cookie Disclaimer"
              >
                <p className="m-0 text-center">
                  This website uses cookies to improve the user experience. By
                  proceeding, you are agreeing to the use of cookies.
                </p>
              </Alert>
            </footer>
          </>
        )}
      </Container>
    </>
  );
};

export default NewLogin;
