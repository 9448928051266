import { createContext, useState } from "react";

const GlobalDataContext = createContext({});

export const GlobalDataProvider = ({ children }) => {
  const [latestData, setLatestData] = useState({});
  const [siteInfo, setSiteInfo] = useState([]);
  const [projectNames, setProjectNames] = useState([]);
  const [currentSiteSerials, setCurrentSiteSerials] = useState([]);
  const [batteryQty, setBatteryQty] = useState(0);
  const [inverterQty, setInverterQty] = useState(0);
  const [evChargeQty, setEvChargeQty] = useState(0);
  const [evChargeOnline, setEvChargeOnline] = useState(0);
  const [evChargeSessions, setEvChargeSessions] = useState(0);
  const [acMeterQty, setAcMeterQty] = useState(0);

  const addToSiteInfo = (arr) => {
    const siteInfoLength = siteInfo.length;
    const arrLength = arr.length;

    //cannot remember what this does for the life of me
    //not being used?
    for (let i = 0; i < siteInfoLength; i++) {
      for (let j = 0; j < arrLength; j++) {
        if (siteInfo[i].contract_ref === arr[j].contract_ref) {
          return null;
        } else {
          setSiteInfo([...siteInfo, arr[j]]);
        }
      }
    }
  };

  return (
    <GlobalDataContext.Provider
      value={{
        latestData,
        setLatestData,
        siteInfo,
        setSiteInfo,
        currentSiteSerials,
        setCurrentSiteSerials,
        projectNames,
        setProjectNames,
        batteryQty,
        setBatteryQty,
        inverterQty,
        setInverterQty,
        evChargeQty,
        setEvChargeQty,
        evChargeOnline,
        setEvChargeOnline,
        evChargeSessions,
        setEvChargeSessions,
        acMeterQty,
        setAcMeterQty,
        addToSiteInfo,
      }}
    >
      {children}
    </GlobalDataContext.Provider>
  );
};

export default GlobalDataContext;
