"use client";

// MSP Technologies Ltd. - All Rights Reserved

// Copyright © 2023/2024 - MSP Technologies Ltd.

// This source code is proprietary and confidential.
// Unauthorised copying, distribution, or disclosure of this code, in whole or in part, is strictly prohibited,
// except as permitted by the terms of the MSP Technologies License Agreement.

import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import "./CSS/globalStyles.css";
import "./CSS/mobileStyles.css";
import { AuthProvider } from "./Context/AuthProvider";
import "react-toastify/dist/ReactToastify.css";
import { GlobalDataProvider } from "./Context/DataProvider";
import { UserDataProvider } from "./Context/UserProvider";
import { StatusProvider } from "./Context/StatusProvider";
import { ErrorBoundary } from "react-error-boundary";
import ErrorPage from "./Components/Misc/ErrorPage";

//silence console dialog when in production
if (process.env.NODE_ENV === "production") {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

const root = ReactDOM.createRoot(document.getElementById("root"));


root.render(
  <StatusProvider>
    <AuthProvider>
      <UserDataProvider>
        <GlobalDataProvider>
          <ErrorBoundary fallback={<ErrorPage />}>
            <App />
          </ErrorBoundary>
        </GlobalDataProvider>
      </UserDataProvider>
    </AuthProvider>
  </StatusProvider>
);
