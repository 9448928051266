import { Container, Row, Col } from "react-bootstrap";
import Arrow from "../../../../images/new/arrow.svg";
import Solar from "../../../../images/new/solar.png";
import { useContext } from "react";
import { useIsDesktop } from "../../../../Hooks/useIsDesktop";
import UserContext from "../../../../Context/UserProvider";
import GlobalDataContext from "../../../../Context/DataProvider";

const PV = () => {
  const isDesktop = useIsDesktop();
  const { theme } = useContext(UserContext);
  const globalTheme = theme;

  const { latestData } = useContext(GlobalDataContext);

  let solarPower;

  const solarData = () => {
    if (latestData?.dc_solar_quantity !== undefined) {
      return (solarPower = Number(latestData?.dc_solar_1_dc_bus_power)?.toFixed(
        2
      ));
    }
    return (solarPower =
      Number(latestData?.ac_solar_1_ac_power)?.toFixed(2) || 0);
  };

  solarData();

  return (
    <>
      {isDesktop && (
        <Container
          className="p-0"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "fit-content",
          }}
        >
          <Row className="my-2 " style={{ height: "fit-content" }}>
            {" "}
            <div
              style={{
                width: "fit-content",
              }}
            >
              <img
                src={Arrow}
                alt="Import Energy"
                className={
                  solarPower !== 0
                    ? solarPower < 0
                      ? "img-fluid overview-arrow-active"
                      : "img-fluid overview-arrow"
                    : "img-fluid overview-arrow"
                }
                style={{
                  transform: "scaleY(-1)",
                }}
              />
            </div>
            <div
              style={{
                width: "fit-content",
              }}
            >
              <img
                src={Arrow}
                alt="Export Energy"
                className={
                  solarPower !== 0
                    ? solarPower > 0
                      ? "img-fluid overview-arrow-active"
                      : "img-fluid overview-arrow"
                    : "img-fluid overview-arrow"
                }
              />
            </div>
          </Row>
          <Row className="mt-0 mb-1" style={{ height: "1.7rem" }}>
            {/* {isNegative && <p className="m-0">Power In: {power()}kW</p>}
              {!isNegative && <p className="m-0">Power Out: {power()}kW</p>} */}
          </Row>
          <Container className="m-0 p-2 overview-top-box">
            <Row className="m-0" style={{ height: "100%" }}>
              <Col
                style={{
                  display: "flex",
                  flexDirection: "column",

                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    height: "fit-content",
                    aspectRatio: 1 / 1,
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                    overflow: "hidden",
                  }}
                >
                  <img
                    src={Solar}
                    alt="Grid Connection"
                    className="img-fluid"
                    style={{
                      objectFit: "fit",
                      height: "70%",
                    }}
                  />{" "}
                  <h3
                    className="mt-2"
                    style={{
                      width: "fit-content",
                      color: "var(--alt-white))",
                    }}
                  >
                    {solarPower ? solarPower : 0} kW
                  </h3>
                </div>
              </Col>
            </Row>
          </Container>
        </Container>
      )}
      {!isDesktop && (
        <>
          <Container
            className="my-2 text-center p-1"
            style={{
              display: "flex",
              flexDirection: "column",
              // aspectRatio: "1/1",
              backgroundColor: "rgba(255,255,255,0.3)",
              borderRadius: "8px",
              width: "100%",
              height: "10rem",
              position: "relative",
            }}
          >
            <Container
              fluid
              style={{
                position: "absolute",
                left: 0,
                top: 0,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                width: "100%",
              }}
            >
              <h1
                style={{
                  fontSize: "2.75rem",
                  fontWeight: "bolder",
                  color: "white",
                  filter: "drop-shadow(0px 2px 3px rgba(0,0,0,0.6))",
                }}
              >
                {solarPower}
              </h1>
              <h1
                style={{
                  fontSize: "2rem",
                  color: "white",
                  filter: "drop-shadow(0px 2px 3px rgba(0,0,0,0.6))",
                }}
              >
                kW
              </h1>
            </Container>
            <Container
              className="my-auto p-0"
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                overflow: "hidden",
              }}
            >
              <img
                src={Solar}
                alt="Solar Panel"
                // className="img-fluid"
                style={{
                  minWidth: "95%",
                  maxWidth: "100%",
                  opacity: 0.2,
                  zIndex: -1,
                }}
              />
            </Container>
          </Container>
        </>
      )}
    </>
  );
};

export default PV;
