import { Col, Container, Row } from "react-bootstrap";
import { useIsDesktop } from "../../../../Hooks/useIsDesktop";
import ACMeter from "../../../../images/new/ac_meter.png";
import GaugeChart from "react-gauge-chart";
import MobileACMeterComp from "../Battery/Mobile Views/ACMeter";

const ACMeterComp = ({
  avgPhaseVoltage,
  avgLineVoltage,
  avgCurrent,
  frequency,
  totalPowerFactor,
  totalActivePower,
  importPower,
  exportPower,
}) => {
  const isDesktop = useIsDesktop();

  const renderActivePowerGauge = () => {
    const powerPercentage = () => {
      if (totalActivePower < 0) {
        if (totalActivePower <= -100) {
          return (totalActivePower / 250) * -1;
        } else {
          return (totalActivePower / 100) * -1;
        }
      }
      if (totalActivePower >= 0) {
        if (totalActivePower >= 100) {
          return totalActivePower / 250;
        } else {
          return totalActivePower / 100;
        }
      }
    };

    return (
      <GaugeChart
        id="acmeter-power-gauge"
        hideText={true}
        arcWidth={0.2}
        nrOfLevels={1}
        colors={["#5e97bf", "#3a5c9b"]}
        percent={powerPercentage()}
        cornerRadius={0}
        arcsLength={[powerPercentage(), 1 - powerPercentage()]}
      />
    );
  };

  return (
    <>
      {!isDesktop && (
        <>
          <MobileACMeterComp
            avgCurrent={avgCurrent}
            avgLineVoltage={avgLineVoltage}
            avgPhaseVoltage={avgPhaseVoltage}
            importPower={importPower}
            exportPower={exportPower}
            totalActivePower={totalActivePower}
            totalPowerFactor={totalPowerFactor}
            frequency={frequency}
          />
        </>
      )}
      {isDesktop && (
        <>
          <Container
            className="mb-3 p-2"
            fluid
            style={{
              height: "100%",
              backgroundColor: "rgba(255,255,255,0.4)",
              borderRadius: "5px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Row
              className="text-center"
              style={{
                width: "100%",
                // height: "20rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Col md={3}>
                <Container>
                  <img
                    src={ACMeter}
                    alt="AC Meter Icon"
                    className="img-fluid"
                    style={{ maxHeight: "12rem" }}
                  />
                  <h5>Power Factor: {Number(totalPowerFactor).toFixed(2)}</h5>
                </Container>
              </Col>
              <Col md={{ span: 7 }}>
                <Row className="m-0 text-center">
                  <Col
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Container
                      style={{
                        height: "10rem",
                        backgroundColor: "rgba(255,255,255,0.4)",
                        borderRadius: "8px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <h1>{importPower}</h1>
                      <h4>Total Active Import Energy</h4>
                    </Container>
                  </Col>
                  <Col
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Container
                      style={{
                        height: "10rem",
                        backgroundColor: "rgba(255,255,255,0.4)",
                        borderRadius: "8px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <h1>{exportPower}</h1>
                      <h4>Total Active Export Energy</h4>
                    </Container>
                  </Col>
                </Row>
              </Col>
              <Row className="mt-3 text-center m-0" style={{ height: "15rem" }}>
                <Col style={{ height: "100%" }} className="m-0">
                  <Container
                    className="m-0 p-0"
                    style={{
                      backgroundColor: "rgba(255,255,255,0.4)",
                      height: "100%",
                      borderRadius: "8px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {/* <div style={{ width: "fit-content" }}>
                    <GaugeChart
                      id="acmeter-phase-voltage-gauge"
                      hideText={true}
                      arcWidth={0.2}
                      nrOfLevels={1}
                      // colors={["#3a5c9b"]}
                      percent={avgPhaseVoltage / 1000}
                      cornerRadius={0}
                      colors={["#7dbae5", "#3a5c9b"]}
                      arcsLength={[
                        avgPhaseVoltage / 1000,
                        1 - avgPhaseVoltage / 1000,
                      ]}
                    />
                  </div> */}
                    <Row
                      style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      className="text-center m-0 p-0"
                    >
                      <Col
                        className="m-0"
                        style={{
                          height: "100%",
                          borderRight: "1px solid grey",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <h4>Average Phase Voltage</h4>
                        <h1>{Number(avgPhaseVoltage).toFixed(2)}V</h1>
                      </Col>
                      <Col
                        style={{
                          height: "100%",
                          // borderLeft: "1px solid grey",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <h4>Average Line Voltage</h4>
                        <h1>{Number(avgLineVoltage).toFixed(2)}V</h1>
                      </Col>
                    </Row>
                  </Container>
                </Col>
                <Col style={{ height: "100%" }}>
                  <Container
                    style={{
                      backgroundColor: "rgba(255,255,255,0.4)",
                      height: "100%",
                      borderRadius: "8px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div style={{ width: "fit-content" }}>
                      {renderActivePowerGauge()}
                    </div>
                    <h1 className="lcd m-0" style={{ fontSize: "3.5rem" }}>
                      {Number(totalActivePower).toFixed(2)}kW
                    </h1>
                    <h3>Total Active Power</h3>
                  </Container>
                </Col>
                <Col style={{ height: "100%" }}>
                  <Container
                    style={{
                      backgroundColor: "rgba(255,255,255,0.4)",
                      height: "100%",
                      borderRadius: "8px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div style={{ width: "fit-content" }}>
                      <GaugeChart
                        id="acmeter-current-gauge"
                        hideText={true}
                        arcWidth={0.2}
                        nrOfLevels={1}
                        // colors={["#3a5c9b"]}
                        percent={avgCurrent / 100}
                        cornerRadius={0}
                        colors={["#7dbae5", "#3a5c9b"]}
                        arcsLength={[avgCurrent / 100, 1 - avgCurrent / 100]}
                      />
                    </div>
                    <h1 className="lcd m-0" style={{ fontSize: "3.5rem" }}>
                      {Number(avgCurrent).toFixed(2)}A
                    </h1>
                    <h3>Average Current</h3>
                  </Container>
                </Col>
              </Row>
            </Row>
          </Container>
        </>
      )}
    </>
  );
};

export default ACMeterComp;
