/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Container, Row } from "react-bootstrap";
import SiteCards from "./SiteCards.jsx";
import { useEffect, useState, useContext } from "react";
import LoadingScreen from "../../Misc/LoadingScreen.jsx";
import MainView from "./MainView.jsx";
import MobileLandingPage from "./MobileLandingPage.jsx";
import { useAxiosPrivate } from "../../../Hooks/useAxiosPrivate.js";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useAuth from "../../../Hooks/useAuth.js";
import logo from "../../../images/transparent_logo.png";
import UserContext from "../../../Context/UserProvider.js";
import StatusContext from "../../../Context/StatusProvider.js";
import GlobalDataContext from "../../../Context/DataProvider.js";
import { useIsDesktop } from "../../../Hooks/useIsDesktop.js";
import useForwardButton from "../../../Hooks/useForwardButton.js";
import useBackButton from "../../../Hooks/useBackButton.js";
import WIPModal from "../../Misc/WIPModal.jsx";

const NewLandingPage = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };
  const goForward = () => {
    navigate(1);
  };

  //allows user to use browser back and forward buttons
  useBackButton(goBack);
  useForwardButton(goForward);

  const axiosPrivate = useAxiosPrivate();

  const { logInNotify, setLogInNotify } = useAuth();
  const logInNotificationStatus = logInNotify;

  // use hooks for fetching and storing site data, user data, loading and error status', and whether the viewport is desktop or mobile
  const { setSiteInfo } = useContext(GlobalDataContext);
  const isDesktop = useIsDesktop();
  const { userData, setUserData, setTheme, setUserFaultProfile } =
    useContext(UserContext);
  let {
    isLoading,
    setIsLoading,
    setIsError,
    setErrorMessage,
    wipShown,
    setWipShown,
  } = useContext(StatusContext);

  const currentUserRole = userData.role;
  const [userSites, setUserSites] = useState([]);

  // set to true when site data has been loaded into state
  const [siteDataLoaded, setSiteDataLoaded] = useState(false);

  //toast notification for successful login
  const notify = () => {
    return toast.success("Logged in.", {
      toastId: "login",
    });
  };

  //set page title on load
  useEffect(() => {
    document.title = "MSP - Dashboard";
  }, []);

  //get user data on load
  // added: also fetch and set user fault profile - global state.
  useEffect(() => {
    if (!wipShown) {
      setWipShown(true);
      setShowModal(true);
    }
    let isMounted = true;
    const controller = new AbortController();

    async function getUser() {
      try {
        const response = await axiosPrivate.get("/users/me", {
          signal: controller.signal,
        });
        // const userFaultProfile = await axiosPrivate.get("/faults/profile", {
        //   signal: controller.signal,
        // });
        // isMounted && setUserFaultProfile(userFaultProfile?.data);
        isMounted && setUserData(response?.data);
        let arr = [];
        response?.data?.projects?.map((site) => arr.push(site));
        setUserSites([]);
        setUserSites(arr);
        if (response?.data?.theme) setTheme(response?.data?.theme);
      } catch (err) {
        console.error(err);
        navigate("/");
      }
    }

    getUser();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  useEffect(() => {
    const siteArr = [];
    async function getSiteInfo(site) {
      try {
        //fingers crossed this does not add duplicates anymore to siteInfo
        //fetches site info for each site in user's project list and adds it to siteInfo state - primarily location/name/serial etc
        const response = await axiosPrivate.get(`/data/${site}/site-info`);
        if (response.data) {
          siteArr.push(response.data);
        }
        setSiteDataLoaded(true);
      } catch (err) {
        console.error(err);
        setIsError(true);
        setErrorMessage("Failed to load site information.");
      }
    }
    async function awaitSiteInfo() {
      //await multiple calls to getSiteInfo
      await Promise.all(userSites.map((site) => getSiteInfo(site)));

      setSiteInfo(siteArr);
    }
    setTimeout(() => {
      awaitSiteInfo();
    }, 400);

    setSiteDataLoaded(true);
  }, [userSites]);

  useEffect(() => {
    if (!logInNotificationStatus) {
      notify();
    }
    setLogInNotify(true);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => setIsLoading(true);
  }, []);

  const [showModal, setShowModal] = useState(false);

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <>
      {isLoading === true && <LoadingScreen />}
      {!isLoading && isDesktop && (
        <Container
          className="dashboard-main-con"
          fluid
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            overflowY: "hidden",
            width: "100%",
          }}
        >
          {/* <AlertComp /> */}
          <Row className="" style={{ height: "100%" }}>
            <Col
              md={2}
              sm={4}
              xs={4}
              className="sidebar p-0"
              style={{ height: "100%" }}
            >
              {/* <Container
                fluid
                onClick={() => navigate("/dashboard/notifications/settings")}
                style={{ backgroundColor: "red", height: "5rem" }}
              >
                <h4>Fault Notifications</h4>
              </Container> */}
              {userSites?.map((site, index) => (
                <SiteCards key={index} name={site} />
              ))}
              {/* show only if user is an admin */}
              {currentUserRole === "admin" && (
                <>
                  <Row
                    className="text-center my-2 p-0 mx-0"
                    style={{ width: "100%" }}
                  >
                    <Link
                      className="mx-auto"
                      style={{ width: "fit-content" }}
                      id="add-account-link"
                      to="/dashboard/add-user"
                    >
                      <h5 className="mx-auto">Add An Account</h5>
                    </Link>
                  </Row>
                  <Row
                    className="text-center my-2 p-0 mx-0"
                    style={{ width: "100%" }}
                  >
                    <Link
                      className="mx-auto"
                      style={{ width: "fit-content" }}
                      id="add-account-link"
                      to="/dashboard/edit-user"
                    >
                      <h5>Edit An Account</h5>
                    </Link>
                  </Row>
                </>
              )}

              <Row className="mt-2" style={{ width: "100%" }}>
                <a href="https://www.multisourcepower.com" target="_blank">
                  <div
                    className="mx-auto"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <img
                      className="img-fluid"
                      src={logo}
                      alt="MSP"
                      style={{
                        height: "5rem",
                        objectFit: "contain",
                        filter:
                          "drop-shadow(2px 2px 1px rgba(255,255,255,0.3))",
                      }}
                    />
                  </div>
                </a>
              </Row>
              <Row id="sidebar-small-text" className="text-center mt-1 p-0">
                <a
                  href="https://www.multisourcepower.com"
                  target="_blank"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <p>© MSP Technologies Ltd {new Date().getFullYear()}</p>
                </a>
              </Row>
            </Col>
            <Col className="p-0 m-0" style={{ overflowY: "scroll" }}>
              <MainView siteDataLoaded={siteDataLoaded} />
            </Col>
          </Row>
        </Container>
      )}
      {!isLoading && !isDesktop && (
        <MobileLandingPage
          siteNames={userSites}
          siteDataLoaded={siteDataLoaded}
        />
      )}

      <WIPModal show={showModal} handleClose={handleClose} />
    </>
  );
};

export default NewLandingPage;
