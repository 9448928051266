import * as pdfMake from "pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

// pdf tools for exporting report page as a pdf
const dd = (img, desktop) => {
  return {
    pageSize: "A4",
    pageOrientation: desktop !== true ? "portrait" : "landscape",
    content: [
      {
        image: "reportImg",
        width: desktop ? 750 : 500,
        // width: "100%",
      },
    ],
    images: {
      reportImg: img,
    },
  };
};

export const exportPdf = (img, siteName, desktop) =>
  pdfMake.createPdf(dd(img, desktop)).download(`${siteName}-report.pdf`);

pdfMake.pageLayout = {
  height: 595.28,
  width: 841.89,
  margins: Array(4).fill(72),
};

const reportPageLayout = (report) => {
  return {
    pageSize: "A4",
    pageOrientation: "portrait",
    pageMargins: pdfMake.pageLayout.margins,
    content: [
      {
        image: "reportPage",
        width:
          (pdfMake.pageLayout.width -
            pdfMake.pageLayout.margin[0] -
            pdfMake.pageLayout.margin[2]) *
          100,
      },
    ],
    images: {
      reportPage: report,
    },
    styles: {
      header: {
        fontSize: 22,
        alignment: "center",
      },
    },
  };
};

export const exportReportPdf = (site, report) => {
  pdfMake
    .createPdf(reportPageLayout(site, report))
    .download(`${site}-report.pdf`);
};
