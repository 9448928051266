/* eslint-disable eqeqeq */
import { Col, Container, Row } from "react-bootstrap";
import { useContext, useEffect, useState } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
// import Thermometer from "react-thermometer-component";
import UserContext from "../../../../Context/UserProvider";
// import { BsLightningChargeFill, BsPower, BsPlugFill } from "react-icons/bs";
import GaugeChart from "react-gauge-chart";

const BatteryComp = ({
  soc,
  // num,
  current,
  voltage,
  power,
  // minCellTemp,
  // maxCellTemp,
  avgCellTemp,
  // maxChargePower,
  // maxDischargePower,
  // cellVoltageMin,
  // cellVoltageAvg,
  // cellVoltageMax,
  // maxCapacity,
  // onlineCapacity,
  // cycles,
}) => {
  const [battSoc, setSoc] = useState(0);
  const [bgColour, setBgColour] = useState("lightgreen");

  const { theme } = useContext(UserContext);
  const globalTheme = theme;
  // const globalTheme = useSelector((state) => state?.user?.theme);

  useEffect(() => {
    setSoc(soc);

    if (soc <= 25) {
      setBgColour("#d11313");
    }
    if (soc >= 26 && soc <= 50) {
      setBgColour("#e66a0b");
    }
    if (soc >= 51 && soc <= 75) {
      setBgColour("#f2df0f");
    }
    if (soc >= 76 && soc <= 100) {
      setBgColour("#64f538");
    }
  }, [soc]);

  return (
    <Container
      key="battery-comp"
      fluid
      className="p-0"
      style={{ display: "flex", height: "fit-content" }}
    >
      <Col
        className="mt-3 pb-4 battery-comp"
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
          // border: "1px solid red",
        }}
      >
        <Container
          fluid
          className="battery-comp-con p-0 my-2 mx-auto"
          style={{
            width: "100%",
            display: "flex",
            // border: "1px solid red",
            height: "30rem",
            justifyContent: "center",
          }}
        >
          <Row className="m-0 p-0" style={{ width: "100%", height: "100%" }}>
            {/* <h4 className="text-center my-2">Battery {num}</h4> */}
            <Col
              className="m-0 p-0"
              xs={12}
              md={3}
              style={{
                display: "flex",
                // border: "1px solid yellow",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  position: "relative",
                  height: "12rem",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div className="batteryBorderL mt-4">
                  <div className="batteryTopL"></div>

                  <div
                    className="chargeL"
                    style={{
                      height: battSoc <= 5 ? "5%" : `${battSoc}%`,
                      maxHeight: "100%",
                      backgroundColor: `${bgColour}`,
                    }}
                  ></div>
                  {/* <input
                      id="batt-num-id"
                      value={"Battery " + num}
                      readOnly
                      disabled
                    /> */}
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip>
                        Average State of Charge of Online Battery Cells
                      </Tooltip>
                    }
                  >
                    <input
                      className="lcd"
                      style={{
                        color:
                          globalTheme === "dark"
                            ? battSoc >= 55 && soc <= 100
                              ? "black"
                              : "white"
                            : "black",
                      }}
                      id="chargeDisplayL"
                      value={battSoc.toFixed(1) + "%"}
                      readOnly
                      disabled
                    />
                  </OverlayTrigger>
                  <input
                    // className="lcd"
                    readOnly
                    disabled
                    id="charging-indicator"
                    value={
                      power != 0 ? (power < 0 ? "Charging" : "Discharging") : ""
                    }
                  />
                </div>
              </div>
            </Col>
            <Col className="mt-2 p-0 my-auto">
              <Container
                fluid
                className="m-0 p-0"
                style={{
                  // border: "1px solid red",
                  height: "90%",
                  display: "grid",
                  gridTemplateColumns: "repeat(8, 1fr)",
                  gridTemplateRows: "repeat(6, 1fr)",
                  gridGap: "0.5rem",
                }}
              >
                <Container
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gridArea: "1/1/4/5",
                    backgroundColor: "rgba(255,255,255,0.4)",
                    borderRadius: "20px 8px 8px 8px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div style={{ height: "50%", width: "fit-content" }}>
                    <GaugeChart
                      // animate={false}
                      percent={power < 0 ? (power / 100) * -1 : power / 100}
                      id="dc-power-gauge"
                      hideText={true}
                      colors={["#7dbae5", "#3a5c9b"]}
                      arcWidth={0.2}
                      nrOfLevels={1}
                      cornerRadius={0}
                      arcsLength={[power / 100, 1 - power / 100]}
                    />
                  </div>
                  <h1 style={{ fontSize: "3.5rem" }} className="lcd">
                    {Number(power).toFixed(2) == 0 ? "0" : power.toFixed(2)} kW
                  </h1>
                  <h3 className="">DC Bus Power</h3>
                </Container>
                <Container
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gridArea: "1/5/4/9",
                    backgroundColor: "rgba(255,255,255,0.4)",
                    borderRadius: "8px 20px 8px 8px",
                  }}
                >
                  <div style={{ height: "50%", width: "fit-content" }}>
                    <GaugeChart
                      // animate={false}
                      id="dc-voltage-gauge"
                      hideText={true}
                      arcWidth={0.2}
                      nrOfLevels={1}
                      // colors={["#3a5c9b"]}
                      percent={voltage / 1000}
                      cornerRadius={0}
                      colors={["#7dbae5", "#3a5c9b"]}
                      arcsLength={[voltage / 1000, 1 - voltage / 1000]}
                    />
                  </div>
                  <h1 style={{ fontSize: "3.5rem" }} className="lcd">
                    {voltage.toFixed(2) == 0 ? "0" : voltage.toFixed(2)} V
                  </h1>
                  <h3 className="">DC Bus Voltage</h3>
                </Container>
                <Container
                  style={{
                    gridArea: "4/1/7/5",
                    backgroundColor: "rgba(255,255,255,0.4)",
                    borderRadius: "8px 8px 8px 20px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div style={{ height: "50%", width: "fit-content" }}>
                    <GaugeChart
                      // animate={false}
                      id="dc-current-gauge"
                      hideText={true}
                      arcWidth={0.2}
                      nrOfLevels={1}
                      colors={["#7dbae5", "#3a5c9b"]}
                      percent={current < 0 ? (current / 50) * -1 : current / 50}
                      cornerRadius={0}
                      arcsLength={[current / 50, 1 - current / 50]}
                    />
                  </div>
                  <h1 style={{ fontSize: "3.5rem" }} className="lcd">
                    {current.toFixed(2) == 0 ? "0" : current.toFixed(2)} A
                  </h1>
                  <h3 className="">DC Bus Current</h3>
                </Container>
                <Container
                  style={{
                    gridArea: "4/5/7/9",
                    backgroundColor: "rgba(255,255,255,0.4)",
                    borderRadius: "8px 8px 20px 5px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      height: "50%",
                      width: "fit-content",
                    }}
                  >
                    <GaugeChart
                      hideText={true}
                      id="battery-temp-gauge"
                      arcWidth={0.2}
                      nrOfLevels={1}
                      colors={["yellow", "orange", "red"]}
                      percent={avgCellTemp / 100}
                      cornerRadius={0}
                      arcsLength={[0.4, 0.6, 1]}
                    />
                  </div>
                  <h1 style={{ fontSize: "3.5rem" }} className="lcd">
                    {avgCellTemp.toFixed(2)}°C
                  </h1>
                  <h4 className="">Average Cell Temperature</h4>
                </Container>
              </Container>
            </Col>
          </Row>
        </Container>
      </Col>
    </Container>
  );
};

export default BatteryComp;
