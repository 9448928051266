import { Container, Row, Col } from "react-bootstrap";
import Arrow from "../../../../images/new/arrow.svg";
import Battery20 from "../../../../images/new/battery_20.png";
import Battery40 from "../../../../images/new/battery_40.png";
import Battery60 from "../../../../images/new/battery_60.png";
import Battery80 from "../../../../images/new/battery_80.png";
import Battery100 from "../../../../images/new/battery_100.png";
// import Charging from "../../../../images/new/charging.png";
import { useState, useEffect } from "react";
import { useIsDesktop } from "../../../../Hooks/useIsDesktop";
import { useContext } from "react";
import GlobalDataContext from "../../../../Context/DataProvider";
import UserContext from "../../../../Context/UserProvider";

const Battery = () => {
  const { theme } = useContext(UserContext);
  const globalTheme = theme;
  const { latestData } = useContext(GlobalDataContext);

  const batteryDcBus = Number(latestData?.battery_1_dc_bus_power)?.toFixed(2);
  const batterySoc = Number(latestData?.battery_1_soc)?.toFixed(2);

  const isNegative = () => {
    return batteryDcBus < 0 ? true : false;
  };
  
  //returns the battery icon based on the soc
  const batteryIcon = () => {
    if (batterySoc <= 20) {
      return Battery20;
    } else if (batterySoc <= 40) {
      return Battery40;
    } else if (batterySoc <= 60) {
      return Battery60;
    } else if (batterySoc <= 80) {
      return Battery80;
    } else if (batterySoc <= 100) {
      return Battery100;
    } else {
      return Battery20;
    }
  };

  // const currentData = latestData;
  // const [isNegative, setIsNegative] = useState(dcBus < 0);

  // useEffect(() => {
  //   console.log("busPower", dcBus);
  //   setIsNegative(dcBus < 0);
  // }, [currentData, dcBus]);

  const isDesktop = useIsDesktop();

  return (
    <>
      {isDesktop && (
        <Container
          className="p-0"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "fit-content",
            height: "100%",
          }}
        >
          <Row className="my-2" style={{ height: "fit-content" }}>
            <div
              style={{
                width: "fit-content",
              }}
            >
              <img
                src={Arrow}
                alt="Import Energy"
                className={
                  batteryDcBus !== 0
                    ? isNegative()
                      ? "img-fluid overview-arrow-active"
                      : "img-fluid overview-arrow"
                    : "img-fluid overview-arrow"
                }
                style={{
                  transform: "scaleY(-1)",
                }}
              />
            </div>
            <div
              style={{
                width: "fit-content",
              }}
            >
              <img
                src={Arrow}
                alt="Export Energy"
                className={
                  batteryDcBus !== 0
                    ? isNegative()
                      ? "img-fluid overview-arrow"
                      : "img-fluid overview-arrow-active"
                    : "img-fluid overview-arrow"
                }
              />
            </div>
          </Row>
          <Row className="mt-0 mb-1" style={{ height: "1.7rem" }}>
            {isNegative && <p className="m-0">{batteryDcBus}kW In, Charging</p>}
            {/*eslint-disable-next-line eqeqeq */}
            {batteryDcBus < 0
              ? !isNegative() && (
                  <p className="m-0">{batteryDcBus}kW Out, Discharging</p>
                )
              : ""}
          </Row>
          <Container className="m-0 p-2 overview-top-box">
            <Row className="m-0" style={{ height: "100%" }}>
              <Col
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    height: "fit-content",
                    aspectRatio: 1 / 1,
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  {/* {isNegative && (
                    <img
                      src={Charging}
                      alt="Charging Indicator"
                      className="img-fluid"
                      style={{
                        position: "absolute",
                        width: "7.5vh",
                        zIndex: 2,
                        left: "50%",
                        transform: "translate(-50%, 0)",
                        filter: "invert(1)",
                      }}
                    />
                  )} */}
                  <img
                    src={batteryIcon()}
                    alt="Grid Connection"
                    className="img-fluid"
                    style={{
                      objectFit: "fit",
                      height: "70%",
                    }}
                  />{" "}
                  {/* <Row className="p-0">
                    {isNegative && (
                      <p className="m-0" style={{ fontSize: "0.8rem" }}>
                        Charging, {dcBus}kW
                      </p>
                    )}
                    {!isNegative && (
                      <p style={{ fontSize: "0.8rem" }} className="m-0">
                        Discharging, {dcBus}kW
                      </p>
                    )}
                  </Row> */}
                  <h3
                    className="mt-2"
                    style={{
                      width: "fit-content",
                      color: "var(--alt-white))",
                    }}
                  >
                    {batterySoc}%
                  </h3>
                </div>
              </Col>
            </Row>
          </Container>
        </Container>
      )}
      {!isDesktop && (
        <>
          <Container
            fluid
            className="my-auto text-center p-1"
            style={{
              display: "flex",
              flexDirection: "column",
              // aspectRatio: "1/1",
              backgroundColor: "rgba(255,255,255,0.3)",
              borderRadius: "8px",
              width: "100%",
              height: "10rem",
              position: "relative",
            }}
          >
            <Container
              fluid
              style={{
                position: "absolute",
                left: 0,
                top: 0,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                width: "100%",
              }}
            >
              <h1
                style={{
                  width: "fit-content",
                  fontSize: "2.75rem",
                  fontWeight: "bold",
                  color: "white",
                  filter: "drop-shadow(0px 2px 3px rgba(0,0,0,0.6))",
                }}
                // className={
                //   globalTheme === "dark"
                //     ? "darkTextOutline m-0"
                //     : "defaultTextOutline m-0"
                // }
              >
                {batterySoc === 100
                  ? Number(batterySoc)?.toFixed(0)
                  : Number(batterySoc)?.toFixed(1)}
                %
              </h1>
              <Row style={{ color: "white", zIndex: 1 }}>
                {isNegative() && (
                  <h5
                    className="m-0"
                    style={{
                      color: "white",
                      filter: "drop-shadow(0px 2px 3px rgba(0,0,0,0.6))",
                    }}
                  >
                    Charging at {batteryDcBus}kW
                  </h5>
                )}
                {!isNegative() && (
                  <h5
                    className="m-0"
                    style={{
                      color: "white",
                      filter: "drop-shadow(0px 2px 3px rgba(0,0,0,0.6))",
                    }}
                  >
                    Discharging at {batteryDcBus}kW
                  </h5>
                )}
              </Row>
              {/* <Row
                className="my-1 p-0 text-white"
                style={{ textShadow: "0px 2px 3px rgba(0,0,0,0.6)" }}
              >
                {isNegative && <p className="m-0">Charging, {dcBus}kW</p>}
                {!isNegative && <p className="m-0">Discharging, {dcBus}kW</p>}
              </Row> */}
            </Container>
            <Container
              className="my-auto p-0"
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                overflow: "hidden",
              }}
            >
              <img
                src={batteryIcon()}
                alt="Grid Connection"
                style={{
                  minWidth: "95%",
                  maxWidth: "100%",
                  opacity: 0.2,
                  zIndex: -1,
                }}
              />
            </Container>
          </Container>
        </>
      )}
    </>
  );
};

export default Battery;
