import { Card, Container, Row, Button, Form } from "react-bootstrap";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { generatePassword } from "../Admin/Tools/password-tools.js";
import { useEffect, useState } from "react";
// import { MultiSelect } from "react-multi-select-component";
import ConfirmationModal from "../Admin/ConfirmUserModal.jsx";
import { useAxiosPrivate } from "../../../Hooks/useAxiosPrivate.js";
import useBackButton from "../../../Hooks/useBackButton.js";
import useForwardButton from "../../../Hooks/useForwardButton.js";
import BackButtonRow from "../../Misc/BackButtonRow.jsx";

// page for admin account only - to add new users and select which projects accounts have access to.
const AdminPage = () => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  const goForward = () => {
    navigate(1);
  };

  useBackButton(goBack);
  useForwardButton(goForward);

  const axiosPrivate = useAxiosPrivate();
  // state for email, password and whichever projects are selected
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [selected, setSelected] = useState([]);
  const [options, setOptions] = useState([]);
  const [projectNames, setProjectNames] = useState([]);

  // for modal
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    setEmail("");
    // setPassword(generatePassword());
    setSelected([]);
  };

  // randomly generates password for initial account login
  useEffect(() => {
    setPassword(generatePassword);
  }, []);

  useEffect(() => {
    setEmail("");
    setSelected([]);
    setPassword(generatePassword);
  }, []);

  // object to be send to backend
  const userToCreate = {
    email: email,
    // password: password,
    role: "client",
    projects: selected.map((site) => site.value),
    lastLoggedIn: null,
  };

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        let arr = [];
        const response = await axiosPrivate.get(`/projects/list`);
        if (response.status === 200) {
          response.data.map((site) => arr.push({ label: site, value: site }));
        }
        setOptions(arr);
      } catch (err) {
        console.error(err);
      }
    };
    fetchProjects();
  }, []);

  // function for form submit
  // const handleSubmit = (e) => {
  //   e.preventDefault();

  // };

  return (
    <>
      <Container fluid>
        <BackButtonRow />
        <Container fluid className="mt-3 admin-page">
          <Card className="my-auto">
            <Card.Header as="h5">Add a new account</Card.Header>
            <Card.Body
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <Container fluid style={{ height: "18rem" }}>
                <Form
                  style={{ width: "50%", height: "fit-content" }}
                  className="mx-auto mt-2"
                  // onSubmit={(e) => handleSubmit(e)}
                >
                  <Form.Group className="mb-3">
                    <Form.Label>Account Email Address</Form.Label>
                    <Form.Control
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      type="email"
                      placeholder="Enter email"
                    />
                    <Form.Text>
                      This email address will be used to log in.
                    </Form.Text>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Associated Sites/Projects</Form.Label>
                    <br />
                    {/* <MultiSelect
                    options={options}
                    value={selected}
                    onChange={setSelected}
                    labelledBy="Select"
                  /> */}
                  </Form.Group>
                  <Row className="my-4 m-0">
                    <Button
                      disabled={email && selected ? false : true}
                      className="mx-auto my-3"
                      style={{ width: "fit-content" }}
                      onClick={() => handleShow()}
                    >
                      Create
                    </Button>
                  </Row>
                </Form>
              </Container>
            </Card.Body>
          </Card>
        </Container>
      </Container>

      {/* modal */}
      <ConfirmationModal
        show={show}
        handleClose={handleClose}
        onHide={handleClose}
        userObject={userToCreate}
      />
    </>
  );
};

export default AdminPage;
