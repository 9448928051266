import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
// import { Container } from "react-bootstrap";
// import { useIsDesktop } from "../../../../../Hooks/useIsDesktop";

const DailyData = ({ data }) => {
  const formattedData = [];

  data.forEach((i) => {
    formattedData.push({
      name: i.id,
      data: i.data,
      stack: 1,
    });
  });

  const options = {
    title: {
      text: null,
    },
    chart: {
      type: "column",
      zoomType: "x",
      style: {
        backgroundColor: "rgba(255,255,255,0.5)",
        borderRadius: "8px",
      },
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      crosshairs: true,
      shared: true,
      formatter: function () {
        let text = "";
        let total = 0;

        this?.points?.forEach((point, idx) => {
          const pointTotal = this?.points?.[idx]?.y;
          total += pointTotal;
          text +=
            '<span class="number"<span style="color:' +
            this?.points?.[idx]?.color +
            '">\u25CF</span>' +
            " " +
            this?.points?.[idx]?.series.name +
            " <b>" +
            Number(this?.points?.[idx]?.y)?.toFixed(2) +
            "kWh </b></br>";
        });
        text += "<br/> Total: <b>" + total + "kWh</b>";
        return text;
      },
    },
    series: formattedData,
    yAxis: {
      title: {
        text: "kWh",
      },
    },
    xAxis: {
      labels: {
        padding: 2,
      },
      type: "datetime",
      crosshair: true,
      minTickInterval: 24 * 3600 * 1000,
      tickInterval: 24 * 3600 * 1000,
      tickPixelInterval: null,
    },
    plotOptions: {
      column: {
        stacking: "normal",
        pointPadding: 0,
        borderWidth: 0,
        groupPadding: 0.1,
        shadow: false,
        grouping: true,
      },
    },
    responsive: true,
  };

  return (
    <>
      {
        <div
          id="daily-data-con"
          className="text-center"
          style={{ height: "20rem", margin: "1rem" }}
        >
          <HighchartsReact
            containerProps={{
              style: {
                height: "100%",
              },
            }}
            id="daily-data-chart"
            options={options}
            highcharts={Highcharts}
          />
        </div>
      }
    </>
  );
};

export default DailyData;
