// import { useState } from "react";

export const reportDataReducer = (state, action) => {
  switch (action.type) {
    case "setBattery":
      return { ...state, battery: action.payload };
    case "setSources":
      return { ...state, sources: action.payload };
    case "setDaily":
      return { ...state, daily: action.payload };
    case "setRenewable":
      return { ...state, renewable: action.payload };
    case "resetData":
      return {
        ...state,
        battery: [],
        sources: [],
        daily: [],
        renewable: [],
      };
    default:
      console.error(`Unhandled action type: ${action.type}`);
  }
};

export const reportLoadReducer = (state, action) => {
  switch (action.type) {
    case "setLoading":
      return { ...state, isLoading: action.payload };
    case "setError":
      return { ...state, isError: action.payload };
    case "setShowReports":
      return { ...state, showReports: action.payload };
    case "setBatteryLoading":
      return { ...state, batteryLoading: action.payload };
    case "setSourcesLoading":
      return { ...state, sourcesLoading: action.payload };
    case "setDailyLoading":
      return { ...state, dailyLoading: action.payload };
    case "setRenewablesLoading":
      return { ...state, renewablesLoading: action.payload };

    default:
      console.error(`Unhandled action type: ${action.type}`);
  }
};

export const reportDateReducer = (state, action) => {
  switch (action.type) {
    case "setStart":
      return { ...state, startDate: action.payload };
    case "setEnd":
      return { ...state, endDate: action.payload };
    default:
      console.error(`Unhandled action type: ${action.type}`);
  }
};

export const fetchReportData = async (
  endpoint,
  site,
  queryString,
  axios,
  setData
) => {
  let baseUrl = `/report/${site}/`;

  let url = "";
  let dataPoint = "";

  const urlDatapoints = () => {
    if (endpoint === "battery") {
      url = baseUrl + "battery" + queryString;
      dataPoint = "setBattery";
    }
    if (endpoint === "sources") {
      url = baseUrl + "sources" + queryString;
      dataPoint = "setSources";
    }
    if (endpoint === "daily") {
      url = baseUrl + "daily" + queryString;
      dataPoint = "setDaily";
    }
    if (endpoint === "renewable") {
      url = baseUrl + "prev-renew" + queryString;
      dataPoint = "setRenewable";
    }
  };

  try {
    urlDatapoints();

    const response = await axios.get(url);

    if (response.status === 200 || response.status === 304) {
      setData({
        type: dataPoint,
        payload: response.data,
      });
    }
  } catch (err) {
    console.log(err);
  }
};
