/* eslint-disable react-hooks/exhaustive-deps */
import { useContext } from "react";
import { useEffect, useState } from "react";
import GlobalDataContext from "../Context/DataProvider";

//going to attempt to produce a hook that dynamically fetches data, sorts that data and then returns the correct state based on the device
//passed as an argument.
//Currently there is a mass of awful code within the Overview component that is doing this job, and it is not very readable, or practical,
//and I can't imagine it's doing wonders for performance either.
//I'm probably wasting my time here but I can dream.
//Also just realised I don't need to refetch data. Oops.

//Flow ->
// Hook is called in relevant component with selected device passed as an argument
//based on this argument, the correct function will be called to fetch and store the data
//the data will be processed, then saved into state
//this state will be what is returned to the component.
//will probably need to investigate use of useSuspense to prevent the app from crashing before data is returned

export const useFetchData = (device) => {
  // const currentData = useSelector((state) => state?.data?.latest);
  const { latestData } = useContext(GlobalDataContext);

  const currentData = latestData;

  const [evState, setEvState] = useState({});
  const [inverterState, setInverterState] = useState({});
  const [acMeterState, setACMeterState] = useState({});
  const [importExportState, setImportExportState] = useState({});
  const [solarState, setSolarState] = useState({});
  const [batteryState, setBatteryState] = useState({});

  async function fetchEV() {
    const ev = {
      enableState: [],
      sessionActive: [],
      powerBudget: [],
      duration: [],
      vehicleVoltage: [],
      vehicleCurrent: [],
      vehiclePower: [],
      soc: [],
      faults: [],
    };

    for (let i = 0; i < currentData?.ev_charge_quantity; i++) {
      ev.enableState.push(currentData[`ev_charge_${i + 1}_enable_state`]);
      ev.sessionActive.push(
        currentData[`ev_charge_${i + 1}_charger_session_active`]
      );
      ev.powerBudget.push(currentData[`ev_charge_${i + 1}_max_power_budget`]);
      ev.duration.push(currentData[`ev_charge_${i + 1}_charger_duration`]);
      ev.vehicleVoltage.push(
        currentData[`ev_charge_${i + 1}_vehicle_dc_voltage`]
      );
      ev.vehicleCurrent.push(
        currentData[`ev_charge_${i + 1}_vehicle_dc_current`]
      );
      ev.vehiclePower.push(currentData[`ev_charge_${i + 1}_vehicle_dc_power`]);
      ev.soc.push(currentData[`ev_charge_${i + 1}_vehicle_soc`]);
      ev.faults.push(currentData[`ev_charge_${i + 1}_faults`]);
    }
    setEvState(ev);
  }

  async function fetchInverter() {
    const inverter = {
      power: [],
      temperature: [],
      state: [],
      frequency: [],
      acLineVoltage: [],
      realCurrent: [],
      dcBusVoltage: [],
      dcBusCurrent: [],
    };

    for (let i = 0; i < currentData?.inverter_quantity; i++) {
      inverter.power.push(currentData[`inverter_${i + 1}_real_power`]);
      inverter.temperature.push(
        currentData[`inverter_${i + 1}_internal_temperature`]
      );
      inverter.state.push(currentData[`inverter_${i + 1}_inverter_state`]);
      inverter.frequency.push(currentData[`inverter_${i + 1}_frequency`]);
      inverter.acLineVoltage.push(
        currentData[`inverter_${i + 1}_ac_line_voltage`]
      );
      inverter.realCurrent.push(currentData[`inverter_${i + 1}_real_current`]);
      inverter.dcBusVoltage.push(
        currentData[`inverter_${i + 1}_dc_bus_voltage`]
      );
      inverter.dcBusCurrent.push(
        currentData[`inverter_${i + 1}_dc_bus_current`]
      );
    }

    setInverterState(inverter);
  }

  async function fetchACMeter() {
    const acMeter = {
      acAvgPhaseVoltage: [],
      acAvgLineVoltage: [],
      acAvgCurrent: [],
      acFrequency: [],
      acTotalPowerFactor: [],
      acTotalActivePower: [],
    };

    for (let i = 0; i < currentData?.ac_meter_quantity; i++) {
      acMeter.acAvgPhaseVoltage.push(
        currentData[`ac_meter_${i + 1}_average_phase_voltage`]
      );
      acMeter.acAvgLineVoltage.push(
        currentData[`ac_meter_${i + 1}_average_line_voltage`]
      );
      acMeter.acAvgCurrent.push(
        currentData[`ac_meter_${i + 1}_average_current`]
      );
      acMeter.acFrequency.push(currentData[`ac_meter_${i + 1}_frequency`]);
      acMeter.acTotalPowerFactor.push(
        currentData[`ac_meter_${i + 1}_total_power_factor`]
      );
      acMeter.acTotalActivePower.push(
        currentData[`ac_meter_${i + 1}_total_active_power`]
      );
    }
    setACMeterState(acMeter);
  }

  async function fetchImportExport() {
    const importExport = {
      importPowerHi: [],
      importPowerLo: [],
      exportPowerHi: [],
      exportPowerLo: [],
    };

    for (let i = 0; i < currentData?.ac_meter_quantity; i++) {
      importExport.importPowerHi.push(
        currentData[`ac_meter_${i + 1}_import_active_energy_hi`]
      );
      importExport.importPowerLo.push(
        currentData[`ac_meter_${i + 1}_import_active_energy_lo`]
      );
      importExport.exportPowerHi.push(
        currentData[`ac_meter_${i + 1}_export_active_energy_hi`]
      );
      importExport.exportPowerLo.push(
        currentData[`ac_meter_${i + 1}_export_active_energy_lo`]
      );
    }

    setImportExportState(importExport);
  }

  async function fetchSolar() {
    const solar = {
      dcBusVoltage: [],
      dcBusCurrent: [],
      dcBusPower: [],
      acSolarCurrent: [],
      acSolarLineVoltage: [],
      acSolarPower: [],
      temperature: [],
      importLo: [],
      importHi: [],
    };
    const solarQuantity = currentData?.ac_solar_quantity
      ? currentData?.ac_solar_quantity
      : currentData?.dc_solar_quantity;

    const isAC = currentData?.ac_solar_quantity >= 1 ? true : false;

    if (isAC) {
      for (let i = 0; i < solarQuantity; i++) {
        solar.dcBusVoltage.push(
          currentData[`ac_solar_${i + 1}_dc_bus_voltage`]
        );
        solar.dcBusCurrent.push(
          currentData[`ac_solar_${i + 1}_dc_bus_current`]
        );
        solar.dcBusPower.push(currentData[`ac_solar_${i + 1}_dc_bus_power`]);
        solar.temperature.push(currentData[`ac_solar_${i + 1}_temperature`]);
        solar.importLo.push(
          currentData[`ac_solar_${i + 1}_import_active_energy_lo`]
        );
        solar.importHi.push(
          currentData[`ac_solar_${i + 1}_import_active_energy_hi`]
        );
        solar.acSolarCurrent.push(
          currentData[`ac_solar_${i + 1}_ac_total_current`]
        );
        solar.acSolarLineVoltage.push(
          currentData[`ac_solar_${i + 1}_ac_line_voltage`]
        );
        solar.acSolarPower
          .push(currentData[`ac_solar_${i + 1}_ac_power`])
          .toFixed(2);
      }
    }
    if (!isAC) {
      for (let i = 0; i < solarQuantity; i++) {
        solar.dcBusVoltage.push(
          currentData[`dc_solar_${i + 1}_dc_bus_voltage`]
        );
        solar.dcBusCurrent.push(
          currentData[`dc_solar_${i + 1}_dc_bus_current`]
        );
        solar.dcBusPower.push(currentData[`dc_solar_${i + 1}_dc_bus_power`]);
        solar.temperature.push(currentData[`dc_solar_${i + 1}_temperature`]);
        solar.importLo.push(
          currentData[`dc_solar_${i + 1}_import_active_energy_lo`]
        );
        solar.importHi.push(
          currentData[`dc_solar_${i + 1}_import_active_energy_hi`]
        );
      }
    }
    setSolarState(solar);
  }

  async function fetchBattery() {
    const battery = {
      dcVoltage: [],
      dcCurrent: [],
      dcPower: [],
      soc: [],
      avgCellTemp: [],
      maxCellTemp: [],
      minCellTemp: [],
      maxChargePower: [],
      maxDischargePower: [],
      cellVoltageMin: [],
      cellVoltageMax: [],
      cellVoltageAvg: [],
      maxCapacity: [],
      onlineCapacity: [],
      cycles: [],
    };

    for (let i = 0; i < currentData?.battery_quantity; i++) {
      battery.dcVoltage.push(currentData[`battery_${i + 1}_dc_bus_voltage`]);
      battery.dcCurrent.push(currentData[`battery_${i + 1}_dc_bus_current`]);
      battery.dcPower.push(currentData[`battery_${i + 1}_dc_bus_power`]);
      battery.soc.push(currentData[`battery_${i + 1}_soc`]);
      battery.avgCellTemp.push(currentData[`battery_${i + 1}_cell_temp_avg`]);
      battery.maxCellTemp.push(currentData[`battery_${i + 1}_cell_temp_max`]);
      battery.minCellTemp.push(currentData[`battery_${i + 1}_cell_temp_min`]);
      battery.maxChargePower.push(
        currentData[`battery_${i + 1}_max_charge_power`]
      );
      battery.maxDischargePower.push(
        currentData[`battery_${i + 1}_max_discharge_power`]
      );
      battery.cellVoltageMin.push(
        currentData[`battery_${i + 1}_cell_voltage_min`]
      );
      battery.cellVoltageMax.push(
        currentData[`battery_${i + 1}_cell_voltage_max`]
      );
      battery.cellVoltageAvg.push(
        currentData[`battery_${i + 1}_cell_voltage_avg`]
      );
      battery.maxCapacity.push(currentData[`battery_${i + 1}_max_capacity`]);
      battery.onlineCapacity.push(
        currentData[`battery_${i + 1}_online_capacity`]
      );
    }

    setBatteryState(battery);
  }

  useEffect(() => {
    //switch case to call relevant function based on function argument
    async function callRelevantFunc() {
      switch (device) {
        case "ev":
          await fetchEV();
          break;
        case "inverter":
          await fetchInverter();
          break;
        case "acmeter":
          await fetchACMeter();
          break;
        case "importexport":
          await fetchImportExport();
          break;
        case "solar":
          await fetchSolar();
          break;
        case "battery":
          await fetchBattery();
          break;
        default:
          break;
      }
    }

    callRelevantFunc();
  }, [currentData]);

  //switch case to return relevant state based on function argument
  switch (device) {
    case "ev":
      return evState;
    case "inverter":
      return inverterState;
    case "acmeter":
      return acMeterState;
    case "importexport":
      return importExportState;
    case "solar":
      return solarState;
    case "battery":
      return batteryState;
    default:
      return null;
  }
};
