import axios from "axios";

export default axios.create({
  baseURL: process.env.REACT_APP_BE_URL,
});

export const axiosPrivate = axios.create({
  baseURL: process.env.REACT_APP_BE_URL,
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
  // timeout: 5000,
  // signal: AbortSignal.timeout(50000),
});
