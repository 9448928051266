/* eslint-disable react-hooks/exhaustive-deps */
import { Container, Form, Row, Button, Card } from "react-bootstrap";
import LoadingScreen from "../../Misc/LoadingScreen.jsx";
import { useContext, useState } from "react";
import { useEffect } from "react";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { useAxiosPrivate } from "../../../Hooks/useAxiosPrivate.js";
import { toast } from "react-toastify";
import useBackButton from "../../../Hooks/useBackButton.js";
import useForwardButton from "../../../Hooks/useForwardButton.js";
import UserContext from "../../../Context/UserProvider.js";
import StatusContext from "../../../Context/StatusProvider.js";

const Settings = () => {
  // const userEmail = useSelector((state) => state?.user?.user?.email)
  // const dispatch = useDispatch();
  // const isLoading = useSelector((state) => state?.general?.isLoading);
  // const currentUser = useSelector((state) => state?.user?.user);

  const { userData, setUserData } = useContext(UserContext);
  const {
    isLoading,
    setIsLoading,
    isError,
    setIsError,
    errorMessage,
    setErrorMessage,
  } = useContext(StatusContext);
  const currentUser = userData;
  const axiosPrivate = useAxiosPrivate();
  // const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };
  const goForward = () => {
    navigate(1);
  };

  useBackButton(goBack);
  useForwardButton(goForward);

  // useEffect(() => {
  //   //loading indicator on page load
  //   setTimeout(() => {
  //     dispatch({
  //       type: IS_LOADING,
  //       payload: false,
  //     });
  //   }, 400);

  //   return () =>
  //     dispatch({
  //       type: IS_LOADING,
  //       payload: true,
  //     });
  // }, []);

  const toaster = () => {
    return toast.info("Profile Updated.");
  };

  //need values for user's current email address
  //compare current password to that stored in db
  //allow user to change email address, password

  const [email, setEmail] = useState("");
  // const [originalPassword, setOriginalPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [verifyNewPassword, setVerifyNewPassword] = useState("");

  // changes form info to send based on what data is entered
  const formToSubmit = () => {
    if (email !== currentUser?.email && email !== "" && newPassword === "") {
      const form = {
        email: email,
      };
      return form;
    }
    if (email !== currentUser?.email && email !== "" && newPassword !== "") {
      const form = {
        email: email,
        password: newPassword,
      };
      return form;
    }
    if (newPassword !== "") {
      const form = {
        password: newPassword,
      };
      return form;
    }
  };

  // form submit function
  async function onSubmit(e) {
    e.preventDefault();
    if (newPassword === verifyNewPassword) {
      try {
        const response = await axiosPrivate.put("/users/me", formToSubmit(), {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        });
        if (response.status === 201) {
          const getUser = await axiosPrivate.get(
            "/users/me",
            {},
            {
              withCredentials: true,
            }
          );
          setUserData(getUser.data);
          toaster();
          setEmail("");
          setNewPassword("");
          setVerifyNewPassword("");
        }
      } catch (err) {
        console.error(err);
      }
    } else {
      setIsError(true);
      setErrorMessage("You must verify your new password.");

    }
  }

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 800);

    return () => setIsLoading(true);
  }, []);

  return (
    <>
      {isLoading === true && <LoadingScreen />}
      {isLoading === false && (
        <>
          <Container
            className="p-0 m-0 settings-page"
            fluid
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100vw",
            }}
          >
            <Container
              className="p-0 m-0"
              fluid
              style={{ height: "94.8%", width: "100%" }}
            >
              <Row
                className="p-0 m-0 site-info-top"
                style={{
                  width: "100%",
                  height: "2.5rem",
                }}
              >
                <Button
                  style={{ width: "fit-content", fontSize: "0.8rem" }}
                  onClick={() => navigate(-1)}
                  className="my-auto mx-1 back-btn-top"
                >
                  <IoMdArrowRoundBack style={{ fontSize: "1.3rem" }} /> Return
                </Button>
              </Row>

              <Container className="mt-4" style={{ maxWidth: "40rem" }}>
                <Card>
                  <Card.Header as="h5">User Settings</Card.Header>
                  <Card.Body>
                    <Container>
                      <Form autoComplete="off" onSubmit={(e) => onSubmit(e)}>
                        <Form.Group>
                          <Form.Text>
                            Current registered email: {currentUser.email}
                            <br />
                          </Form.Text>
                          <Form.Text className="text-muted">
                            To leave unchanged, leave this field empty.
                          </Form.Text>
                          <FloatingLabel
                            controlId="floatingInput"
                            label="New Email Address"
                            className="mb-3"
                          >
                            <Form.Control
                              autoComplete="off"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              type="email"
                              placeholder="Enter new email address"
                            />
                          </FloatingLabel>
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Text>Enter your new password</Form.Text>
                          <FloatingLabel
                            controlId="floatingPassword"
                            label="Current password"
                          >
                            <Form.Control
                              value={newPassword}
                              onChange={(e) => setNewPassword(e.target.value)}
                              type="password"
                              placeholder="Password"
                            />
                          </FloatingLabel>
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Text>Confirm your new password</Form.Text>
                          <FloatingLabel
                            controlId="floatingPassword"
                            label="Current password"
                          >
                            <Form.Control
                              value={verifyNewPassword}
                              onChange={(e) =>
                                setVerifyNewPassword(e.target.value)
                              }
                              type="password"
                              placeholder="Password"
                            />
                          </FloatingLabel>
                        </Form.Group>

                        <Row>
                          <Button
                            style={{ width: "fit-content" }}
                            className="mx-auto"
                            type="submit"
                          >
                            Submit
                          </Button>
                        </Row>
                      </Form>
                    </Container>
                  </Card.Body>
                </Card>
              </Container>
            </Container>
          </Container>
        </>
      )}
    </>
  );
};

export default Settings;
