import { Container } from "react-bootstrap";
import { BarLoader } from "react-spinners";

const LoadingComp = () => {
  return (
    <>
      <Container
        fluid
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <BarLoader
          color="#08436d"
          loading
          width="12rem"
          aria-label="Loading Screen"
        />
      </Container>
    </>
  );
};

export default LoadingComp;
