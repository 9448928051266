import { Navigate } from "react-router-dom";
import { useContext } from "react";
import UserContext from "../../Context/UserProvider";

// allows only users with 'admin' role to access certain pages
const AdminRoute = ({ children, redirectTo }) => {
  const { userData } = useContext(UserContext);

  let isAuthenticated = userData?.role === "admin";

  return isAuthenticated ? children : <Navigate to={redirectTo} />;
};

export default AdminRoute;
