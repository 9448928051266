import { createContext, useState } from "react";

const StatusContext = createContext({});

export const StatusProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isFetchingData, setIsFetchingData] = useState(false);
  const [wipShown, setWipShown] = useState(false);

  const [showReportAck, setShowReportAck] = useState(false);
  const [reportShownSession, setReportShownSession] = useState(false);

  return (
    <StatusContext.Provider
      value={{
        wipShown,
        setWipShown,
        isLoading,
        setIsLoading,
        isError,
        setIsError,
        errorMessage,
        setErrorMessage,
        isFetchingData,
        setIsFetchingData,
        showReportAck,
        setShowReportAck,
        reportShownSession,
        setReportShownSession,
      }}
    >
      {children}
    </StatusContext.Provider>
  );
};

export default StatusContext;
