import { Alert, Button, Container, Modal } from "react-bootstrap";
import { GoAlert } from "react-icons/go";
import { useContext, useEffect, useState } from "react";
import StatusContext from "../../../../Context/StatusProvider";

const ReportHelpModal = ({ handleClose }) => {
  // const handleAck = (e) => {
  //   setShowOnLoad(e.target.checked);
  // };

  // useEffect(() => {
  //   const reportAck = localStorage.getItem("reportAck");

  //   if (reportAck !== null) {
  //     setShowOnLoad(reportAck);
  //   } else {
  //     setShowOnLoad(true);
  //   }
  // }, []);

  const {
    showReportAck,
    setShowReportAck,
    reportShownSession,
    setReportShownSession,
  } = useContext(StatusContext);

  const handleClick = (e) => {
    setShowReportAck(e.target.checked);
  };

  return (
    <>
      <Modal show={showReportAck} onHide={handleClose} centered size="lg">
        <Modal.Body>
          <Container fluid>
            <Alert
              variant="warning"
              style={{
                padding: "0.5rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <GoAlert style={{ marginRight: "1rem", fontSize: "1.5rem" }} />
              This feature is still under development. Some elements may not
              work as expected and some data may be inaccurate.
            </Alert>{" "}
            <h5>Generating Reports</h5>
            <p>
              This page allows you to generate and view reports for systems
              within a specified time period. <br /> Generated reports can then
              be exported as a PDF file and saved locally.
            </p>
            <p>
              If viewing this page on a mobile device, rotating the device to
              landscape mode is encouraged.
            </p>
            <p>
              To generate a report:
              <ul>
                <li>Select a start and end date.</li>
                <li>Select at least one system serial number.</li>
                <li>Click 'Generate Reports'</li>
                <li>Reports may take some time to compile.</li>
              </ul>
            </p>
            {/* <p className="pb-0 mb-0">Reports may take some time to compile.</p> */}
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <div
            className="pe-3 me-3"
            style={{ borderRight: "1px solid lightgray" }}
          >
            <label className="me-2" htmlFor="#ack-check">
              Show on Page Load
            </label>
            <input
              defaultChecked={true}
              onChange={(e) => handleClick(e)}
              id="ack-check"
              type="checkbox"
            />
          </div>
          <div>
            <Button variant="secondary" onClick={() => handleClose()}>
              Close
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ReportHelpModal;
