import { Container, Row, Col } from "react-bootstrap";
import EVChargerCard from "./EVChargerCard";
import { IoMdInformationCircle } from "react-icons/io";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useContext, useEffect, useState } from "react";
import GlobalDataContext from "../../../../Context/DataProvider";
import { useFetchData } from "../../../../Hooks/useFetchData";

const EVPage = ({ site }) => {
  const { latestData } = useContext(GlobalDataContext);
  const evQty = latestData?.ev_charge_quantity;
  // const evOnline = 0;
  // const evSessions = 0;
  // const allEVs = {};

  const {
    enableState,
    sessionActive,
    powerBudget,
    duration,
    vehicleVoltage,
    vehicleCurrent,
    vehiclePower,
    soc,
    faults,
  } = useFetchData("ev");

  const [evOnline, setEvOnline] = useState(0);
  const [evSessions, setEvSessions] = useState(0);

  useEffect(() => {
    const online = sessionActive?.filter((item) => item === true);
    setEvOnline(online?.length || 0);
  }, []);

  return (
    <>
      {evQty === 0 && (
        <>
          <Row className="text-center" style={{ height: "30rem" }}>
            <h2 className="my-auto">No EV charging equipment available.</h2>
          </Row>
        </>
      )}
      {evQty !== 0 && (
        <Container fluid className="mt-2">
          <Row className="text-center mb-2 ev-page-banner">
            <Col>
              <h5 className="m-0">{evQty} EV Chargers On Site</h5>
            </Col>
            <Col>
              <h5 className="m-0">
                {evOnline !== 0 ? evOnline : "No"} EV Chargers Online{" "}
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip>
                      EV Chargers that are not flagged with a fault.
                    </Tooltip>
                  }
                >
                  <span>
                    <IoMdInformationCircle style={{ fontSize: "1.3rem" }} />
                  </span>
                </OverlayTrigger>
              </h5>
            </Col>
            <Col>
              <h5 className="m-0">
                {evSessions !== 0 ? evSessions : "No"} EV Charger
                {evSessions > 1 || evSessions === 0 ? "s" : ""} In Use
              </h5>
            </Col>
          </Row>

          <Row style={{ justifyContent: "center" }}>
            {evQty !== undefined &&
              faults?.map((ev, index) => (
                <EVChargerCard
                  key={"evCard-" + index}
                  num={index + 1}
                  enable_state={enableState[index]}
                  session_active={sessionActive[index]}
                  power_budget={powerBudget[index]}
                  duration={duration[index]}
                  voltage={vehicleVoltage[index]}
                  current={vehicleCurrent[index]}
                  power={vehiclePower[index]}
                  soc={soc[index]}
                  fault={faults[index]}
                />
              ))}
          </Row>
        </Container>
      )}
    </>
  );
};

export default EVPage;
