/* eslint-disable react-hooks/exhaustive-deps */
import { Container, Row, Table, Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import BackButtonRow from "../../Misc/BackButtonRow";
import BlankCard from "../../Misc/BlankCard";
import { useContext } from "react";
import UserContext from "../../../Context/UserProvider";
import { useAxiosPrivate } from "../../../Hooks/useAxiosPrivate";
import CheckboxComp from "./CheckboxComp";
import LoadingComp from "../Site View/Reports/LoadingComp";

const FaultSettings = () => {
  const [loading, setLoading] = useState(true);

  const { userFaultProfile } = useContext(UserContext);

  const email_settings = userFaultProfile.email_notifications;

  const new_email_settings = structuredClone(email_settings);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 650);
  }, []);

  return (
    <>
      <Container
        className="m-0 p-0"
        fluid
        style={{ height: "100%", display: "flex", flexDirection: "column" }}
      >
        <BackButtonRow />

        <Container
          className="m-0 p-0"
          fluid
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Container style={{ minHeight: "50%" }}>
            <BlankCard
              title={{ title: "Notification Settings", subtitle: "Email" }}
            >
              <Container
                className="m-0 p-2"
                fluid
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Table striped bordered hover className="m-0 m-2">
                  <thead className="text-center fw-bold">
                    <tr>
                      <td>Contract Reference</td>
                      <td>Warning</td>
                      <td>Alarm</td>
                      <td>Fault</td>
                    </tr>
                  </thead>
                  <tbody className="text-center">
                    {loading && (
                      <Container
                        fluid
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <LoadingComp />
                      </Container>
                    )}
                    {!loading &&
                      new_email_settings.warnings.map((el, idx) => (
                        <>
                          <tr key={`${el.contract_ref}-row`}>
                            <td>{el.contract_ref}</td>
                            <td>
                              <CheckboxComp
                                val={new_email_settings.warnings[idx].enabled}
                              />
                            </td>
                            <td>
                              <CheckboxComp
                                val={new_email_settings.alarms[idx].enabled}
                              />
                            </td>
                            <td>
                              <CheckboxComp
                                val={new_email_settings.faults[idx].enabled}
                              />
                            </td>
                          </tr>
                        </>
                      ))}
                    {/* {loading === false &&
                      userSites.map((proj, idx) => (
                        <>
                          <tr key={`${proj}-row`}>
                            <td>{proj}</td>
                            <td>
                              <CheckboxComp
                                handleClick={() =>
                                  handleStateChange("warning", idx)
                                }
                                val={warningState[idx]?.enabled}
                              />
                            </td>
                            <td>
                              <CheckboxComp
                                handleClick={() =>
                                  handleStateChange("alarm", idx)
                                }
                                val={alarmState[idx]?.enabled}
                              />
                            </td>
                            <td>
                              <CheckboxComp
                                handleClick={() =>
                                  handleStateChange("fault", idx)
                                }
                                val={faultState[idx]?.enabled}
                              />
                            </td>
                          </tr>
                        </>
                      ))} */}
                  </tbody>
                </Table>
                <Row style={{ width: "100%" }} className="p-0 m-0">
                  <Container
                    className="ms-auto me-0"
                    style={{
                      width: "16rem",
                      // width: "fit-content",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Button
                      // className="ms-auto me-auto"
                      style={{ width: "fit-content" }}
                    >
                      Enable All
                    </Button>
                    <Button
                      // onClick={() => postFaultSettings()}
                      style={{ width: "fit-content" }}
                      // className="ms-auto me-0"
                    >
                      Save Changes
                    </Button>
                  </Container>
                </Row>
              </Container>
            </BlankCard>
          </Container>
        </Container>
      </Container>
    </>
  );
};

export default FaultSettings;
