import { Modal, Container, Row, Col } from "react-bootstrap";
import { MdAvTimer } from "react-icons/md";
import { BsFillLightningChargeFill } from "react-icons/bs";
import { AiOutlineStop } from "react-icons/ai";
import EV_Charge from "../../../../images/new/ev_charge.svg";
import EV_0 from "../../../../images/new/evCharge0.svg";
import EV_20 from "../../../../images/new/evCharge20.svg";
import EV_40 from "../../../../images/new/evCharge40.svg";
import EV_60 from "../../../../images/new/evCharge60.svg";
import EV_80 from "../../../../images/new/evCharge80.svg";
import EV_100 from "../../../../images/new/evCharge100.svg";
import { useContext } from "react";
import UserContext from "../../../../Context/UserProvider";

const EVChargerModal = ({
  num,
  soc,
  enable_state,
  session_active,
  power_budget,
  duration,
  voltage,
  current,
  power,
  fault,
  show,
  handleClose,
}) => {
  const imageToDisplay = () => {
    if (session_active === false || fault !== 0) {
      return EV_Charge;
    }
    if (soc >= 0 && soc <= 19) {
      return EV_0;
    }
    if (soc >= 20 && soc <= 39) {
      return EV_20;
    }
    if (soc >= 40 && soc <= 59) {
      return EV_40;
    }
    if (soc >= 60 && soc <= 79) {
      return EV_60;
    }
    if (soc >= 80 && soc <= 99) {
      return EV_80;
    }
    if (soc === 100) {
      return EV_100;
    }
  };

  const { theme } = useContext(UserContext);
  const globalTheme = theme;

  return (
    <>
      <Modal show={show} onHide={handleClose} keyboard={false} centered>
        <Modal.Header closeButton style={{ fontSize: "1.25rem" }}>
          Charger {num}
        </Modal.Header>
        <Modal.Body style={{ borderRadius: "10px" }}>
          <Container fluid style={{ height: "fit-content" }}>
            <Row style={{ height: "100%" }}>
              <Col className="text-center my-auto">
                <Container
                  fluid
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <img
                    src={imageToDisplay()}
                    alt="EV Charger"
                    className="img-fluid mx-auto"
                    style={{
                      maxWidth: "10rem",
                      position: "relative",
                      filter:
                        globalTheme === "dark" ? "invert(1)" : "invert(0)",
                    }}
                  />
                </Container>

                <h2>
                  {fault === 0
                    ? "Online"
                    : `Charger Offline - Fault Code ${fault} `}
                </h2>
                <h5>
                  {session_active ? (
                    <BsFillLightningChargeFill style={{ fontSize: "1.4rem" }} />
                  ) : (
                    <AiOutlineStop style={{ fontSize: "1.4rem" }} />
                  )}

                  {session_active
                    ? `Charging Session Active - ${soc || 0}%`
                    : "Not Charging"}
                </h5>
                {session_active === true && (
                  <p>
                    <MdAvTimer title="Session Duration" size="1.5rem" />{" "}
                    {session_active ? duration + " minutes" : ""}
                  </p>
                )}
                {session_active === true && (
                  <>
                    <p>DC Voltage: {voltage}V</p>
                    <p>DC Current: {current}A</p>
                    <p>DC Power: {power}kW</p>
                    <p>Max Power Budget: {power_budget}kW</p>
                  </>
                )}
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EVChargerModal;
