import { Container, Row, Col } from "react-bootstrap";
import ACMeter from "../../../../../../images/new/ac_meter.png";

const MobileACMeterComp = ({
  avgPhaseVoltage,
  avgLineVoltage,
  avgCurrent,
  frequency,
  totalPowerFactor,
  totalActivePower,
  importPower,
  exportPower,
}) => {
  return (
    <>
      <Container
        className="m-0 p-0"
        style={{ overflowX: "hidden", width: "100vw" }}
      >
        <Row
          className="m-0 p-0"
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Container
            className="text-center"
            style={{ width: "fit-content", height: "fit-content" }}
          >
            <img
              src={ACMeter}
              className="img-fluid"
              alt="AC Meter"
              style={{
                maxHeight: "10rem",
                filter: "drop-shadow(0px 3px 5px rgba(0,0,0,0.4))",
              }}
            />

            <h4 className="m-0">
              Total Power Factor: {Number(totalPowerFactor).toFixed(2)}
            </h4>
          </Container>
        </Row>

        <Container
          className="my-2 text-center m-0 mx-auto p-0"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: "fit-content",
            width: "100%",
          }}
        >
          <Row
            className="m-0 mx-auto p-0"
            style={{ display: "flex", width: "100%", gap: "0.5rem" }}
          >
            <Col
              className="m-0 p-0"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Container
                className="m-0 mb-2 p-0"
                style={{
                  backgroundColor: "rgba(255,255,255,0.4)",
                  height: "6rem",
                  borderRadius: "8px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <h1 className="m-0" style={{ fontSize: "8vw" }}>
                  {importPower}
                </h1>
                <h5 className="m-0 text-muted">Total Import Energy</h5>
              </Container>
              <Container
                className="m-0 mb-2 p-0"
                style={{
                  backgroundColor: "rgba(255,255,255,0.4)",
                  height: "6rem",
                  borderRadius: "8px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <h1 className="m-0" style={{ fontSize: "8vw" }}>
                  {Number(totalActivePower).toFixed(2)}kW
                </h1>
                <h5 className="m-0 text-muted">Total Active Power</h5>
              </Container>
              <Container
                className="m-0 mb-2 p-0"
                style={{
                  backgroundColor: "rgba(255,255,255,0.4)",
                  height: "6rem",
                  borderRadius: "8px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <h1 className="m-0" style={{ fontSize: "8vw" }}>
                  {Number(avgPhaseVoltage).toFixed(2)}V
                </h1>
                <h5 className="m-0 text-muted">Average Phase Voltage</h5>
              </Container>
            </Col>
            <Col className="m-0 p-0">
              <Container
                className="m-0 mb-2 p-0"
                style={{
                  backgroundColor: "rgba(255,255,255,0.4)",
                  height: "6rem",
                  borderRadius: "8px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <h1 className="m-0" style={{ fontSize: "8vw" }}>
                  {exportPower}
                </h1>
                <h5 className="m-0 text-muted">Total Export Energy</h5>
              </Container>
              <Container
                className="m-0 mb-2 p-0"
                style={{
                  backgroundColor: "rgba(255,255,255,0.4)",
                  height: "6rem",
                  borderRadius: "8px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <h1 className="m-0" style={{ fontSize: "8vw" }}>
                  {Number(avgCurrent).toFixed(2)}A
                </h1>
                <h5 className="m-0 text-muted">Average Current</h5>
              </Container>
              <Container
                className="m-0 mb-2 p-0"
                style={{
                  backgroundColor: "rgba(255,255,255,0.4)",
                  height: "6rem",
                  borderRadius: "8px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <h1 className="m-0" style={{ fontSize: "8vw" }}>
                  {Number(avgLineVoltage).toFixed(2)}V
                </h1>
                <h5 className="m-0 text-muted">Average Line Voltage</h5>
              </Container>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};

export default MobileACMeterComp;
