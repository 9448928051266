import { Container } from "react-bootstrap";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import { useState } from "react";
import { SyncLoader } from "react-spinners";
import LoadingComp from "../LoadingComp";

//set timeout on load - show loading animation, if no data after x seconds, switch to display "no data available for selected period."
const BatteryUsage = ({ data, serial }) => {
  const options = {
    credits: {
      enabled: false,
    },
    title: {
      text: null,
    },
    chart: {
      plotBackgroundColor: "white",
      type: "line",
      zoomType: "xy",
      events: {
        load: function (event) {
          event.target.reflow();
        },
      },
    },
    legend: {
      enabled: true,
    },
    rangeSelector: {
      enabled: false,
    },
    yAxis: {
      opposite: false,
      title: {
        text: null,
      },
      // min: Number(-50),
    },
    xAxis: {
      type: "datetime",
      crosshair: true,
    },
    tooltip: {
      crosshairs: true,
      // dateTimeLabelFormats: {},
      formatter: function () {
        let date = new Date(this?.x);
        let text =
          date?.toLocaleDateString("en-GB") +
          ", " +
          date?.toLocaleTimeString("en-GB") +
          "<br/>" +
          "</br>";
        this?.points?.forEach((point, idx) => {
          text +=
            '<span class="number"><span style="color:' +
            this?.points?.[idx]?.color +
            '">\u25CF</span> ' +
            " " +
            this?.points?.[idx]?.series.name +
            " " +
            "<b>" +
            Number(this?.points?.[idx]?.y)?.toFixed(2) +
            "</b>" +
            "</span>" +
            "<br/>";
        });

        return text;
      },
      shared: true,
    },
    series: data
      ? data.map((data) => {
          return {
            name: data?.id,
            data: data?.data,
            visible: data?.id === "DC Bus Voltage (V)" ? false : true,
          };
        })
      : [],
    plotOptions: {
      series: {
        turboThreshold: 0,
      },
    },
  };

  return (
    <>
      <div
        id="battery-usage-con"
        className="text-center"
        style={{ height: "20rem", margin: "1rem" }}
      >
        {/* <p>For system with s/n: {serial}</p> */}
        <HighchartsReact
          containerProps={{
            style: {
              height: "100%",
            },
          }}
          id={"battery-usage-chart" + serial}
          constructorType={"stockChart"}
          highcharts={Highcharts}
          options={options}
        />{" "}
      </div>
    </>
  );
};

export default BatteryUsage;
