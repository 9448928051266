import { Col, Container, Row, Form, Button } from "react-bootstrap";
import { AiFillCloseCircle } from "react-icons/ai";
import { IoSettings } from "react-icons/io5";
import { BiSolidHelpCircle } from "react-icons/bi";
import { parseISO } from "date-fns";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Clock from "react-live-clock";
import axios from "../../api/axios";
// import {
//   setErrorMessage,
//   setErrorState,
//   setLogInState,
// } from "../../Redux/Actions/stateActions";
import AuthContext from "../../Context/AuthProvider";
import { useContext } from "react";
import useAuth from "../../Hooks/useAuth";
import StatusContext from "../../Context/StatusProvider";
import UserContext from "../../Context/UserProvider";

// top 'nav' bar displaying current date/time, option for changing theme, access to settings page and logout button.
const TopNav = ({ themeSelect }) => {
  const { setAuth } = useContext(AuthContext);
  const { setIsError, setErrorMessage } = useContext(StatusContext);

  const { setLoggedIn, theme, handleThemeChange } = useContext(UserContext);

  const { setLogInNotify, setLogOutNotify } = useAuth();

  const location = useLocation();

  // const dispatch = useDispatch();

  const pathname = location.pathname;

  const navigate = useNavigate();

  const date = new Date().toISOString();
  const newDate = parseISO(date).toLocaleDateString();

  // theme picker function
  const changeTheme = () => {
    if (theme === "default") {
      handleThemeChange("dark");
    }
    if (theme === "dark") {
      handleThemeChange("default");
    }
  };

  // logout function, performs post request to API to clear cookie storing refresh token
  const logout = async () => {
    try {
      // post function, withCredentials enduring cookies sent with request (to be cleared)
      await axios.post(
        "/users/logout",
        {},
        {
          withCredentials: true,
        }
      );

      // reset state on logout
      setAuth({});
      setLogInNotify(false);
      setLogOutNotify(true);
      setErrorMessage("");
      setIsError(false);
      setLoggedIn(false);
      navigate("/");
      window.location.reload();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <header>
        <Row
          className="p-0 m-0 topbar-nav"
          style={{
            zIndex: "5",
            width: "100%",
            height: "3rem",
            position: "sticky",
            top: 0,
          }}
        >
          <Container
            fluid
            style={{
              display: "flex",
              height: "100%",
              flexDirection: "column",
              width: "fit-content",
              marginLeft: "1rem",
              marginRight: "auto",
              alignItems: "center",
            }}
            className="p-0 text-white"
          >
            <Row className="p-0" style={{ height: "100%" }}>
              <Col className="m-0 p-0" style={{ height: "100%" }}>
                {/* contains the date/time components */}
                <Container
                  className="text-center"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <span style={{ userSelect: "none" }}>
                    <Clock
                      format={"HH:mm:ss"}
                      ticking={true}
                      timezone={"Europe/London"}
                    />
                  </span>
                  <span
                    className="mb-1"
                    style={{ userSelect: "none", fontSize: "0.9rem" }}
                  >
                    {newDate}
                  </span>
                </Container>
              </Col>
            </Row>
          </Container>
          {/* contains rest of options - theme changer/settings/logout etc */}
          <Container
            fluid
            className="p-0"
            style={{
              display: pathname === "/" ? "none" : "flex",
              flexDirection: "row",
              width: "10rem",
              marginLeft: "auto",
              marginRight: "1rem",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Form.Check type="switch" onClick={() => changeTheme()} />

            <BiSolidHelpCircle
              onClick={() => navigate("/help")}
              title="Help Page"
              id="help-btn"
              color="white"
              style={{ fontSize: "1.4rem" }}
            />

            <IoSettings
              onClick={() => navigate("/dashboard/settings")}
              title="User Settings"
              id="settings-btn"
              color="white"
              style={{ fontSize: "1.4rem" }}
            />

            <AiFillCloseCircle
              title="Sign Out"
              onClick={() => logout()}
              id="logout-btn"
              color="white"
              style={{ fontSize: "1.5rem" }}
            />
          </Container>
        </Row>
      </header>
    </>
  );
};

export default TopNav;
