import { Form } from "react-bootstrap";

const CheckboxComp = ({ handleClick, val }) => {
  console.log(val);
  return (
    <>
      <Form.Check
        checked={val}
        onChange={handleClick}
        aria-label="Enable/Disable Email Notifications"
        title="Enable Email Notifications"
      />
      {/* <ToggleButtonGroup
        onChange={(e) => handleClick(e, faultType, project)}
        type="radio"
        size="sm"
        defaultValue={val || false}
        name={`${project}-${faultType}-group`}
      >
        <ToggleButton
          id={`${project}-${faultType}-enabled`}
          value={true}
          variant="outline-success"
        >
          Enabled
        </ToggleButton>
        <ToggleButton
          id={`${project}-${faultType}-disabled`}
          value={false}
          variant="outline-danger"
        >
          Disabled
        </ToggleButton>
      </ToggleButtonGroup> */}
    </>
  );
};

export default CheckboxComp;
