import { Container, Row, Col } from "react-bootstrap";
import Inverter from "../../../../../../images/new/inverter.png";

const MobileInverterComp = ({
  power,
  temperature,
  state,
  frequency,
  acLineVoltage,
  realCurrent,
  dcBusVoltage,
  dcBusCurrent,
}) => {
  return (
    <>
      <Container
        className="m-0 p-0"
        style={{ overflowX: "hidden", width: "100vw" }}
      >
        <Row
          className="m-0 p-0"
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Container
            className="text-center"
            style={{ width: "fit-content", height: "fit-content" }}
          >
            <img
              src={Inverter}
              className="img-fluid"
              alt="Inverter"
              style={{
                maxHeight: "10rem",
                filter: "drop-shadow(0px 3px 5px rgba(0,0,0,0.4))",
              }}
            />
            <h4 className="m-0">{state}</h4>
          </Container>
        </Row>

        <Container
          className="my-2 text-center m-0 mx-auto p-0"
          style={{
            display: "flex",
            flexDirection: "column",
            // justifyContent: "center",
            alignItems: "center",
            height: "fit-content",
            width: "100%",
            // backgroundColor: "rgba(255,255,255,0.4)",
            // borderRadius: "8px",
          }}
        >
          <Row
            className="m-0 mx-auto p-0"
            style={{
              display: "flex",
              // border: "1px solid red",
              width: "100%",
              gap: "0.5rem",
            }}
          >
            <Col
              className="m-0 p-0"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Container
                className="m-0 mb-2 p-0"
                style={{
                  backgroundColor: "rgba(255,255,255,0.4)",
                  height: "6rem",
                  borderRadius: "8px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <h1 className="m-0" style={{ fontSize: "8vw" }}>
                  {Number(power / 1000).toFixed(2)}kW
                </h1>
                <h3 className="m-0 text-muted">Real Power</h3>
              </Container>
              <Container
                className="m-0 mb-2 p-0"
                style={{
                  backgroundColor: "rgba(255,255,255,0.4)",
                  height: "6rem",
                  borderRadius: "8px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <h1 className="m-0" style={{ fontSize: "8vw" }}>
                  {Number(dcBusCurrent).toFixed(2)}A
                </h1>
                <h3 className="m-0 text-muted">DC Bus Current</h3>
              </Container>
              <Container
                className="m-0 p-0"
                style={{
                  backgroundColor: "rgba(255,255,255,0.4)",
                  height: "6rem",
                  borderRadius: "8px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                {" "}
                <h1 className="m-0" style={{ fontSize: "8vw" }}>
                  {Number(frequency).toFixed(2)}Hz
                </h1>
                <h3 className="m-0 text-muted">Frequency</h3>
              </Container>
            </Col>
            <Col
              className="m-0 p-0"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Container
                className="m-0 mb-2 p-0"
                style={{
                  backgroundColor: "rgba(255,255,255,0.4)",
                  height: "6rem",
                  borderRadius: "8px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <h1 className="m-0" style={{ fontSize: "8vw" }}>
                  {Number(acLineVoltage).toFixed(2)}V
                </h1>
                <h3 className="m-0 text-muted">AC Line Voltage</h3>
              </Container>{" "}
              <Container
                className="m-0 p-0 mb-2"
                style={{
                  backgroundColor: "rgba(255,255,255,0.4)",
                  height: "6rem",
                  borderRadius: "8px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                {" "}
                <h1 className="m-0" style={{ fontSize: "8vw" }}>
                  {Number(dcBusVoltage).toFixed(2)}V
                </h1>
                <h3 className="m-0 text-muted">DC Bus Voltage</h3>
              </Container>
              <Container
                className="m-0 p-0"
                style={{
                  backgroundColor: "rgba(255,255,255,0.4)",
                  height: "6rem",
                  borderRadius: "8px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                {" "}
                <h1 className="m-0" style={{ fontSize: "8vw" }}>
                  {Number(temperature).toFixed(2)}°C
                </h1>
                <h3 className="m-0 text-muted">Internal Temp.</h3>
              </Container>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};

export default MobileInverterComp;
