/* eslint-disable eqeqeq */
import { Container, Row, Col } from "react-bootstrap";
import Inverter from "../../../../images/new/inverter.png";
import { useIsDesktop } from "../../../../Hooks/useIsDesktop";
import GaugeChart from "react-gauge-chart";
import MobileInverterComp from "../Battery/Mobile Views/Inverter";

const InverterComp = ({
  power,
  temperature,
  state,
  acLineVoltage,
  realCurrent,
  dcBusVoltage,
  dcBusCurrent,
  frequency,
}) => {
  const inverterStates = {
    0: "POR",
    1: "Ready",
    2: "Following",
    3: "Fault",
    4: "Forming",
    5: "Reconnect Delay",
    6: "Reserved",
    7: "Area EPS Loss",
    8: "Charging DC",
    9: "Ride Through",
    10: "Momentary Cessation",
    11: "Transitioning",
    12: "Inhibited",
    13: "Connect Delay",
  };

  const renderPowerChart = () => {
    const powerValue = power < 0 ? (power / 1000) * -1 : power / 1000;

    return (
      <GaugeChart
        id="inverter-real-power-gauge"
        percent={powerValue / 100}
        hideText={true}
        colors={["#7dbae5", "#3a5c9b"]}
        arcWidth={0.2}
        nrOfLevels={1}
        cornerRadius={0}
        arcsLength={[powerValue / 100, 1 - powerValue / 100]}
      />
    );
  };

  const isDesktop = useIsDesktop();

  return (
    <>
      {!isDesktop && (
        <>
          <MobileInverterComp
            power={power}
            temperature={temperature}
            state={inverterStates[state]}
            frequency={frequency}
            acLineVoltage={acLineVoltage}
            realCurrent={realCurrent}
            dcBusVoltage={dcBusVoltage}
            dcBusCurrent={dcBusCurrent}
          />
        </>
      )}
      {isDesktop && (
        <>
          <Container
            fluid
            className="p-0"
            style={{ display: "flex", height: "fit-content" }}
          >
            <Col
              className="mt-3 pb-4"
              style={{
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
                // border: "1px solid red",
              }}
            >
              <Container
                fluid
                className="p-0 my-2 mx-auto"
                style={{
                  width: "100%",
                  display: "flex",
                  height: "30rem",
                  justifyContent: "center",
                }}
              >
                <Row
                  className="m-0 p-0"
                  style={{ width: "100%", height: "100%" }}
                >
                  <Col
                    className="m-0 p-0"
                    xs={12}
                    md={3}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={Inverter}
                      alt="Inverter Icon"
                      className="img-fluid"
                      style={{ maxHeight: "12rem" }}
                    />
                    <h5>{inverterStates[state]}</h5>
                  </Col>
                  <Col className="mt-2 p-0 my-auto">
                    <Container
                      fluid
                      className="m-0 p-0"
                      style={{
                        height: "90%",
                        display: "grid",
                        gridTemplateColumns: "repeat(8, 1fr)",
                        gridTemplateRows: "repeat(6, 1fr)",
                        gridGap: "0.5rem",
                      }}
                    >
                      <Container
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gridArea: "1/1/4/5",
                          backgroundColor: "rgba(255,255,255,0.4)",
                          borderRadius: "20px 8px 8px 8px",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ height: "50%", width: "fit-content" }}>
                          {renderPowerChart()}
                        </div>
                        <h1 style={{ fontSize: "3.5rem" }} className="lcd">
                          {Number(power / 1000).toFixed(2) == 0
                            ? "0"
                            : Number(power / 1000).toFixed(2)}{" "}
                          kW
                        </h1>
                        <h3 className="">Real Power</h3>
                      </Container>
                      <Container
                        className="text-center"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          gridArea: "1/5/4/9",
                          backgroundColor: "rgba(255,255,255,0.4)",
                          borderRadius: "8px 20px 8px 8px",
                        }}
                      >
                        <div style={{ height: "50%", width: "fit-content" }}>
                          <GaugeChart
                            // animate={false}
                            id="inverter-voltage-gauge"
                            hideText={true}
                            arcWidth={0.2}
                            nrOfLevels={1}
                            // colors={["#3a5c9b"]}
                            percent={dcBusVoltage / 1000}
                            cornerRadius={0}
                            colors={["#7dbae5", "#3a5c9b"]}
                            arcsLength={[
                              dcBusVoltage / 1000,
                              1 - dcBusVoltage / 1000,
                            ]}
                          />
                        </div>
                        <h1 style={{ fontSize: "3.5rem" }} className="lcd">
                          {dcBusVoltage.toFixed(2) == 0
                            ? "0"
                            : dcBusVoltage.toFixed(2)}{" "}
                          V
                        </h1>
                        <h3 className="">DC Bus Voltage</h3>
                      </Container>
                      <Container
                        className="text-center"
                        style={{
                          gridArea: "4/1/7/5",
                          backgroundColor: "rgba(255,255,255,0.4)",
                          borderRadius: "8px 8px 8px 20px",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ height: "50%", width: "fit-content" }}>
                          <GaugeChart
                            // animate={false}
                            id="inverter-current-gauge"
                            hideText={true}
                            arcWidth={0.2}
                            nrOfLevels={1}
                            colors={["#7dbae5", "#3a5c9b"]}
                            percent={
                              dcBusCurrent < 0
                                ? (dcBusCurrent / 50) * -1
                                : dcBusCurrent / 50
                            }
                            cornerRadius={0}
                            arcsLength={[
                              dcBusCurrent / 50,
                              1 - dcBusCurrent / 50,
                            ]}
                          />
                        </div>
                        <h1 style={{ fontSize: "3.5rem" }} className="lcd">
                          {dcBusCurrent.toFixed(2) == 0
                            ? "0"
                            : dcBusCurrent.toFixed(2)}{" "}
                          A
                        </h1>
                        <h3 className="">DC Bus Current</h3>
                      </Container>
                      <Container
                        style={{
                          gridArea: "4/5/7/9",
                          backgroundColor: "rgba(255,255,255,0.4)",
                          borderRadius: "8px 8px 20px 5px",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            height: "50%",
                            width: "fit-content",
                          }}
                        >
                          <GaugeChart
                            hideText={true}
                            id="battery-temp-gauge"
                            arcWidth={0.2}
                            nrOfLevels={1}
                            colors={["yellow", "orange", "red"]}
                            percent={temperature / 100}
                            cornerRadius={0}
                            arcsLength={[0.4, 0.6, 1]}
                          />
                        </div>
                        <h1 style={{ fontSize: "3.5rem" }} className="lcd">
                          {temperature.toFixed(2)}°C
                        </h1>
                        <h4 className=""> Temperature</h4>
                      </Container>
                    </Container>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Container>
        </>
      )}
    </>
  );
};

export default InverterComp;
