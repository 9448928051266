import { Container, Row, Col } from "react-bootstrap";

const NotificationSelection = () => {
  return (
    <>
      <Container fluid style={{ border: "1px solid white", height: "100%" }}>
        <Row style={{ height: "100%" }}>
          <Col style={{ border: "1px solid white" }}></Col>
          <Col
            className="m-0 p-0"
            style={{
              border: "1px solid white",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <button
              style={{ width: "fit-content", padding: "0 0.5rem 0 0.5rem" }}
            >
              Filter Selection
            </button>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default NotificationSelection;
