import { Col, Container, Row } from "react-bootstrap";
import MapView from "./MapViewComp.jsx";
import MobileNav from "./MobileNavComp.jsx";

const MobileLandingPage = ({ siteNames, siteDataLoaded }) => {
  return (
    <>
      <Container fluid className="p-0 m-0 mobile-landing-page">
        <MobileNav siteNames={siteNames} />
        <Container fluid className="p-0">
          <Row className="p-0">
            <Col className="p-0">
              <MapView siteDataLoaded={siteDataLoaded} />
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};

export default MobileLandingPage;
