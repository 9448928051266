import { Col, Container, Row } from "react-bootstrap";
import { useFetchData } from "../../../../../../Hooks/useFetchData";
import { useState, useEffect } from "react";
import UserContext from "../../../../../../Context/UserProvider";
import { useContext } from "react";
import { ImPower } from "react-icons/im";
import { HiArrowNarrowUp } from "react-icons/hi";

const MobileBattery = () => {
  const {
    dcVoltage,
    dcCurrent,
    dcPower,
    soc,
    avgCellTemp,
    maxCellTemp,
    minCellTemp,
    maxChargePower,
    maxDischargePower,
  } = useFetchData("battery");

  const { theme } = useContext(UserContext);
  const globalTheme = theme;

  const [bgColour, setBgColour] = useState("lightgreen");
  const [battSoc, setSoc] = useState(0);

  useEffect(() => {
    setSoc(soc);

    if (battSoc <= 25) {
      setBgColour("#d11313");
    }
    if (battSoc >= 26 && battSoc <= 50) {
      setBgColour("#e66a0b");
    }
    if (battSoc >= 51 && battSoc <= 75) {
      setBgColour("#f2c80f");
    }
    if (battSoc >= 76 && battSoc <= 100) {
      setBgColour("#64f538");
    }
  }, [soc, setSoc, battSoc]);

  return (
    <>
      <Container
        className="my-2"
        fluid
        style={{ display: "flex", flexDirection: "column", height: "100%" }}
      >
        <Row
          className="mt-2"
          style={{
            // border: "1px solid white",
            display: "flex",
            height: "20rem",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Col>
            <Container fluid>
              <Col
                className="m-0 p-0"
                xs={12}
                md={3}
                style={{
                  display: "flex",
                  // border: "1px solid yellow",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    position: "relative",
                    height: "12rem",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div className="batteryBorder mt-4">
                    <div className="batteryTop"></div>

                    <div
                      className="charge"
                      style={{
                        height: soc <= 5 ? "5%" : `${battSoc}%`,
                        backgroundColor: `${bgColour}`,
                      }}
                    ></div>
                    <input
                      style={{
                        color:
                          globalTheme === "dark"
                            ? battSoc >= 55 && soc <= 100
                              ? "black"
                              : "white"
                            : "black",
                      }}
                      id="chargeDisplay"
                      value={Number(battSoc).toFixed(1) + "%"}
                      readOnly
                      disabled
                    />
                  </div>
                </div>
              </Col>
            </Container>
          </Col>
        </Row>

        <Row
          className="mt-4 m-0 p-0 mx-auto"
          style={{
            backgroundColor: "rgba(255,255,255,0.4)",
            borderRadius: "8px",
            // border: "1px solid red",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Row className="text-center">
            {dcPower !== 0 && dcPower < 0 ? (
              <h2 className="my-3">
                <ImPower style={{ color: "gold" }} /> Battery Charging
              </h2>
            ) : (
              <h2 className="my-3">
                <HiArrowNarrowUp style={{ color: "red" }} /> Battery Discharging
              </h2>
            )}

            <Col
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <h6>Max Charge Power: </h6>
              <h2>{(Number(maxChargePower) / 1000).toFixed(2)}kW</h2>
            </Col>
            <Col
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <h6>Max Discharge Power:</h6>
              <h2>{(Number(maxDischargePower) / 1000).toFixed(2)}kW</h2>
            </Col>
          </Row>
          <hr />
          <Col
            className="text-center m-0"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Row className="m-0" style={{ display: "flex" }}>
              <Col className="text-center m-0">
                <h6>Avg Cell Temp:</h6>
                <h2>{Number(avgCellTemp).toFixed(2)}°C</h2>
              </Col>
            </Row>
            <Row className="m-0">
              <Col className="m-0 text-center">
                <h6>DC Bus Voltage:</h6>
                <h2>{Number(dcVoltage).toFixed(2)}V</h2>
              </Col>
            </Row>
          </Col>
          <Col
            className="text-center m-0"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Row className="m-0">
              <Col className="text-center m-0">
                <h6>DC Bus Power:</h6>
                <h2>{Number(dcPower).toFixed(2)}kW</h2>
              </Col>
            </Row>
            <Row className="m-0">
              <Col className="m-0">
                <h6>DC Bus Current:</h6>
                <h2>{Number(dcCurrent).toFixed(2)}A</h2>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default MobileBattery;
