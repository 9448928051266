/* eslint-disable eqeqeq */
import { Col, Container, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import SolarActive from "../../../images/new/solar_active.png";
import SolarInactive from "../../../images/new/solar_inactive.png";
import { useIsDesktop } from "../../../Hooks/useIsDesktop";
import { useFetchData } from "../../../Hooks/useFetchData";

import GaugeChart from "react-gauge-chart";

const Solar = () => {
  const shiftBitsAround = (lo = 0, hi = 0) => {
    let number = (hi << 16) + lo;

    let suffix = "kWh";

    if (number === 0) {
      return "--kWh";
    } else if (number > 10000) {
      suffix = "MWh";
      number = number / 10000;
      const stringToReturn = `${number.toFixed(2)}${suffix}`;
      return stringToReturn;
    } else {
      number = number / 10;
      const stringToReturn = `${number.toFixed(2)}${suffix}`;
      return stringToReturn;
    }
  };

  const {
    dcBusVoltage,
    dcBusCurrent,
    dcBusPower,
    temperature,
    importLo,
    importHi,
    acSolarPower,
    acSolarCurrent,
    acSolarLineVoltage,
  } = useFetchData("solar");

  const imageSource = () => {
    if (Number(dcBusPower) !== 0) {
      return SolarActive;
    } else if (Number(dcBusPower) === 0) {
      if (acSolarPower !== 0) {
        return SolarActive;
      }
      return SolarInactive;
    }
  };

  const isDesktop = useIsDesktop();

  return (
    <>
      {isDesktop && (
        <>
          <Container
            className="mt-4"
            style={{
              height: "fit-content",
              display: "flex",
            }}
          >
            <Container
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "fit-content",
              }}
            >
              <img
                src={imageSource()}
                alt="Solar Panel"
                className="img-fluid"
                style={{
                  maxHeight: dcBusPower == 0 ? "12rem" : "15rem",
                  opacity: dcBusPower == 0 ? 0.5 : 1,
                }}
              />
              {acSolarPower?.length >= 1 && (
                <>
                  <h1 className="lcd mt-2" style={{ fontSize: "4rem" }}>
                    {Number(acSolarPower)?.toFixed(2)}kW
                  </h1>
                  <h3>AC Solar Power</h3>
                </>
              )}
              {acSolarPower?.length === 0 && (
                <>
                  <h1 className="lcd mt-2" style={{ fontSize: "4rem" }}>
                    {Number(dcBusPower).toFixed(2)}kW
                  </h1>
                  <h3>DC Bus Power</h3>
                </>
              )}
            </Container>
            <Col
              md={3}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Container
                className="text-center"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "75%",
                  height: "fit-content",
                  padding: "1rem",
                  // border: "1px solid red",
                  backgroundColor: "rgba(255,255,255,0.4)",
                  borderRadius: "8px",
                }}
              >
                <h5 style={{ width: "fit-content", userSelect: "none" }}>
                  Total Lifetime Import
                </h5>

                <h1>{shiftBitsAround(importLo, importHi)}</h1>
              </Container>
              <Container
                className="mt-2 text-center"
                style={{
                  padding: "1rem",
                  backgroundColor: "rgba(255,255,255,0.4)",
                  height: "fit-content",
                  width: "75%",
                  borderRadius: "8px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h5>Temperature</h5>
                <h1 className="">{temperature != 0 ? temperature : "--"}°C</h1>
              </Container>
            </Col>
            <Container
              className="text-center"
              style={{
                // backgroundColor: "rgba(255,255,255,0.4)",
                // borderRadius: "8px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "fit-content",
              }}
            >
              <Row>
                <Col
                  className="mb-2"
                  style={{
                    backgroundColor: "rgba(255,255,255,0.4)",
                    borderRadius: "8px",
                  }}
                >
                  <GaugeChart
                    percent={dcBusCurrent / 100}
                    id="solar-current-gauge"
                    hideText={true}
                    colors={["#7dbae5", "#3a5c9b"]}
                    arcWidth={0.2}
                    nrOfLevels={1}
                    cornerRadius={0}
                    arcsLength={[dcBusCurrent / 100, 1 - dcBusCurrent / 100]}
                  />
                  {acSolarPower ? (
                    <>
                      <h1 className="lcd m-0" style={{ fontSize: "3.5rem" }}>
                        {Number(dcBusCurrent).toFixed(2)}A
                      </h1>
                      <h5>DC Bus Current</h5>
                    </>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
              <Row>
                <Col
                  style={{
                    backgroundColor: "rgba(255,255,255,0.4)",
                    borderRadius: "8px",
                  }}
                >
                  <GaugeChart
                    percent={dcBusVoltage / 1000}
                    id="solar-voltage-gauge"
                    hideText={true}
                    colors={["#7dbae5", "#3a5c9b"]}
                    arcWidth={0.2}
                    nrOfLevels={1}
                    cornerRadius={0}
                    arcsLength={[dcBusVoltage / 1000, 1 - dcBusVoltage / 1000]}
                  />
                  <h1 className="lcd" style={{ fontSize: "3.5rem" }}>
                    {Number(dcBusVoltage).toFixed(2)}V
                  </h1>
                  <h5>DC Bus Voltage</h5>
                </Col>
              </Row>
            </Container>
          </Container>
        </>
      )}
      {!isDesktop && (
        <>
          <Container
            className="mt-2 p-0"
            style={{
              overflowX: "hidden",
              // width: "100vw",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Row
              className="m-0 p-0"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Container
                className="text-center"
                style={{ width: "fit-content", height: "fit-content" }}
              >
                <img
                  src={imageSource()}
                  alt="Solar Panel"
                  className="img-fluid"
                  style={{ maxHeight: "10rem" }}
                />
                <p>{dcBusPower == 0 ? "Inactive" : "Active"}</p>
              </Container>
            </Row>

            <Row className="m-0 my-2 p-0" style={{ width: "100%" }}>
              <Container
                className="m-0 mb-2 p-0"
                style={{
                  backgroundColor: "rgba(255,255,255,0.4)",
                  height: "6rem",
                  borderRadius: "8px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <h1 className="m-0" style={{ fontSize: "8vw" }}>
                  {shiftBitsAround(importLo, importHi)}
                </h1>
                <h5>Imported Solar Energy for Today</h5>
              </Container>

              <Container
                className="m-0 mb-2 p-0"
                style={{
                  backgroundColor: "rgba(255,255,255,0.4)",
                  height: "6rem",
                  borderRadius: "8px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <h1 className="m-0" style={{ fontSize: "8vw" }}>
                  {Number(dcBusPower).toFixed(2)}kW
                </h1>
                <h5>DC Bus Power</h5>
              </Container>
              <Container
                className="m-0 mb-2 p-0"
                style={{
                  backgroundColor: "rgba(255,255,255,0.4)",
                  height: "6rem",
                  borderRadius: "8px",
                  display: "flex",
                  // flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <Col
                  className="text-center"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h1 className="m-0" style={{ fontSize: "8vw" }}>
                    {Number(dcBusCurrent).toFixed(2)}A
                  </h1>
                  <h5>DC Bus Current</h5>
                </Col>
                <Col
                  className="text-center"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h1 className="m-0" style={{ fontSize: "8vw" }}>
                    {Number(dcBusVoltage).toFixed(2)}V
                  </h1>
                  <h5>DC Bus Voltage</h5>
                </Col>
              </Container>
            </Row>
          </Container>
        </>
      )}
    </>
  );
};

export default Solar;
