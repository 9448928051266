import { Card } from "react-bootstrap";
import LoadingComp from "../Pages/Site View/Reports/LoadingComp";

//not currently being used
const BlankCard = ({ title, children, loading }) => {
  return loading && loading === true ? (
    <LoadingComp />
  ) : (
    <Card className="m-0 p-0" style={{ height: "100%", width: "100%" }}>
      <Card.Header as="h6">
        {title.title} {title.subtitle && `- ${title.subtitle}`}
      </Card.Header>
      <Card.Body className="p-0">{children}</Card.Body>
    </Card>
  );
};

export default BlankCard;
