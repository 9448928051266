/* eslint-disable react-hooks/exhaustive-deps */
import { Container } from "react-bootstrap";
import { useEffect, useState } from "react";
import LoadingComp from "../LoadingComp";

const RenewablePrevMonth = ({ data }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 2500);
  }, []);

  return (
    <>
      {!show && <LoadingComp />}
      {show && (
        <>
          <Container
            id="renewable-energy-con"
            fluid
            style={{
              display: "flex",
              flexDirection: "column",
              // backgroundColor: "white",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Container
              style={{
                // border: "1px solid red",
                height: "100%",
                width: "fit-content",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                padding: "1rem",
                // height: "fit-content",
              }}
            >
              <h1 className="m-0 p-0" style={{ width: "100%" }}>
                {data?.month}
              </h1>
              <h1>
                {data.solar ? Number(data.solar / 1000).toFixed(2) : 0}
                <span style={{ fontSize: "1.5rem" }}>kWh</span>
              </h1>
              <h4 className="m-0 p-0">Renewable Energy Import</h4>
            </Container>
          </Container>
        </>
      )}
    </>
  );
};

export default RenewablePrevMonth;
