import React from "react";
import ErrorPage from "./ErrorPage";

class ErrorBoundary extends React.Component {
  state = {
    hasError: false,
    message: "",
  };

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log({ error, errorInfo });
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage />;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
