import { Navigate } from "react-router-dom";
import { useContext } from "react";
import UserContext from "../../Context/UserProvider";

// protected route ensuring only logged in users are allowed access to anything that isn't the login page
const ProtectedRoute = ({ children, redirectTo }) => {
  const { loggedIn } = useContext(UserContext);
  let isAuthenticated = loggedIn;

  return isAuthenticated ? children : <Navigate to={redirectTo} />;
};

export default ProtectedRoute;
