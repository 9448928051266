import { useEffect, useState } from "react";

const useForwardButton = (callback) => {
  const [isForward, setIsForward] = useState(false);
  const handleEvent = () => {
    setIsForward(true);
    callback();
    window.history.go(1);
  };

  useEffect(() => {
    window.addEventListener("popstate", handleEvent);
    return () => window.removeEventListener("popstate", handleEvent);
  });

  return isForward;
};

export default useForwardButton;
