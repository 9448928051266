import { Modal, Button, Row, Container } from "react-bootstrap";
import { useAxiosPrivate } from "../../../Hooks/useAxiosPrivate";
import { toast } from "react-toastify";


//Modal that confirms the creation of a new user account and posts the data to the server
const ConfirmationModal = ({ show, handleClose, userObject }) => {
  const axiosPrivate = useAxiosPrivate();
  const handleSubmit = async () => {
    try {
      const response = await axiosPrivate.post("/users/new-user", userObject);
      if (response) {
        handleClose();
        userToast();
      }
    } catch (err) {
      console.error(err);
    }
  };

  const userToast = () => {
    return toast.info("User account created!", {
      toastId: "userToast",
    });
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>Email Address: {userObject.email}</Row>
            <Row>
              Associated Sites: {userObject.projects.map((site) => site + " ")}
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Return
          </Button>
          <Button onClick={() => handleSubmit()} variant="primary">
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ConfirmationModal;
