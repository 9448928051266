import { Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAxiosPrivate } from "../../../Hooks/useAxiosPrivate";
import { useContext } from "react";
import GlobalDataContext from "../../../Context/DataProvider";

const SiteCards = ({ name }) => {
  let siteCxp = name;

  const axiosPrivate = useAxiosPrivate();

  const navigate = useNavigate();

  const [siteLocation, setSiteLocation] = useState("No Data Available");

  const { siteInfo } = useContext(GlobalDataContext);

  const findAndFormatSerial = (site) => {
    const siteMatch = siteInfo.find(
      (item) => item?.contract_ref?.toLowerCase() === site.toLowerCase()
    );

    if (siteMatch) {
      const serial = siteMatch.serial_number
        ? siteMatch.serial_number
        : "0010/0020";
      const split = serial.split("/");
      return `${split[0]}${split[1]}`.toString();
    } else {
      return null;
    }
  };

  const fetchSiteName = async () => {
    try {
      const response = await axiosPrivate.get(`/projects/${name}`);

      if (response.status === 304 || response.status === 200) {
        setSiteLocation(response.data.location);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchSiteName();
  }, []);

  return (
    <>
      <Container
        className="site-cards"
        onClick={() =>
          navigate("/dashboard/sites/" + name + "/" + findAndFormatSerial(name))
        }
      >
        <Row style={{ height: "100%" }}>
          <Col className="text-center my-auto">
            <h4 className="p-0 m-0">{siteCxp.toUpperCase()}</h4>
          </Col>
        </Row>
        <Row className="site-card-location-text">
          <Col
            className="text-center"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p className="p-0 m-0 site-card-location-text">{siteLocation}</p>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SiteCards;
