import { Container, Row, Button, Form, Card } from "react-bootstrap";
import useBackButton from "../../../Hooks/useBackButton.js";
import useForwardButton from "../../../Hooks/useForwardButton.js";
import { useNavigate } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useAxiosPrivate } from "../../../Hooks/useAxiosPrivate.js";
import { useState, useEffect, useContext } from "react";
// import { MultiSelect } from "react-multi-select-component";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { toast } from "react-toastify";
import UserContext from "../../../Context/UserProvider.js";
import BackButtonRow from "../../Misc/BackButtonRow.jsx";

//page that is meant to enable admin to edit an account's associated projects
//WIP - not currently in use
const EditAccount = () => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  const goForward = () => {
    navigate(1);
  };

  useBackButton(goBack);
  useForwardButton(goForward);

  const axiosPrivate = useAxiosPrivate();

  const { userData } = useContext(UserContext);
  // const userProjects = useSelector((state) => state?.user?.user?.projects);
  const userProjects = userData.projects;

  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState([]);

  const [allAccounts, setAllAccounts] = useState([]);
  const [selectedAccountData, setSelectedAccountData] = useState({});
  const [accountSelected, setAccountSelected] = useState(false);

  const projectNames = [];

  const fetchAccountData = async () => {
    try {
      const response = await axiosPrivate.get("/users");
      console.log(response.data);
      setAllAccounts(response.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    async function getProjectInfo() {
      const arr = [];
      try {
        const response = await axiosPrivate.get(`/projects`);

        projectNames.forEach(async (project) => {
          return arr.push({
            label: project.contract_ref + " " + project.project_title,
            value: project.contract_ref,
          });
        });
        setOptions(arr);

        fetchAccountData();
      } catch (err) {
        console.error(err);
      }
    }

    getProjectInfo();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  //take userProjects and then filter projectNames to matching cxp numbers, then set selected to those
  //when user selects new cxp numbers, either add to existing array or remove

  const filterProjects = () => {
    const arr = [];

    for (let i of userProjects) {
      for (let j of projectNames) {
        if (i === j.contract_ref) {
          arr.push({
            label: j.contract_ref + " " + j.project_title,
            value: j.contract_ref,
          });
        }
      }
    }

    setSelected(arr);
  };

  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    console.error(string, results);
  };

  const formatResult = (item) => {
    return (
      <>
        <span>{item.email}</span>
      </>
    );
  };

  const handleOnSelect = (item) => {
    setSelectedAccountData(item);
    setAccountSelected(true);
    filterProjects();
  };

  const toSend = {
    projects: Array.from(selected.map((project) => project.contract_ref)),
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosPrivate.put(
        `/users/edit/${selectedAccountData.email}`,
        toSend
      );
      if (response.status === 201) {
        const toaster = () => {
          return toast.success("Account Updated");
        };
        toaster();
        setSelected([]);
        setSelectedAccountData({});
        setAccountSelected(false);
      } else {
        const toaster = () => {
          return toast.error("Unable to update this account.");
        };
        toaster();
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <BackButtonRow />
      <Container fluid className="mt-3 admin-page">
        <Card className="my-auto">
          <Card.Header as="h5">Edit an Account</Card.Header>
          <Card.Body>
            <Container fluid style={{ height: "30rem" }}>
              <Form
                style={{ width: "50%" }}
                className="mx-auto"
                // onSubmit={(e) => handleSubmit(e)}
              >
                <Form.Group className="mb-3">
                  <Form.Label>Search by Email</Form.Label>

                  {/*  */}
                  <ReactSearchAutocomplete
                    fuseOptions={{ keys: ["email"] }}
                    formatResult={formatResult}
                    onSearch={handleOnSearch}
                    items={allAccounts}
                    onSelect={handleOnSelect}
                    onClear={() => {
                      setAccountSelected(false);
                      setSelectedAccountData({});
                      setSelected([]);
                    }}
                    resultStringKeyName="email"
                    styling={{
                      zIndex: "999",
                      marginBottom: "1rem",
                    }}
                  />

                  <Container fluid className="my-2">
                    {accountSelected && (
                      <>
                        <Container fluid>
                          <h6>Selected Account:</h6>
                          <p>{selectedAccountData.email}</p>
                          <p>
                            {selectedAccountData.projects.map((project) => (
                              <p>{project}</p>
                            ))}
                          </p>
                        </Container>
                      </>
                    )}
                  </Container>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Associated Sites/Projects</Form.Label>
                  <br />
                  {/* <MultiSelect
                    options={options}
                    value={selected}
                    onChange={setSelected}
                    labelledBy="Select"
                  /> */}
                </Form.Group>
                <Row>
                  <Button
                    onClick={(e) => onSubmit(e)}
                    className="mx-auto my-3"
                    style={{ width: "fit-content" }}
                  >
                    Submit
                  </Button>
                </Row>
              </Form>
            </Container>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
};

export default EditAccount;
