/* eslint-disable react-hooks/exhaustive-deps */
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { SyncLoader } from "react-spinners";
import LoadingComp from "../LoadingComp";
// import { Container } from "react-bootstrap";

const SourcePieChart = ({ data }) => {
  const options = {
    credits: {
      enabled: false,
    },
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
    },
    title: {
      text: null,
    },
    // subtitle: {
    //   text: "Previous 28 Days",
    // },
    tooltip: {
      pointFormat: "<b>{series.name}</b>: <b>{point.percentage:.2f}%</b>",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    series: [
      {
        name: "Energy Sources",
        colorByPoint: true,
        data: data,
      },
    ],
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: "<b>{point.name}</b>: {point.percentage: .2f}%",
          style: {
            fontSize: "1.1rem",
          },
        },
      },
    },
  };

  return (
    <>
      {data.length === 0 ? (
        <LoadingComp />
      ) : (
        <>
          <HighchartsReact
            containerProps={{
              style: {
                height: "100%",
              },
            }}
            highcharts={Highcharts}
            options={options}
          />
        </>
      )}
    </>
  );
};

export default SourcePieChart;
