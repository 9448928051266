// This file contains the reducers for use with 

export const reducer = (state, action) => {
  switch (action.type) {
    case "evEnableState":
      return { ...state, evEnableState: action.payload };
    case "evSessionActive":
      return { ...state, evSessionActive: action.payload };
    case "evDuration":
      return { ...state, evDuration: action.payload };
    case "evPowerBudget":
      return { ...state, evPowerBudget: action.payload };
    case "evVoltage":
      return { ...state, evVoltage: action.payload };
    case "evCurrent":
      return { ...state, evCurrent: action.payload };
    case "evPower":
      return { ...state, evPower: action.payload };
    case "evSoc":
      return { ...state, evSoc: action.payload };
    case "inverterPower":
      return { ...state, inverterPower: action.payload };
    case "inverterTemperature":
      return { ...state, inverterTemperature: action.payload };
    case "inverterState":
      return { ...state, inverterState: action.payload };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

export const userReducer = (state, action) => {
  switch (action.type) {
    case "setUserEmail":
      return { ...state, email: action.payload };
    case "setUserPassword":
      return { ...state, password: action.payload };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
