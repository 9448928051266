import { Container, Spinner } from "react-bootstrap";

// simple loading screen used when components are being mounted
const LoadingScreen = () => {
  return (
    <>
      {/* <div
        style={{
          paddingTop: "5rem",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      > */}
      <Container
        fluid
        className="loading-screen"
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner variant="info" />
      </Container>{" "}
    </>
  );
};

export default LoadingScreen;
