/* eslint-disable react-hooks/exhaustive-deps */
//this function will render a complete page based on the quantities of devices within the system
//there are two sections to the page, the top being all components involved in taking in and storing power, the bottom being the site load, or power in use by the system.
//this could be either through EV chargers or simply power out.

//need-
//device quantities - can be taken from site data within redux store
//import used components and render them where necessary
//will to live
import Battery from "./Components/Battery";
import Grid from "./Components/Grid";
import PV from "./Components/PV";
import Siteload from "./Components/Siteload";
import Xarrow from "react-xarrows";
import { Col, Row, Container } from "react-bootstrap";
import { useState } from "react";
import { useEffect } from "react";
import { useLayoutData } from "../../../Hooks/useLayoutData";
import { useContext } from "react";
import GlobalDataContext from "../../../Context/DataProvider";
import { useParams } from "react-router-dom";

const CreateSystemLayout = ({ evQty, isDesktop }) => {
  const { latestData } = useContext(GlobalDataContext);
  let currentData = latestData;
  // const params = useParams();

  const { siteCxp, serialNumber } = useParams();

  const [generator, setGenerator] = useState(false);
  const [grid, setGrid] = useState(false);
  const [solar, setSolar] = useState(false);
  const [battery, setBattery] = useState(false);
  const [evCharging, setEvCharging] = useState(false);

  const { gridPower, batterySoc, batteryDCBus, siteload, solarPower } =
    useLayoutData();

  const generatorExists = () => {
    console.log("Generator Exists Called");
    if (
      currentData?.ac_generator_quantity === 0 ||
      currentData?.ac_gen_quantity === 0 ||
      currentData?.ac_generator_quantity === undefined ||
      currentData?.ac_gen_quantity === undefined
    ) {
      return false;
    }
    if (
      currentData?.ac_generator_quantity !== 0 ||
      currentData?.ac_gen_quantity !== 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  //check if there is a grid connection on one of the AC meters
  //technically not possible to ascertain if there is a grid connection based
  //on the data available, but we can check if there are any meters attached, as
  //well as checking if there are any other inputs to the system, and make
  //an educated guess based on that
  //If there are no other inputs to the system aside from AC meters, then we can
  //assume at the very least that there is a grid connection
  //REVIEW THIS
  const gridInputExists = () => {
    if (currentData?.ac_meter_quantity !== 0) {
      for (let i = 0; i < currentData?.ac_meter_quantity; i++) {
        if (currentData[`ac_meter_${i + 1}_total_active_power`] !== 0) {
          return true;
        }
      }
    } else {
      return false;
    }
  };

  const solarExists = () => {
    if (currentData?.ac_solar_quantity >= 1) {
      return true;
    }
    if (currentData?.dc_solar_quantity >= 1) {
      return true;
    } else return;
  };

  //battery should always exist in system but check here in case of prototype systems in use
  //such as air con units in testing etc
  //theoretically this shouldn't matter once in production (?)
  const batteryExists = () => {
    if (currentData?.battery_quantity !== 0) {
      return true;
    } else {
      return false;
    }
  };

  const evChargingExists = () => {
    if (currentData?.ev_charge_quantity !== 0) {
      return true;
    } else {
      return false;
    }
  };

  const generatorExist = generatorExists();
  const gridExist = gridInputExists();
  const solarExist = solarExists();
  const batteryExist = batteryExists();
  const evChargingExist = evChargingExists();

  const setDevicesExist = async () => {
    const generator = generatorExists();
    const grid = gridInputExists();
    const solar = solarExists();
    const battery = batteryExists();
    const evCharging = evChargingExists();

    setGenerator(generatorExist);
    setGrid(gridExist);
    setSolar(solarExist);
    setBattery(batteryExist);
    setEvCharging(evChargingExist);
  };
  //set state on component mount, refreshes on data change
  useEffect(() => {
    //I don't know what these do but I'm too scared to remove them
    setDevicesExist();
  }, [siteCxp, serialNumber]);

  const createConnectors = () => {
    //check if both top row and bottom row exist in dom
    //only render connectors if they do
    const topRowExists = document.getElementById("power-in") !== null;
    const bottomRowExists = document.getElementById("site-load") !== null;

    if (topRowExists && bottomRowExists) {
      return (
        <Xarrow
          start="power-in"
          end="site-load"
          color={siteload > 0 ? "blue" : "grey"}
          dashness={{
            strokeLen: 10,
            nonStrokeLen: 5,
            animation: siteload > 0 ? 0.5 : 0,
          }}
          showHead={siteload > 0 ? true : false}
          strokeWidth={3}
        />
      );
    } else {
      return null;
    }
  };

  return (
    <>
      {isDesktop && (
        <Container
          fluid
          className="my-2"
          style={{ display: "flex", flexDirection: "column" }}
        >
          {createConnectors()}
          <Row
            id="power-in"
            className="mx-auto py-3 my-auto m-0 mt-2 text-center"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "2px dashed rgba(0, 0, 255, 0.3)",
              borderRadius: "8px",
              width: "100%",
            }}
          >
            {generator ? (
              grid ? (
                <Col
                  className="p-1"
                  xs={4}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <Grid power={gridPower} />
                </Col>
              ) : (
                ""
              )
            ) : grid ? (
              <Col
                className="p-0"
                xs={4}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <Grid gridPower={gridPower} genPower={undefined} />
              </Col>
            ) : (
              ""
            )}
            {battery === true ? (
              <Col
                className="p-0"
                xs={4}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <Battery soc={batterySoc} dcBus={batteryDCBus} />
              </Col>
            ) : (
              ""
            )}
            {solar === true ? (
              <Col
                xs={4}
                className="p-0"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <PV solarPower={solarPower} />
              </Col>
            ) : (
              ""
            )}
          </Row>
          {siteload !== 0 && (
            <Row
              id="site-load"
              className="mx-auto p-0"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "20rem",
                marginTop: "5.5rem",
                marginBottom: "3rem",
                borderRadius: "8px",
                border: "2px dashed rgba(0, 0, 255, 0.3)",
                height: "fit-content",
                padding: "1rem",
              }}
            >
              <Col
                style={{
                  display: "flex",
                  width: "fit-content",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                className="m-0 p-0"
              >
                <Siteload power={siteload} evQty={evQty} />
              </Col>
            </Row>
          )}
        </Container>
      )}
      {isDesktop === false && (
        <Container
          fluid
          className="mt-2 mx-auto p-0"
          style={{
            // border: "1px solid red",
            // height: "20rem",
            width: "100%",
          }}
        >
          {/* {createConnectors()} */}
          {createConnectors()}
          <Row
            className="m-0"
            id="power-in"
            style={{
              display: "flex",
              width: "100%",
              border: "2px dotted rgba(0,0,255,0.2)",
              padding: "0.5rem",
              borderRadius: "5px",
              justifyContent: "space-evenly",
              height: "100%",
            }}
          >
            {generator ? (
              grid ? (
                <Col
                  className="p-0"
                  xs={5}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Grid power={gridPower} />
                </Col>
              ) : (
                ""
              )
            ) : grid ? (
              <Col
                className="p-0"
                xs={5}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Grid gridPower={gridPower} genPower={undefined} />
              </Col>
            ) : (
              ""
            )}
            {battery === true ? (
              <Col
                className="p-0"
                xs={!grid && !solar ? 8 : 5}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Battery soc={batterySoc} dcBus={batteryDCBus} />
              </Col>
            ) : (
              ""
            )}
            {solar === true ? (
              <Col
                xs={6}
                className="p-0"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <PV solarPower={solarPower} />
              </Col>
            ) : (
              ""
            )}
          </Row>
          {siteload !== 0 && (
            <Row
              id="site-load"
              className="mx-auto p-0"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "20rem",
                marginTop: "5.5rem",
                marginBottom: "3rem",
                borderRadius: "8px",
                border: "2px dashed rgba(0, 0, 255, 0.3)",
                height: "fit-content",
                padding: "1rem",
              }}
            >
              <Col
                style={{
                  display: "flex",
                  width: "fit-content",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                className="m-0 p-0"
              >
                <Siteload power={siteload} evQty={evQty} />
              </Col>
            </Row>
          )}
        </Container>
      )}
    </>
    //render page component here
  );
};

export default CreateSystemLayout;
