/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-eval */
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import LayoutMain from "../LayoutTest/LayoutMain";
import { useIsDesktop } from "../../../Hooks/useIsDesktop";
import GlobalDataContext from "../../../Context/DataProvider";

const Overview = () => {
  const params = useParams();
  const siteName = params.siteCxp;
  const { siteInfo, latestData } = useContext(GlobalDataContext);

  const allSystemDevices = siteInfo;

  const systemDevices = allSystemDevices?.filter(
    (device) => device?.projectName?.toLowerCase() === siteName?.toLowerCase()
  )[0]?.devices;

  const ev_charge_quantity = systemDevices?.ev_charge_quantity;
  const isDesktop = useIsDesktop();

  return (
    <>
      {!latestData && (
        <>
          <Container
            id="not-configured"
            className="my-4"
            fluid
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "30rem",
              textAlign: "center",
            }}
          >
            <h3 className="my-auto">
              System has not been configured for data reporting.
            </h3>
          </Container>
        </>
      )}
      {latestData && (
        <Container
          className="p-0 m-0"
          fluid
          style={{ height: "fit-content", width: "100%" }}
        >
          <LayoutMain
            site={siteName}
            isDesktop={isDesktop}
            evQty={ev_charge_quantity}
          />
        </Container>
      )}
    </>
  );
};

export default Overview;
