/* eslint-disable eqeqeq */
import { Col, Container, Row } from "react-bootstrap";
import Pylon from "../../../../images/new/Pylon.png";
import GenGrid from "../../../../images/new/gen+grid.png";
import Arrow from "../../../../images/new/arrow.svg";
import { useContext } from "react";
import { useIsDesktop } from "../../../../Hooks/useIsDesktop";
import UserContext from "../../../../Context/UserProvider";
import GlobalDataContext from "../../../../Context/DataProvider";

const Grid = () => {
  // const [isNegative, setIsNegative] = useState(gridPower < 0);
  const { theme } = useContext(UserContext);
  const globalTheme = theme;

  // const [isDesktop, setIsDesktop] = useState(window.innerWidth > 750);

  // const updateMedia = () => {
  //   setIsDesktop(window.innerWidth > 750);
  // };

  const { latestData } = useContext(GlobalDataContext);

  const totalActivePower = Number(
    latestData?.ac_meter_1_total_active_power
  )?.toFixed(2);

  const isDesktop = useIsDesktop();

  return (
    <>
      {isDesktop && (
        <>
          <Container
            className="p-0"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "fit-content",
              aspectRatio: "1/1",
            }}
          >
            <Row className="my-2 " style={{ height: "fit-content" }}>
              {" "}
              <div
                style={{
                  // height: "fit-content",
                  width: "fit-content",
                }}
              >
                <img
                  src={Arrow}
                  alt="Import Energy"
                  className={
                    totalActivePower !== 0
                      ? totalActivePower < 0
                        ? "img-fluid overview-arrow-active"
                        : "img-fluid overview-arrow"
                      : "img-fluid overview-arrow"
                  }
                  style={{ transform: "scaleY(-1)" }}
                />
              </div>
              <div
                style={{
                  width: "fit-content",
                }}
              >
                <img
                  src={Arrow}
                  alt="Export Energy"
                  className={
                    totalActivePower !== 0
                      ? totalActivePower > 0
                        ? "img-fluid overview-arrow-active"
                        : "img-fluid overview-arrow"
                      : "img-fluid overview-arrow"
                  }
                />
              </div>
            </Row>
            <Row className="mt-0 mb-1" style={{ height: "1.7rem" }}>
              {/* {totalActivePower < 0 && (
                <p className="m-0">Power In: {totalActivePower}kW</p>
              )}
              {totalActivePower > 0 && (
                <p className="m-0">Power Out: {totalActivePower}kW</p>
              )} */}
              {/* {isNegative && <p className="m-0">Power In: {power()}kW</p>}
              {!isNegative && <p className="m-0">Power Out: {power()}kW</p>} */}
            </Row>
            <Container
              className="m-0 p-2 overview-top-box"
              style={{ aspectRatio: "1/1" }}
            >
              <Row className="m-0" style={{ height: "100%" }}>
                <Col
                  style={{
                    display: "flex",
                    flexDirection: "column",

                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      aspectRatio: 1 / 1,
                      justifyContent: "center",
                      alignItems: "center",
                      overflow: "hidden",
                    }}
                  >
                    <img
                      src={Pylon}
                      alt="Grid Connection"
                      className="img-fluid"
                      style={{
                        objectFit: "fit",
                        height: "70%",
                      }}
                    />{" "}
                    <h3
                      className="mt-2"
                      style={{
                        width: "fit-content",
                        color: "var(--alt-white))",
                      }}
                    >
                      {totalActivePower}kW
                    </h3>
                  </div>
                </Col>
              </Row>
            </Container>
          </Container>
        </>
      )}
      {!isDesktop && (
        <>
          <Container
            className="my-auto text-center p-1"
            style={{
              display: "flex",
              flexDirection: "column",
              // aspectRatio: "1/1",
              backgroundColor: "rgba(255,255,255,0.3)",
              borderRadius: "8px",
              width: "100%",
              height: "10rem",
              position: "relative",
            }}
          >
            <Container
              fluid
              style={{
                position: "absolute",
                left: 0,
                top: 0,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                // border: "1px solid red",
                height: "100%",
                width: "100%",
              }}
            >
              <h1
                style={{
                  fontSize: "2.75rem",
                  fontWeight: "bolder",
                  maxWidth: "100%",
                  color: "white",
                  filter: "drop-shadow(0px 2px 3px rgba(0,0,0,0.6))",
                }}
              >
                {totalActivePower}
              </h1>
              <h1
                style={{
                  fontSize: "2rem",
                  color: "white",
                  filter: "drop-shadow(0px 2px 3px rgba(0,0,0,0.75))",
                }}
              >
                kW
              </h1>
            </Container>
            <Container
              className="my-auto mx-auto"
              style={{
                width: "fit-content",
                height: "fit-content",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                overflow: "hidden",
              }}
            >
              <img
                src={Pylon}
                alt="Grid Connection"
                // className="img-fluid"
                style={{
                  minWidth: "80%",
                  maxWidth: "100%",
                  opacity: 0.2,
                  zIndex: -1,
                }}
              />
            </Container>
          </Container>
        </>
      )}
    </>
  );
};

export default Grid;
