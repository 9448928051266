import { Container, Row } from "react-bootstrap";
import { useFetchData } from "../../../../Hooks/useFetchData";
import InverterComp from "./InverterComp";

const InverterPage = () => {
  const {
    power,
    temperature,
    state,
    frequency,
    acLineVoltage,
    realCurrent,
    dcBusVoltage,
    dcBusCurrent,
  } = useFetchData("inverter");

  return (
    <Container
      fluid
      className="p-0"
      style={{
        display: "flex",
        flexDirection: "column",
        height: "fit-content",
      }}
    >
      {power &&
        power.map((inv, index) => (
          <Row
            key={"inverter-total-" + index}
            className="m-0 mt-2 text-center"
            style={{ width: "100%", height: "100%" }}
          >
            {power.length > 1 ? (
              <p className="m-0">Inverter {index + 1}</p>
            ) : (
              ""
            )}
            <InverterComp
              key={"inverter-comp-" + index}
              power={power[index]}
              temperature={temperature[index]}
              state={state[index]}
              frequency={frequency[index]}
              acLineVoltage={acLineVoltage[index]}
              realCurrent={realCurrent[index]}
              dcBusVoltage={dcBusVoltage[index]}
              dcBusCurrent={dcBusCurrent[index]}
            />
          </Row>
        ))}
    </Container>
  );
};

export default InverterPage;
