import { Accordion, Container, Row, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import UserContext from "../../../Context/UserProvider";
import GlobalDataContext from "../../../Context/DataProvider";

const MobileNav = () => {
  const navigate = useNavigate();
  const { userData } = useContext(UserContext);
  const userSites = userData?.projects || [];
  const { siteInfo } = useContext(GlobalDataContext);

  const findAndFormatSerial = (site) => {
    const siteMatch = siteInfo.find(
      (item) => item.projectName.toLowerCase() === site.toLowerCase()
    );

    if (siteMatch) {
      const serial = siteMatch.serial_numbers[0];
      const split = serial.split("/");
      return `${split[0]}${split[1]}`.toString();
    } else {
      return "00000000";
    }
  };

  return (
    <>
      <Container className="p-0 m-0" fluid style={{ width: "100%" }}>
        <Accordion flush style={{ maxHeight: "20rem", overflowY: "scroll" }}>
          <Accordion.Item eventKey="0">
            <Accordion.Header className="text-center">
              <h4 className="p-0 m-0">Site List</h4>
            </Accordion.Header>
            <div>
              <Accordion.Body
                style={{ maxHeight: "20rem", overflowY: "scroll" }}
              >
                {userSites.map((site, idx) => (
                  <Row key={idx}>
                    <Button
                      className="mobile-nav-link text-center"
                      onClick={() =>
                        navigate(
                          "/dashboard/sites/" +
                            site +
                            "/" +
                            findAndFormatSerial(site)
                        )
                      }
                      style={{ fontSize: "1.3rem" }}
                    >
                      {site.toUpperCase()}
                    </Button>
                  </Row>
                ))}
              </Accordion.Body>
            </div>
          </Accordion.Item>
        </Accordion>
      </Container>
    </>
  );
};

export default MobileNav;
